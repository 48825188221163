import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom'
import React, { lazy, Suspense } from 'react'
import AppRoute from './AppRoute'
import MainLayout from './MainLayout'
import Home from '../views/home'
import Loader from 'components/Loader/Loader'
import { URL, Logo_URL } from '../shared/settings'
import PGDiplomaCity from 'views/pgDiplomaAnalyticsDelhi'
import {pgDiplomaCityBangalore, pgDiplomaCityDelhi, pgDiplomaCityGurgaon, pgDiplomaCityNoida} from 'data/pgDiplomaAnalyticsCity'
import PayAfterPlacement from 'views/pages/PayAfterPlacement'
const UpcomingBatche = lazy(() => import('../views/upcomingBatche'))
const ThankYou = lazy(() => import('../views/thanksForms'))
const ThanksPayment = lazy(() => import('../views/thanksPayment'))
const HDFCResponse = lazy(() => import('../views/HDFCResponse'))
const Courses = lazy(() => import('../views/courses'))
const indexLgCourses = lazy(() => import('../views/courses/indexLgCourses'))
const Course = lazy(() => import('../views/slugCheck'))
const Offer = lazy(() => import('../views/offer'))
const OurFaculty = lazy(() => import('../views/ourFaculty'))
const Enterprise = lazy(() => import('../views/enterprise'))
const CollegeUniversity = lazy(() => import('../views/collegeuniversity'))
const Contact = lazy(() => import('../views/contact'))
const LgContactUs = lazy(() => import('../views/contact/indexLgContactUs'))
const FreeResource = lazy(() => import('../views/freeResource'))
const Testimonial = lazy(() => import('../views/testimonial'))
const WhyUS = lazy(() => import('../views/whyus'))
const Terms = lazy(() => import('../views/pages/terms'))
const Refund = lazy(() => import('../views/pages/refund'))
const Privacy = lazy(() => import('../views/pages/privacy'))
const Legal = lazy(() => import('../views/pages/legal'))
// const Placement = lazy(() => import('../views/pages/placement'))
const PlacementLgPage = lazy(() => import('../views/pages/placementLgPage'))
const Checkout = lazy(() => import('../views/checkout'))
const NoMatch = lazy(() => import('../views/NoMatch'))
const Sitemap = lazy(() => import('../views/Sitemap'))

const Signin = lazy(() => import('../views/auth/signin'))
const Signup = lazy(() => import('../views/auth/signup'))
const ForgotPassword = lazy(() => import('../views/auth/forgotpassword'))
const VerifyEmail = lazy(() => import('../views/auth/verifyemail'))
const ResetPassword = lazy(() => import('../views/auth/resetpassword'))
const PGDiploma = lazy(() => import('../views/pgDiploma'))
const PGDiplomaAnalytics = lazy(() => import('../views/pgDiplomaAnalytics'))

function Routes() {
  return (
    <BrowserRouter forceRefresh={true}>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Redirect from="//*" to="/*" />
          <Route exact path="/signin" component={Signin} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/verify-email" component={VerifyEmail} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <AppRoute
            exact
            path="/"
            component={Home}
            layout={MainLayout}
            title={
              'Courses for Analysts: Online & In-Person Training in India'
            }
            // keywords={
            //   'data analytics courses, data analytics training in Bangalore, data analytics courses in Bangalore, data science institute Bangalore, best data science institute in Bangalore, analytics courses in Bangalore, artificial intelligence training in Noida, data analytics training in Bangalore'
            // }
            description={
              'Analytics certification courses in Gurgaon, Delhi NCR, Bangalore. Analytixlabs, top-ranked analytics training institute, led by IIT, IIM & ISB alumni.'
            }
            schema={
              <script type="application/ld+json">
                {`{
                  "@context": "http://schema.org",
                  "@type": "EducationalOrganization",
                  "Name": "AnalytixLabs",
                  "url": "${URL}",
                  "foundingDate": "2011",
                  "contactPoint": [
                      {
                          "@type": "ContactPoint",
                          "telephone": "+91-9555219007",
                          "contactType": "customer service"
                      }
                  ],
                  "logo": "${Logo_URL}",
                  "address": [
                      {
                          "@type": "PostalAddress",
                          "addressLocality": "Gurgaon",
                          "addressRegion": "HR",
                          "postalCode": "122002",
                          "streetAddress": "SCO 382, GF, Sector 29, Next to IFFCO Metro Gate 2, Gurgaon, Haryana 122002"
                      },
                      {
                          "@type": "PostalAddress",
                          "addressLocality": "Noida",
                          "addressRegion": "UP",
                          "postalCode": "201301",
                          "streetAddress": "FF, A-78, A Block, Sector 2, Next to Sector 15 Metro Gate 3, Noida, Uttar Pradesh 201301"
                      },
                      {
                          "@type": "PostalAddress",
                          "addressLocality": "Bangalore",
                          "addressRegion": "KA",
                          "postalCode": "560102",
                          "streetAddress": "FF, Bldg 41, 14th Main Road, Sector 7, HSR Layout, Near BDA complex (Landmark:Max store), Bangalore, Karnataka 560102"
                      }
                  ],
                  "sameAs": [
                      "https://www.facebook.com/analytixlabs/",
                      "https://twitter.com/analytixlabs",
                      "https://plus.google.com/+AnalytixLabsNewDelhi",
                      "https://in.linkedin.com/company/analytixlabs/",
                      "https://www.glassdoor.co.in/Overview/Working-at-AnalytixLabs-EI_IE1394874.11,23.htm",
                      "https://www.trustpilot.com/review/www.analytixlabs.co.in"
                  ]
              }`}
              </script>
            }
          />
          <AppRoute
            exact
            path="/thank-you"
            component={ThankYou}
            layout={MainLayout}
            title={'Thank You!'}
            description={
              'Thank you for your interest! Download brochure to know about our cutting edge courses.'
            }
          />
          <AppRoute
            exact
            path="/thank-you-org"
            component={ThankYou}
            layout={MainLayout}
            title={'Thank You!'}
            description={
              'Thank you for your interest! Download brochure to know about our cutting edge courses.'
            }
          />
          <AppRoute
            exact
            path="/thanks"
            component={ThanksPayment}
            layout={MainLayout}
            title={'Thank You!'}
            description={'Thank you, payment successful!'}
          />
          <AppRoute
            exact
            path="/hdfc-response"
            component={HDFCResponse}
            layout={MainLayout}
            title={'HDFC Response!'}
            description={'HDFC Response'}
          />
          <AppRoute
            exact
            path="/courses"
            component={Courses}
            layout={MainLayout}
            title={'Courses offered by AnalytixLabs'}
            // keywords={
            //   'Machine learning courses, AI & Deep learning courses, Big data analytics courses, Python courses, Data science courses, Business analytics courses, Excel & tableau courses in Delhi, Gurgaon, Bangalore.'
            // }
            description={
              'AnalytixLabs offers Job oriented Data Science, Python, Machine Learning, AI, Deep Learning, SAS, Big Data and Business Analytics Courses. Call at 95-55-219007'
            }
          />
          <AppRoute
            exact
            path="/courses/lg"
            component={indexLgCourses}
            layout={MainLayout}
            title={'Courses offered by AnalytixLabs'}
            description={
              'AnalytixLabs offers Job oriented Data Science, Python, Machine Learning, AI, Deep Learning, SAS, Big Data and Business Analytics Courses. Call at 95-55-219007'
            }
          />
          <AppRoute
            exact
            path="/offers"
            component={Offer}
            layout={MainLayout}
            title={'Special Offers on Analytics Courses - AnalytixLabs'}
            // keywords={
            //   'Machine learning training offers, AI Deep learning training offers, Python course offers, offers for big data analytics courses.'
            // }
            description={
              'Get Special offer on Data Science, Machine Learning, AI Deep Learning, Business courses etc. Learn best with huge savings in combos. Call at +91 95-55-219007.'
            }
          />
          <AppRoute
            exact
            path="/pg-in-data-science"
            component={PGDiploma}
            layout={MainLayout}
            title={'PG Diploma in Data Science'}
            description={''}
          />
          <AppRoute
            exact
            path={pgDiplomaCityDelhi.slug}
            component={PGDiplomaCity}
            layout={MainLayout}
            title={pgDiplomaCityDelhi.title}
            description={pgDiplomaCityDelhi.description}
          />
          <AppRoute
            exact
            path={pgDiplomaCityNoida.slug}
            component={PGDiplomaCity}
            layout={MainLayout}
            title={pgDiplomaCityNoida.title}
            description={pgDiplomaCityNoida.description}
          />
          <AppRoute
            exact
            path={pgDiplomaCityGurgaon.slug}
            component={PGDiplomaCity}
            layout={MainLayout}
            title={pgDiplomaCityGurgaon.title}
            description={pgDiplomaCityGurgaon.description}
          />
          <AppRoute
            exact
            path={pgDiplomaCityBangalore.slug}
            component={PGDiplomaCity}
            layout={MainLayout}
            title={pgDiplomaCityBangalore.title}
            description={pgDiplomaCityBangalore.description}
          />
          <AppRoute
            exact
            path="/pg-in-data-analytics"
            component={PGDiplomaAnalytics}
            layout={MainLayout}
            title={'Data Analyst Certification Courses: Duration, Fees & Placements'}
            description={'Explore Best Data Analytics courses in India with placements. Get details on fees, duration, syllabus and PostGrad. Become a certified Data Analyst. Start now!'}
          />
          <AppRoute
            exact
            path="/upcoming-batches"
            component={UpcomingBatche}
            layout={MainLayout}
            title={'Analytixlabs - Upcoming Batches'}
          />
          <AppRoute
            exact
            path="/about-faculty"
            component={OurFaculty}
            layout={MainLayout}
            title={'Expert Faculties - AnalytixLabs'}
            // keywords={
            //   'Analytixlabs Faculties, Expert Faculties, Analytixlabs Team, Analytixlabs instructors, Analytixlabs trainers.'
            // }
            description={
              'A renowned institute led by a team of McKinsey, IIT, IIM & ISB alumni with deep industry experience. Get all the details of our expert faculties. Visit now!!'
            }
          />
          <AppRoute
            exact
            path="/enterprises"
            component={Enterprise}
            layout={MainLayout}
            title={
              'Hire Trained Candidate « SAS Training, Analytics Training Institute, Excel Training, SPSS training, SAS Certificate'
            }
            description={'Hire Trained Candidate'}
          />
          <AppRoute
            exact
            path="/corporates-training-courses"
            component={Enterprise}
            layout={MainLayout}
            title={
              'Hire Trained Candidate « SAS Training, Analytics Training Institute, Excel Training, SPSS training, SAS Certificate'
            }
            description={'Hire Trained Candidate'}
          />
          <AppRoute
            exact
            path="/colleges-universities-training-courses"
            component={CollegeUniversity}
            layout={MainLayout}
            title={
              'Analytics Courses for University/College Students - AnalytixLabs'
            }
            // keywords={
            //   'Machine learning courses, AI, Deep learning courses, Big data analytics course, Python courses,  Data science courses, Business analytics,  Excel & tableau courses in Delhi, gurugram, bengaluru for colleges and Universities.'
            // }
            description={
              'AnalytixLabs offers Data science, AI, Machine, Deep learning, Python and big data courses in online/Classroom mode for Colleges/Universities. Call at 95-55-219007'
            }
          />
          <AppRoute
            exact
            path="/contact-us"
            component={Contact}
            layout={MainLayout}
            title={
              'Contact Us « SAS Training, Analytics Training Institute, Excel Training, SPSS training, SAS Certificate'
            }
            description={
              'Contact AnalytixLabs - AnalytixLabs email, AnalytixLabs phone number, AnalytixLabs address'
            }
          />
          <AppRoute
            exact
            path="/contact-us/lg"
            component={LgContactUs}
            layout={MainLayout}
            title={
              'Contact Us « SAS Training, Analytics Training Institute, Excel Training, SPSS training, SAS Certificate'
            }
            description={
              'Contact AnalytixLabs - AnalytixLabs email, AnalytixLabs phone number, AnalytixLabs address'
            }
          />
          <AppRoute
            exact
            path="/free-resources"
            component={FreeResource}
            layout={MainLayout}
            title={'Free Industry Reports & Learning Resources'}
            description={
              'Learn about the latest developments with our industry reports and educational blogs.'
            }
          />
          <AppRoute
            exact
            path="/reviews-complaints-testimonials"
            component={Testimonial}
            layout={MainLayout}
            title={
              'Analytixlabs Institute gurugram reviews, ratings, complaints, testimonials and student feedbacks.'
            }
            // keywords={
            //   'analytixlabs gurugram reviews, analytixlabs student testimonials, analytixlabs student Feedback, analytixlabs students rating, analytixlabs gurugram Feedback, analytixlabs institute reviews, analytixlabs reviews, analytixlabs complaints'
            // }
            description={
              'Analytixlabs Institute gurugram reviews, ratings, complaints, testimonials and student feedbacks. See what our students say about Analytixlabs Institute. Real Profiles… Real Reviews!'
            }
          />
          <AppRoute
            exact
            path="/why-us"
            component={WhyUS}
            layout={MainLayout}
            title={'About Us - AnalytixLabs'}
            // keywords={'About Us, About AnalytixLabs'}
            description={
              'AnalytixLabs is a premier institute for Data Science, Machine learning, AI Deep learning, Python, SAS, Big data & Business analytics. Call at +91 95-55-219007.'
            }
          />
          <AppRoute
            exact
            path="/terms-conditions"
            component={Terms}
            layout={MainLayout}
            title={
              'Terms and Condition of AnalytixLabs « SAS Training, Analytics Training Institute, Excel Training, SPSS training, SAS Certificate'
            }
            description={
              'Complete Terms and Condition of Website Usage AnalytixLabs.'
            }
          />
          <AppRoute
            exact
            path="/refund"
            component={Refund}
            layout={MainLayout}
            title={
              'Refunds/Cancellations of AnalytixLabs « SAS Training, Analytics Training Institute, Excel Training, SPSS training, SAS Certificate'
            }
            description={
              'Complete Refunds/Cancellations of Website Usage AnalytixLabs.'
            }
          />
          <AppRoute
            exact
            path="/privacy"
            component={Privacy}
            layout={MainLayout}
            title={
              'Privacy & Policy of AnaytixLabs « SAS Training, Analytics Training Institute, Excel Training, SPSS training, SAS Certificate'
            }
            description={
              'Complete Privacy & Policy of Website Usage AnaytixLabs.'
            }
          />
          <AppRoute
            exact
            path="/legal"
            component={Legal}
            layout={MainLayout}
            title={
              'Legal Disclaimer by AnalytixLabs « SAS Training, Analytics Training Institute, Excel Training, SPSS training, SAS Certificate'
            }
            description={'Legal Disclaimer by AnalytixLabs.'}
          />
          {/* <AppRoute
            exact
            path="/placements"
            component={Placement}
            layout={MainLayout}
            title={'Analytixlabs - Placements'}
          /> */}
          <AppRoute
            exact
            path="/placements"
            component={PayAfterPlacement}
            layout={MainLayout}
            title={'Analytixlabs - Placements'}
          />
          <AppRoute
            exact
            path="/placements/lg"
            component={PlacementLgPage}
            layout={MainLayout}
            title={'Analytixlabs - Placements'}
          />
          <AppRoute
            exact
            path="/checkout"
            component={Checkout}
            layout={MainLayout}
            privateRoute="true"
            title={'Analytixlabs - Checkout'}
          />
          <AppRoute
            exact
            path="/404"
            component={NoMatch}
            layout={MainLayout}
            title={'Analytixlabs - 404'}
          />
          <AppRoute
            exact
            path="/sitemap"
            component={Sitemap}
            layout={MainLayout}
            title={'Analytixlabs - Sitemap'}
          />
          <AppRoute
            path="/:course"
            component={Course}
            layout={MainLayout}
            dynamicMeta={'true'}
          />
          <AppRoute component={NoMatch} layout={MainLayout} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  )
}

export default Routes
