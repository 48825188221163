import React, { memo } from 'react'
import { UserProvider } from '../context/UserContext'
import { StudentProvider } from '../context/StudentContext'
import { NotificationContainer } from 'react-notifications'
import GeneralProvider from '../context/GeneralContext'
import { PagesProvider } from '../context/PagesContext'
import { ThankyouProvider } from '../context/ThankyouContext'
import 'react-notifications/lib/notifications.css'
import '../assets/css/style.css'
import Routes from './Routes'
import {CourseProvider} from 'context/CourseContext'
const App = memo(() => (
  <ThankyouProvider>
    <UserProvider>
      <StudentProvider>
        <GeneralProvider>
          <CourseProvider>
            <PagesProvider>
              <NotificationContainer />
              <Routes />
            </PagesProvider>
          </CourseProvider>
        </GeneralProvider>
      </StudentProvider>
    </UserProvider>
  </ThankyouProvider>
))
export default App
