/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from 'react'
import rightArrow from 'assets/images/svgs/angle-circle-right.svg'
const MainBulletsPoint = memo(({ info, classAddOn, classAdddescOn }) => {
  return (
      <div className='container'>
        {info.title && 
          <div
            className={`sub-menu-title-pg-diploma row-box ${
              classAddOn ? classAddOn : ''
            }`}
          >
            <>
              <img
                src={rightArrow}
                alt=""
                className=" ls-is-cached lazyloaded mt-1"
                style={{ width: 20, height: 20 }}
              />
              <h3 className="ml-15">{info.title}</h3>
            </>
          </div>
        }
        {info.description && 
          <div
            className={`counrse-contn-pg-diploma ${
              classAdddescOn ? classAdddescOn : ''
            }`}
            dangerouslySetInnerHTML={{
              __html: info.description,
            }}
          ></div>
        }
      </div>
  )
})
export default MainBulletsPoint
