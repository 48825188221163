import axios from 'axios'
import { useUserContext } from '../context/UserContext'
import React, { useEffect } from 'react'
import { BASE_URL } from './settings'
import { toast } from 'react-toastify'
import { NotificationManager } from 'react-notifications'
import Cookies from 'universal-cookie'
import { domain } from '../shared/settings'
const cookies = new Cookies()

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  responseType: 'json',
})

function endpointTransform(endpoint, id) {
  if (id) return endpoint.concat(`/${id}/`)
  else return endpoint
}

function createEndpoint(endpoint) {
  return {
    get: (id) => axiosInstance.get(endpointTransform(endpoint, id)),
    getSingle: (single) => axiosInstance.get(endpoint, single),
    put: (id, data, config) =>
      axiosInstance.put(endpointTransform(endpoint, id), data, config),
    patch: (id, data, config) =>
      axiosInstance.patch(endpointTransform(endpoint, id), data, config),
    post: (data, id) =>
      axiosInstance.post(endpointTransform(endpoint, id), data),
    postSingle: (data, id, single) =>
      axiosInstance.post(endpointTransform(endpoint, id), data),
  }
}

function createHyperlinkedEndpoint(endpoint) {
  return {
    getBlog: (url) => axiosInstance.get(url || endpointTransform(endpoint)),
    get: ({ id, url }) =>
      axiosInstance.get(url || endpointTransform(endpoint, id)),
    patch: ({ id, url, data, config }) =>
      axiosInstance.patch(url || endpointTransform(endpoint, id), data, config),
    post: ({ data, id }) =>
      axiosInstance.post(endpointTransform(endpoint, id), data),
  }
}

const useApi = () => {
  const { token, logoutSuccess } = useUserContext()

  function removeUser(error) {
    if (error === 'Unauthorized') {
      NotificationManager.error('Your Token expired. please relogin')
      cookies.remove('loginType', domain)
      cookies.remove('user', domain)
      cookies.remove('lmsuser', domain)
      logoutSuccess()
      window.location.href = '/'
    }
  }

  useEffect(() => {
    let interceptor
    if (token) {
      interceptor = axiosInstance.interceptors.request.use((config) => {
        return {
          ...config,
          headers: { Authorization: `Token ${token}`, ...config.headers },
        }
      })
    }
    return () => {
      if (interceptor) {
        axiosInstance.interceptors.request.eject(interceptor)
      }
    }
  }, [token])

  useEffect(() => {
    const interceptor = axiosInstance.interceptors.response.use(
      (res) => res,
      (err) => {
        switch (err.response.status) {
          case 404:
            toast('notFoundError')
            break

          case 401:
            removeUser(err.response.data.message)
            break

          case 400:
            toast(err.response.data.metadata.message)
            break

          case 200:
            toast(err.response.data.message)
            break

          case 500:
            toast('serverError')
            break

          default:
            if (err.response.data) {
              const strings = Object.values(err.response.data).flat()
              toast(
                strings.map((str) => (
                  <>
                    {str}
                    <br />
                  </>
                )),
              )
              break
            }
        }
        throw err
      },
    )

    return () => {
      if (interceptor) {
        axiosInstance.interceptors.response.eject(interceptor)
      }
    }
  })

  const API = React.useMemo(
    () => ({
      forgetPassword: {
        post: (data) => axiosInstance.post('secure/forgotPassword/', data),
      },
      verifyEmail: {
        post: (data) => axiosInstance.post('resendVerifyMail', data),
      },
      register: {
        post: (data) => axiosInstance.post('secure/register/', data),
      },
      login: { post: (data) => axiosInstance.post('login/', data) },
      verifyCode: {
        post: (data) => axiosInstance.post('secure/verifyCode', data),
      },
      resetPassword: {
        post: (data) => axiosInstance.post('secure/resetPassword/', data),
      },
      frTestimonial: createEndpoint('frTestimonial'),
      searchCourse: createHyperlinkedEndpoint('searchcourse'),
      frCourseList: createEndpoint('frCourseList'),
      submitForm: { post: (data) => axiosInstance.post('submitForm', data) },
      checkoutOrder: createEndpoint('order'),
      paymentMethod: createEndpoint('/get-payment-method'),
      encryptDataForHDFC: {
        post: (data) => axiosInstance.post('/encryptData', data),
      },
      checkoutOrderRazirpaySucess: createEndpoint(
        '/order/purchase/razorpay/success',
      ),
      checkoutOrderPayumoneySucess: createEndpoint(
        '/order/purchase/payumoney/success',
      ),
      checkoutOrderPayumoneyFailure: createEndpoint(
        '/order/purchase/payumoney/failure',
      ),
      cityList: {
        get: () => axiosInstance.get('/city'),
      },
    }),
    [],
  )

  return { API, axiosInstance }
}

export default useApi
