/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, memo, useContext } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Logo from 'assets/images/logo.png'
import signOut from '../../assets/images/svgs/sign-out.png'
import searchIcon from '../../assets/images/search-icon.png'
import arrowdown from '../../assets/images/sort-down.png'
import { useUserContext } from 'context/UserContext'
import { GeneralContext } from 'context/GeneralContext'
import { usePagesContext } from 'context/PagesContext'
import { URL_BLOG, LMS_URL, domain } from '../../shared/settings'
import Cookies from 'universal-cookie'
import './header.css'
import { axiosInstance } from '../../shared/api'
import footerLogo from 'assets/images/footer-logo.png'
// import SearchBar from '../../components/SearchBar';
// import SearchBarMb from '../../components/SearchBar/searchBarMb';
import { pgDiplomaAnalyticsCard } from 'data/pgDiplomaAnalytics'

function Header({ onClickSearchBar, isDiploma }) {
  const { generalData, setGeneralState } = useContext(GeneralContext)
  const { courses, setCourses } = usePagesContext()
  const { isAuthenticated, logoutSuccess, setUser } = useUserContext()
  let count = 0
  const [courseBatch, setCourseBatch] = useState([])
  const [currentBatch, setCurrentBatch] = useState()
  // let [isserachbar, setIsSearchBar] = useState('');
  // const [dropDown, setDropDown] = useState(false);
  const cookies = new Cookies()
  async function callUpComingBatchHeader() {
    const response = await axiosInstance.get('upcomingBatchHeader')
    const params = {
      upcomingBatches: response.data.upcomingBatches,
    }
    setCourseBatch(params.upcomingBatches)
    setCurrentBatch(params.upcomingBatches[0])
    setGeneralState('uBatchHeader', params.upcomingBatches)
    setGeneralState('LastUpdatedDate', new Date())
    // setCourse(params);
  }
  async function callCourses() {
    const response = await axiosInstance.get('exploreCourseHeader')
    const params = {
      learningTracks: response.data.learningTracks,
      shortTermModules: response.data.shortTermModules,
    }
    params.shortTermModules.push(pgDiplomaAnalyticsCard)
    setCourses(params)
  }
  useEffect(() => {
    setTimeout(() => {
      if (!courses) {
        callCourses()
      }
      if (!generalData?.uBatchHeader) {
        callUpComingBatchHeader()
      } else {
        setCourseBatch(generalData?.uBatchHeader)
        setCurrentBatch(generalData?.uBatchHeader[0])
        // eslint-disable-next-line react-hooks/exhaustive-deps
        count = 1
      }
    }, 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (courseBatch && courseBatch.length > 0) {
      setInterval(() => {
        let tempBatch = null
        tempBatch = courseBatch[count]
        setCurrentBatch(tempBatch)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        count = count + 1 < courseBatch.length ? count + 1 : 0
      }, 3000)
    }
    if (!cookies.get('user', domain)) {
      logoutSuccess()
    } else {
      const user = cookies.get('user', domain)
      setUser(user, user.token)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseBatch.length])

  const logout = function () {
    cookies.remove('loginType', domain)
    cookies.remove('user', domain)
    cookies.remove('lmsuser', domain)
    logoutSuccess()
  }
  const closeMenu = () => {
    const element = document.getElementById('navbartoggler')
    const element2 = document.getElementById('navbarSupportedContent')
    element.setAttribute('aria-expanded', 'false')
    element.className = 'navbar-toggler collapsed'
    element2.className = 'navbar-collapse collapse'
  }
  useEffect(() => {
    const onScroll = (e) => {
      if (e.target.documentElement.scrollTop > 600) {
        closeMenu()
      }
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])
  return (
    <div className="row">
      <div className="col-md-12">
        <nav className="navbar navbar-expand-lg navbar-light ">
          <Link
            rel="preload"
            as="font"
            className="navbar-brand"
            to="/"
            aria-label="logo"
          >
            <img
              className={`ls-is-cached lazyloaded ${
                isDiploma ? '' : 'header-logo'
              }`}
              src={isDiploma ? footerLogo : Logo}
              alt="logo"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            id="navbartoggler"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className="collapse navbar-collapse"
            id="navbarSupportedContent"
          >
            {!isDiploma && (
              <ul
                className="navbar-nav heade-nav ml-auto navbar_height"
              >
                <li className="nav-item active" style={{ height: '66.2px' }}  onClick={()=>closeMenu()}>
                  <Link className="nav-link" to="/upcoming-batches">
                    Upcoming Batches
                  </Link>
                  <div
                    className="bdg-nav text-center"
                    style={{ maxWidth: '180px', height: '24px' }}
                  >
                    {(currentBatch?.City?.name &&
                      `${currentBatch.City.name} | `) ||
                      'No batch found'}
                    {currentBatch?.startDate &&
                      moment(currentBatch.startDate).format('DD MMMM')}
                  </div>
                </li>
                <li className="nav-item row m-0 p-0" >
                  <Link className="nav-link" to="/courses"  onClick={()=>closeMenu()}>
                    All Courses
                  </Link>
                  <a
                    href="javascript:void(0);"
                    data-toggle="dropdown"
                    className="down-arrow-mb p-2"
                  >
                    <img
                      src={arrowdown}
                      alt=""
                      style={{ height: 16, width: 16 }}
                    />
                  </a>
                  <div className="dropdown down-arrow-mb mt-1 mt-md-0"  onClick={()=>closeMenu()}>
                    <ul className="dropdown-menu">
                      {courses?.learningTracks
                        ? courses.learningTracks.map((element, i) => (
                            <li className="nav-item" key={`ki${i}`}>
                              <a href={element.slug} className="dropdown-item">
                                <b>{element.name}</b>
                              </a>
                            </li>
                          ))
                        : ''}
                      {courses?.shortTermModules
                        ? courses.shortTermModules.map((element, i) => (
                            <li className="nav-item" key={`ki${i}`}>
                              <a href={element.slug} className="dropdown-item">
                                <b>{element.name}</b>
                              </a>
                            </li>
                          ))
                        : ''}
                    </ul>
                  </div>
                </li>
                {window?.innerWidth && window.innerWidth > 767 ? (
                  <li className="nav-item row m-0 p-0"  onClick={()=>closeMenu()}>
                    {/* {window?.innerWidth && window.innerWidth < 767 ? (
                  <Link className="nav-link" onClick={onClickSearchBar} to="/">
                    Search Courses
                  </Link>
                ) : null} */}
                    <a
                      href="#"
                      className="down-arrow-mb p-2"
                      // className="down-arrow-mb p-2"
                    >
                      <img
                        src={searchIcon}
                        alt=""
                        onClick={onClickSearchBar}
                        style={{ height: 16, width: 16 }}
                      />
                    </a>
                  </li>
                ) : null}
                {/*
                //TODO: After Changes on offer Page Comment Will Remove.
                <li className="nav-item">
                  <Link className="nav-link" to="/offers">
                    Offers
                  </Link>
                </li> */}
                <li className="nav-item" onClick={()=>closeMenu()}>
                  <Link className="nav-link" to="/why-us">
                    Why Us
                  </Link>
                </li>
                <li className="nav-item"  onClick={()=>closeMenu()}>
                  <Link className="nav-link" to="/enterprises">
                    Corporates
                  </Link>
                </li>
                <li className="nav-item"  onClick={()=>closeMenu()}>
                  <a className="nav-link" href={URL_BLOG}>
                    Blog
                  </a>
                </li>
                <li className="nav-item"  onClick={()=>closeMenu()}>
                  <Link className="nav-link" to="/contact-us">
                    Contact Us
                  </Link>
                </li>
                <li className="nav-item"  onClick={()=>closeMenu()}>
                  {/* {!isAuthenticated ?
                <Link className="nav-link" to='/signin'>Login</Link>
                : */}
                  <a
                    onClick={() => window.open(LMS_URL)}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="nav-link">Login</div>
                    <span
                      className="bdg-nav2 nav2_float"
                      style={{
                        height: '20.8px',
                      }}
                    >
                      LMS
                    </span>
                  </a>
                  {/* } */}
                </li>
                {isAuthenticated ? (
                  <li className="nav-item"  onClick={()=>closeMenu()}>
                    <a
                      className="nav-link"
                      onClick={logout}
                      style={{ cursor: 'pointer' }}
                    >
                      <img
                        className="lazyloaded"
                        src={signOut}
                        width="18"
                        alt=""
                      />
                    </a>
                  </li>
                ) : null}
              </ul>
            )}
          </div>
        </nav>
      </div>
    </div>
  )
}

export default memo(Header)
