import React from 'react'
import rightArrow from 'assets/images/pg-diploma/baseline-arrow_forward-24px.svg'

const TitleWithArrowDescription = ({info,classAddOn}) => {
  return (
    <div className="container">
      {info.title && 
        <div className={`row-box  ${classAddOn ? classAddOn : ''}`}>
          {!info?.notShowArrow && <img
            src={rightArrow}
            alt=""
            className=" ls-is-cached lazyloaded mt-1"
            style={{ width: 20, height: 20 }}
          />}
          <span className={`counrse-contn-pg-diploma-analytics  mt-1 ${!info?.notShowArrow ? `ml-15` : `` } `} 
          dangerouslySetInnerHTML={{
            __html: info.title,
          }}>
          </span>
        </div>
      }
      {info?.description && (
        <div
          className="counrse-contn-pg-diploma-analytics"
          dangerouslySetInnerHTML={{
            __html: info.description,
          }}
        ></div>
      )}
    </div>
  )
}

export default TitleWithArrowDescription