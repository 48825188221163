/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {pgDiploma} from 'views/upcomingBatche/data';
import UpComingBatches from '../UpComingBatches/UpComingBatches';

const OnlyUpcomingBatch = () => {
  return (
    <div className="container">
      <div className="row text-center ">
        <div className="col-md-12 col-lg-12 teblet-pb-1 center-text d-flex justify-content-center pl-1 pr-1">
          {pgDiploma?.CourseBatches && pgDiploma?.CourseBatches.length > 0 && (
            <div className="col center-text d-flex justify-content-center">
              <UpComingBatches/>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default OnlyUpcomingBatch