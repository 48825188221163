import React, { useState } from 'react'
import { createContext } from 'react'

export const initialState = {
  generalData: {
    home: '',
    course: '',
    footer: '',
    headerfooter: '',
    announcement: '',
    testimonial: '',
    headerRecord: '',
    uBatchHeader: '',
  },
}

export const GeneralContext = createContext({
  generalData: JSON.parse(localStorage.getItem('generalData')),
  setGeneralData: () => {},
})

const useGeneral = ({ children }) => {
  const [generalData, setGeneralData] = useState(
    JSON.parse(localStorage.getItem('generalData')),
  )

  const setGeneralState = (key, data) => {
    const newGeneralData = {
      ...JSON.parse(localStorage.getItem('generalData')),
      [key]: data,
    }
    setGeneralData(newGeneralData)
    localStorage.setItem('generalData', JSON.stringify(newGeneralData))
  }
  const value = { generalData, setGeneralData, setGeneralState }

  return (
    <GeneralContext.Provider value={value}>{children}</GeneralContext.Provider>
  )
}

export default useGeneral
