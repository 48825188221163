/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from 'react'
import demoImg1 from 'assets/images/img-5.png'
import { useModalTypeContext } from 'context/modalTypeContext'
import { getDownloadBrochureModal } from 'shared/function'
import demoImg2 from 'assets/images/bnr-img-2.png'
import download from 'assets/images/svgs/download.png'

const DemoBrochure = memo(({ demoBrochure }) => {
  const { setModalType } = useModalTypeContext()
  return (
    <>
      <section className="container left-part" id="demo">
          {demoBrochure?.header && (
            <div className="mt-5 mb-4">
              <h2 className="fn-blue">{demoBrochure?.header}</h2>
            </div>
          )}
        <div className="row mt-5">
          <div className="col-md-8 pl-0">
            <div className=" sidebar_in_box course_demo_style p-0 mt-0">
              <div className="m-0 rounded p-3 text-left bg-white float-left d-block">
                <h5 style={{ fontSize: '24px' }} className="mb-4">
                  Benefits of Demo Account
                </h5>
                <div className="institute_inner_content">
                  <ul>
                    <li>
                      <strong>
                        Get access to trial sessions to <br />
                        choose the most suitable course.
                      </strong>
                    </li>
                    <li>
                      <strong>SAVE UP TO 40%</strong>
                      <br />
                      <small>on specialization learning tracks.</small>
                    </li>
                    <li>
                      <strong>JOB ORIENTED COURSES</strong>
                      <br />
                      <small>crafted and delivered by industry experts.</small>
                    </li>
                    <li>
                      <strong>INDUSTRY RELEVANT</strong>
                      <br />
                      <small>programs with latest curriculum.</small>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 ">
            <div className="take-demo">
              <h5 style={{ fontSize: '45px' }}>
                Want to see <br />
                how it works?
              </h5>
              <div className="dnld-btn float-left">
                <a
                  href="javascript:void(0);"
                  data-toggle="modal"
                  data-target="#free_demo"
                  onClick={() =>
                    setModalType(
                      getDownloadBrochureModal(
                        'ppcBangaloreSignUpDemo',
                        'ppcNcrSignUpDemo',
                        'ppcFbSignUpDemo',
                        'ppcYtbSignUpDemo',
                        'ppcBingSignUpForDemo',
                        'ppcBrandSignUpForDemo',
                        'ppcGhBlrSignUpForDemo',
                        'ppcGhNcrSignUpForDemo',
                        'ppcDsBlrSignUpForDemo',
                        'ppcSignUpDemo',
                      ),
                    )
                  }
                >
                  &nbsp; &nbsp; Sign-up for Demo &nbsp; &nbsp;
                </a>
              </div>
              <div className="col-md-8 pt-3 float-left">
                <img
                  src={demoImg1}
                  className="vert-move1 ls-is-cached lazyloaded"
                  style={{ width: '100%', maxWidth: '365px' }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
      <div className="row mt-5">
        <div className="col-md-12 mt-5">
          <div className="upcmg-btcblk contact-cta ">
            <ul>
              <li
                style={{
                  minWidth: '150px',
                }}
              >
                <img
                  src={demoImg2}
                  className="vert-move contact-cta-img lazyloaded"
                  alt=""
                />
              </li>
              <li>
                <div className="contact-cta-detail">
                  <h5>
                    Learn from top experts
                    <br /> at India’s top institute!
                  </h5>
                </div>
              </li>
              <li className="float-right">
                <div className="checkout-mr text-center mb-2 mt-2">
                  <a
                    className="ctc-btn teblet-down"
                    data-toggle="modal"
                    data-target="#download_brochure"
                    style={{
                      padding: '0 30px',
                      marginTop: '20px',
                      cursor: 'pointer',
                    }}
                    onClick={() => setModalType('downloadBrochure')}
                  >
                    <img
                      className="lazyloaded"
                      src={download}
                      width="15"
                      height="15"
                      alt=""
                    />{' '}
                       Download Brochure
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      </div>
      </section>
    </>
  )
})
export default DemoBrochure
