import React, {memo, useEffect} from 'react'
import FAQSection from '../pgDiplomaAnalytics/faq'
import PGCourseDetailHeader from '../pgDiplomaAnalytics/PGCourseDetailHeader'
import CourseToolList from '../pgDiplomaAnalytics/ToolsList'
import Callus from 'views/course/Callus'
import {
  bannerRecordDelhi,
  toolsList,
  PGdetailsDelhi,
  DataAnalyticsDelhiTable,
  PGdetailsDelhiSecondSections,
  demoBrochureDelhi,
  skillsAndToolsDelhi,
  PGskillsAndToolsDelhi,
  DataAnalyticsCourseFeeDataDelhi,
  MarketingAnalyticsDelhi,
  PlacementAndSupportDelhi,
  courseDetailsDelhi,
  faqsDelhi,
  toolsRecordDelhi,
  UpcomingBatchesDelhi,
} from '../../data/pgDiplomaAnalyticsDelhi'
import CommonPGComponent from './CommonPGComponent'
import DataAnalyticsCourseFee from './DataAnalyticsCourseFee'
import SkillsAndTools from './SkillsAndTools'
import PGDVsPGCC from 'views/pgDiplomaAnalytics/PGDVsPGCC'
import DemoBrochure from './DemoBrochure/DemoBrochure'
import CourseTimePlace from './CourseTimePlace/CourseTimePlace'
import OnlyUpcomingBatch from './OnlyUpcomingBatch/OnlyUpcomingBatch'
import {useRouteMatch} from 'react-router-dom/cjs/react-router-dom'
import {pgDiplomaCityBangalore, pgDiplomaCityDelhi, pgDiplomaCityGurgaon, pgDiplomaCityNoida} from 'data/pgDiplomaAnalyticsCity'
import {
  DataAnalyticsCourseFeeDataNoida,
  DataAnalyticsNoidaTable,
  MarketingAnalyticsNoida,
  PGdetailsNoida,
  PGdetailsNoidaSecondSections,
  PGskillsAndToolsNoida,
  PlacementAndSupportNoida,
  UpcomingBatchesNoida,
  bannerRecordNoida,
  courseDetailsNoida,
  demoBrochureNoida,
  faqsNoida,
  skillsAndToolsNoida,
  toolsRecordNoida
} from 'data/pgDiplomaAnalyticsNoida'
import {useState} from 'react'
import {axiosInstance} from 'shared/api'
import TestimonialStudent from './TestimonialStudent'
import StudentModal from 'components/StudentModal'
import {
  DataAnalyticsCourseFeeDataGurgaon,
  DataAnalyticsGurgaonTable,
  MarketingAnalyticsGurgaon,
  PGdetailsGurgaon,
  PGdetailsGurgaonSecondSections,
  PGskillsAndToolsGurgaon,
  PlacementAndSupportGurgaon,
  UpcomingBatchesGurgaon,
  bannerRecordGurgaon,
  courseDetailsGurgaon,
  demoBrochureGurgaon,
  faqsGurgaon,
  skillsAndToolsGurgaon,
  toolsRecordGurgaon
} from 'data/pgDiplomaAnalyticsGurgaon'
import {DataAnalyticsBangaloreTable, DataAnalyticsCourseFeeDataBangalore, MarketingAnalyticsBangalore, PGdetailsBangalore, PGdetailsBangaloreSecondSections, PGskillsAndToolsBangalore, PlacementAndSupportBangalore, UpcomingBatchesBangalore, bannerRecordBangalore, courseDetailsBangalore, demoBrochureBangalore, faqsBangalore, skillsAndToolsBangalore, toolsRecordBangalore} from 'data/pgDiplomaAnalyticsBangalore'
import HTMLHeadCommon from 'components/HTMLHeadCommon/HTMLHeadCommon'



const PGDiplomaCity = memo(() => {
  const route = useRouteMatch()
  const [testimonials, setTestimonial] = useState([])
  async function callTestimonial() {
    const testimonialRes = await axiosInstance.post('frTestimonial')
    setTestimonial(testimonialRes.data)
  }
  useEffect(() => {callTestimonial()},[])
  var data= {
    courseDetails : null,
    bannerRecord : null,
    toolsRecord : null,
    toolsRecordDetails : null,
    pgDetailsCity : null,
    pgdVspgcc : null,
    pgDetailsSectionTwo : null,
    skillAndTools : null,
    PGskillsAndTools : null,
    info : null,
    MarketingAnalytics : null,
    PlacementAndSupport : null,
    UpcomingBatches: null,
    demoBrochure : null,
    faq : null };
  switch(route?.path) {
    case pgDiplomaCityBangalore.slug:
      data.courseDetails=courseDetailsBangalore;
      data.bannerRecord=bannerRecordBangalore;
      data.toolsRecord=toolsList;
      data.toolsRecordDetails=toolsRecordBangalore;
      data.pgDetailsCity=PGdetailsBangalore;
      data.pgdVspgcc= DataAnalyticsBangaloreTable;
      data.pgDetailsSectionTwo = PGdetailsBangaloreSecondSections;
      data.skillAndTools = skillsAndToolsBangalore;
      data.PGskillsAndTools = PGskillsAndToolsBangalore;
      data.info = DataAnalyticsCourseFeeDataBangalore;
      data.MarketingAnalytics = MarketingAnalyticsBangalore;
      data.PlacementAndSupport = PlacementAndSupportBangalore;
      data.UpcomingBatches= UpcomingBatchesBangalore;
      data.demoBrochure = demoBrochureBangalore;
      data.faq = faqsBangalore;
      break;
    case pgDiplomaCityGurgaon.slug:
      data.courseDetails=courseDetailsGurgaon;
      data.bannerRecord=bannerRecordGurgaon;
      data.toolsRecord=toolsList;
      data.toolsRecordDetails=toolsRecordGurgaon;
      data.pgDetailsCity=PGdetailsGurgaon;
      data.pgdVspgcc= DataAnalyticsGurgaonTable;
      data.pgDetailsSectionTwo = PGdetailsGurgaonSecondSections;
      data.skillAndTools = skillsAndToolsGurgaon;
      data.PGskillsAndTools = PGskillsAndToolsGurgaon;
      data.info = DataAnalyticsCourseFeeDataGurgaon;
      data.MarketingAnalytics = MarketingAnalyticsGurgaon;
      data.PlacementAndSupport = PlacementAndSupportGurgaon;
      data.UpcomingBatches= UpcomingBatchesGurgaon;
      data.demoBrochure = demoBrochureGurgaon;
      data.faq = faqsGurgaon;
      break;
    case pgDiplomaCityNoida.slug:
      data.courseDetails=courseDetailsNoida;
      data.bannerRecord=bannerRecordNoida;
      data.toolsRecord=toolsList;
      data.toolsRecordDetails=toolsRecordNoida;
      data.pgDetailsCity=PGdetailsNoida;
      data.pgdVspgcc= DataAnalyticsNoidaTable;
      data.pgDetailsSectionTwo = PGdetailsNoidaSecondSections;
      data.skillAndTools = skillsAndToolsNoida;
      data.PGskillsAndTools = PGskillsAndToolsNoida;
      data.info = DataAnalyticsCourseFeeDataNoida;
      data.MarketingAnalytics = MarketingAnalyticsNoida;
      data.PlacementAndSupport = PlacementAndSupportNoida;
      data.UpcomingBatches= UpcomingBatchesNoida;
      data.demoBrochure = demoBrochureNoida;
      data.faq = faqsNoida;
      break;
    default :
      data.courseDetails=courseDetailsDelhi;
      data.bannerRecord=bannerRecordDelhi;
      data.toolsRecord=toolsList;
      data.toolsRecordDetails=toolsRecordDelhi;
      data.pgDetailsCity=PGdetailsDelhi;
      data.pgdVspgcc= DataAnalyticsDelhiTable;
      data.pgDetailsSectionTwo = PGdetailsDelhiSecondSections;
      data.skillAndTools = skillsAndToolsDelhi;
      data.PGskillsAndTools = PGskillsAndToolsDelhi;
      data.info = DataAnalyticsCourseFeeDataDelhi;
      data.MarketingAnalytics = MarketingAnalyticsDelhi;
      data.PlacementAndSupport = PlacementAndSupportDelhi;
      data.UpcomingBatches= UpcomingBatchesDelhi;
      data.demoBrochure = demoBrochureDelhi;
      data.faq = faqsDelhi;
      break;
  }
  if (route?.path === pgDiplomaCityDelhi.slug ) {

  }else{

  }
  return (
    <>
      <HTMLHeadCommon/>
      <div className="mb-5 pg-page-font-mobile">
        <PGCourseDetailHeader
          courseDetails={data.courseDetails}
          bannerRecord={data.bannerRecord}
          toolsRecord={data.toolsRecord}
          pgCityWisePage={true}
        />
        <CourseToolList toolsRecord={data.toolsRecordDetails} />
        <div className="course-overView-outer-top mb-5 ct-course-overView-outer-top">
          <CourseTimePlace/>
          <CommonPGComponent PGdetails={data.pgDetailsCity}/>
          <PGDVsPGCC pgdVspgcc={data.pgdVspgcc}/>
          <CommonPGComponent PGdetails={data.pgDetailsSectionTwo}/>
          <SkillsAndTools skillAndTools={data.skillAndTools} />
          <CommonPGComponent PGdetails={data.PGskillsAndTools}/>
          <DataAnalyticsCourseFee info={data.info}/>
          <CommonPGComponent PGdetails={data.MarketingAnalytics} />
          <CommonPGComponent PGdetails={data.PlacementAndSupport} />
          <TestimonialStudent
            testimonials={testimonials}
            isShowInPGPage={true}/>
          <StudentModal />
          <CommonPGComponent PGdetails={data.UpcomingBatches} />
          <OnlyUpcomingBatch/>
          <DemoBrochure demoBrochure={data.demoBrochure}/>
        </div>
        <div className="mt-3">
          <FAQSection faqRecord={data.faq} isShowHeadTag={true} />
        </div>
        <Callus />
      </div>
    </>
  )
})

export default PGDiplomaCity
