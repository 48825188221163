import React, { memo } from 'react'
import LearningTrackSilde from './LearningTrackSilde'
import LearningTrackSlideMb from './LearningTrackSlideMb'
import OwlCarousel from 'react-owl-carousel'

const LearningTrack = memo(({ learningTracks, dummy }) => {
  const blkClasses = {
    0: 'yel-blk',
    1: 'blue-blk',
    2: 'grn-blk',
  }
  let count = 0
  let countMB = 0
  let Options = {
    loop: true,
    margin: 10,
    nav: true,
    autoplayTimeout: 2000,
    autoplaySpeed: 2000,
    responsive: {
      0: {
        items: 1,
        nav: true,
      },
      600: {
        items: 2,
        nav: false,
      },
      1000: {
        items: 3,
        nav: true,
        loop: false,
      },
    },
  }
  return (
    <div
      className="col-md-9 min-ht banner-theblk"
      id="demos-8"
      // style={{position: 'absolute', zIndex: 999, right: 50, width: '70%'}}
    >
      <div className="large-12 columns owl-silder mobi_hide">
        {learningTracks && learningTracks.length > 0 ? (
          <OwlCarousel
            key={`owl__carousel__slider__${learningTracks.length}__${dummy}`}
            className="owl-theme"
            {...Options}
          >
            {learningTracks.map((learning, index) => {
              let cssClass = blkClasses[count] || 'blue-blk'
              count = count < 2 ? (count = count + 1) : 0
              return (
                <LearningTrackSilde
                  key={index}
                  course={{ ...learning, cssClass }}
                />
              )
            })}
          </OwlCarousel>
        ) : (
          ''
        )}
      </div>
      <div className="mb-show mb-0">
        {learningTracks && learningTracks.length > 0
          ? learningTracks.map((learning, index) => {
              let cssClass = blkClasses[countMB] || 'blue-blk'
              countMB = countMB < 2 ? (countMB = countMB + 1) : 0
              return (
                <LearningTrackSlideMb
                  key={index}
                  course={{ ...learning, cssClass }}
                  dummy={dummy}
                />
              )
            })
          : ''}
      </div>
    </div>
  )
})
export default LearningTrack
