const AIEngineeringCourseSlug = {
  slug : '/artificial-intelligence-certification-courses-online',
}

const GenerativeAICourseSlug = {
  slug: "/generative-ai-course"
}

const AIForManagersLeadersSlug = {
  slug: "/artificial-intelligence-for-managers-leaders"
}

const  DataScienceNoidaCourseSlug = {
  slug : '/data-science-course-in-noida',
}

const  dataScienceWithAiSpecializationSlug = {
  slug : '/executive-certification-in-data-science-with-ai-specialization',
  title: 'Executive Certification in Data Science with AI Specialization',
  courseID: 9996,
}

const AICourseInBangaloreDataSlug = {
  slug: '/artificial-intelligence-course-in-bangalore'
}

const AICourseInDelhiDataSlug = {
  slug: '/artificial-intelligence-course-in-delhi'
}

const StaticPGCityPage = ["/data-analyst-training-course-in-noida",
                          "/data-analyst-training-course-in-delhi",
                          "/data-analytics-training-course-in-bangalore",
                          "/data-analyst-training-course-in-gurgaon"]


const StaticPGCityPageCTAContactUsModal = ["/pg-in-data-analytics",
                                          "/data-analyst-training-course-in-noida",
                                          "/data-analyst-training-course-in-delhi",
                                          "/data-analytics-training-course-in-bangalore",
                                          "/data-analyst-training-course-in-gurgaon"]

const staticCourseFormDownloadBrochure = [dataScienceWithAiSpecializationSlug.slug]

const staticCoursePageSlugArray = [ AIEngineeringCourseSlug?.slug,
                                    DataScienceNoidaCourseSlug?.slug,
                                    AICourseInBangaloreDataSlug?.slug,
                                    AICourseInDelhiDataSlug?.slug,
                                    AIForManagersLeadersSlug?.slug,
                                    GenerativeAICourseSlug?.slug
                                  ];

const withStaticCoursePageSlugArray = [ AIEngineeringCourseSlug?.slug,
                                        DataScienceNoidaCourseSlug?.slug ,
                                        dataScienceWithAiSpecializationSlug?.slug,
                                        AICourseInBangaloreDataSlug?.slug,
                                        AICourseInDelhiDataSlug?.slug,
                                        AIForManagersLeadersSlug?.slug,
                                        GenerativeAICourseSlug?.slug
                                      ]

// const onlyStaticCoursePageSlugArray = [ dataScienceWithAiSpecializationSlug?.slug.slice(1)]

const ContactUsModalFormStaticDataShowArray = [AICourseInBangaloreDataSlug?.slug ]

const dynamicPageWithStaticContent = [
  '/data-science-specialization-course',
  '/data-science-course-training-bangalore',
  '/data-science-course-in-gurgaon',
  '/data-science-course-in-delhi',
  '/business-analytics-data-science-course',
  '/business-analytics-course-in-bangalore',
  '/business-analyst-course-in-delhi',
  '/machine-learning-course-certification-training'
]

export const EMICourse = "INR 6,387/- EMI*"
export const EMITextCourse = `( WITH <b>PAY AFTER PLACEMENT</b> )`
export const dynamicPageWithStaticContentExtraText= '(50% Pay after placement)'
export const careerSupportStaticPlusDynamic= [
  {
    courseCareerSupportID: 583,
    courseID: 66,
    careerSupportID: 587,
    sortOrder: 1,
    createdAt: '2020-09-28T09:27:47.000Z',
    updatedAt: '2020-09-30T16:30:02.000Z',
    CareerSupport: {
      careerSupportID: 587,
      title: 'No Upfront Fees',
      image: 'image/course/Analytixlabs-icons-svg-36.svg',
      description:
        `We believe in shared success. That's why we offer a unique pay-after-placement model which comes with zero upfront costs. Instead, you'll only invest 10% of your first-year CTC that too if you secure a job with a minimum salary of INR 7 Lakhs per annum within six months of completing PRP.`,
      isActive: true,
      isDeleted: false,
      createdBy: null,
      updatedBy: null,
      createdAt: '2020-09-28T09:27:47.000Z',
      updatedAt: '2024-05-01T07:46:38.000Z',
    },
  },
  {
    courseCareerSupportID: 585,
    courseID: 66,
    careerSupportID: 589,
    sortOrder: 2,
    createdAt: '2020-09-28T09:27:47.000Z',
    updatedAt: '2020-09-30T16:30:02.000Z',
    CareerSupport: {
      careerSupportID: 589,
      title: 'Placement Readiness Program',
      image: 'image/course/Analytixlabs-icons-svg-34.svg',
      description:
        'An optional 2-month, industry-focused module designed to enhance technical and soft skills. It includes coaching, key topic recaps for interviews, practice tests, case studies, and simulated recruitment drives. The program also features mock interviews with an industry panel, providing one-to-one feedback and guidance.',
      isActive: true,
      isDeleted: false,
      createdBy: null,
      updatedBy: null,
      createdAt: '2020-09-28T09:27:47.000Z',
      updatedAt: '2024-05-01T07:46:38.000Z',
    },
  },
  {
    courseCareerSupportID: 586,
    courseID: 66,
    careerSupportID: 590,
    sortOrder: 3,
    createdAt: '2020-09-28T09:27:47.000Z',
    updatedAt: '2020-09-30T16:30:02.000Z',
    CareerSupport: {
      careerSupportID: 590,
      title: 'Flexible Opt-in',
      image: 'image/course/Analytixlabs-icons-svg-33.svg',
      description:
        'After certification you can take upto 30 days to decide and opt in for the Placement Readiness Program. This option is ideal for those who are looking for upskilling or a coveted industry-recognized certificate, feel confident in their acquired skills, and wish to pursue job applications independently. These students will still be eligible for basic placement guidance.',
      isActive: true,
      isDeleted: false,
      createdBy: null,
      updatedBy: null,
      createdAt: '2020-09-28T09:27:47.000Z',
      updatedAt: '2024-05-01T07:46:38.000Z',
    },
  },
  {
    courseCareerSupportID: 584,
    courseID: 66,
    careerSupportID: 588,
    sortOrder: 4,
    createdAt: '2020-09-28T09:27:47.000Z',
    updatedAt: '2020-09-30T16:30:02.000Z',
    CareerSupport: {
      careerSupportID: 588,
      title: 'Continuous Support',
      image: 'image/course/Analytixlabs-icons-svg-35.svg',
      description:
        'After you successfully complete PRP, we will continue offering ongoing support as long as you need it. Many of our students benefit from numerous interview calls and rewarding career prospects attributed to the skills acquired during the course.',
      isActive: true,
      isDeleted: false,
      createdBy: null,
      updatedBy: null,
      createdAt: '2020-09-28T09:27:47.000Z',
      updatedAt: '2024-05-01T07:46:38.000Z',
    },
  },
]

/*
  CourseInfo Course Page : 
  CourseInfo Course Page checkMatchPage function must Have to added Three places use checkMatchPage function if Not Show Section have to match then add "!"(NOT) for full fill situation.
*/

  // Added New Section or Show Section Wise Array

    // #lession14
    export const showCareerPath = [AIForManagersLeadersSlug?.slug, GenerativeAICourseSlug?.slug]

    // #lession15
    export const showLearningBenefits = [AIForManagersLeadersSlug?.slug]


  // Not Show Section Wise Array

    // #projects
    export const notShowCapstoneProject = [AIForManagersLeadersSlug?.slug, GenerativeAICourseSlug?.slug]

    // #format :: Delivery Format
    export const notShowDeliveryFormat = [AIForManagersLeadersSlug?.slug]

  export const checkMatchPage = (array, url) =>{
    return array.includes(url)
  }
/*
  CourseInfo Course Page
*/

export {
  staticCoursePageSlugArray,
  AIEngineeringCourseSlug,
  DataScienceNoidaCourseSlug,
  dataScienceWithAiSpecializationSlug ,
  withStaticCoursePageSlugArray,
  // onlyStaticCoursePageSlugArray,
  staticCourseFormDownloadBrochure,
  StaticPGCityPage,
  AICourseInBangaloreDataSlug,
  ContactUsModalFormStaticDataShowArray,
  dynamicPageWithStaticContent,
  StaticPGCityPageCTAContactUsModal,
  AICourseInDelhiDataSlug,
  AIForManagersLeadersSlug,
  GenerativeAICourseSlug
}
