import React, { useState, memo } from 'react'
import { Collapse, CardBody, Card } from 'reactstrap'
import rightArrow from 'assets/images/pg-diploma/baseline-chevron_right-24px.svg'
import downArrow from 'assets/images/pg-diploma/baseline-expand_less-24px.svg'

const FAQItem = memo(({ faq }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  return (
    <div className="col-md-12">
      <div className="faq" id="accordionExample">
        <div className="pg-diploma-faq pg-card">
          <div className="pg-card-header m-0" id="htf-1">
            <h2 className="mb-0">
              <button className="btn btn-link faq-inner" onClick={toggle}>
                <span style={{ flex: 1 }}> {faq.title}</span>
                <img
                  style={{ height: '100%' }}
                  className="float-right py-0"
                  src={isOpen ? downArrow : rightArrow}
                  width="14"
                  height="16"
                  alt=""
                />
              </button>
            </h2>
          </div>
          <Collapse isOpen={isOpen}>
            <Card className="no-border">
              <CardBody
                dangerouslySetInnerHTML={{ __html: faq?.description }}
              ></CardBody>
            </Card>
          </Collapse>
        </div>
      </div>
    </div>
  )
})
export default FAQItem
