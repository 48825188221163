const contactNumber = {
  ppcContactNumber: '919555525908', 
  otherContactNumber: '919555219007',
  bingContactNumber: '918880440011',
}

export const contactNumber10 = {
  ppcContactNumber: '9555525908',
  otherContactNumber: '9555219007',
  bingContactNumber: '9667772573',
}

export default contactNumber
