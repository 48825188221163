/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from 'react'
import { URL } from '../../shared/settings'
import StudentJourneyThreeImage from 'assets/images/Student_Journey-03.jpg'
import StudentJourneyThreeMobileImage from 'assets/images/Student_Journey-03-mobile.jpg'

function PayAfterPlacementDetail() {
  return (
    <section className= 'mt-5'>
      <div className="container">
        <div className="position-relative pb-3">
          <div className="row enterprises-box-pos ourfaculty text-left">
            <div className="col-md-12">
              <h1 className="font-48 text-left">
                <span className="yl-botbod">AnalytixLabs Placements</span>
              </h1>
              <p className="eax-2-p mb-5 text-center w-100" />
            </div>
            <div className="col-md-12">
              <div className="para_set">
                <p className="text-left">
                AnalytixLabs is a leading Data Science Institute founded in 2011 with the sole mission of imparting industry-relevant and practical skills to make you job-ready.
                <span
                  className={"placement-link"}
                  onClick={() => window.open(`${URL}/reviews-complaints-testimonials`)}
                >The success of thousands of candidates</span> over the years and the clientele of some of the world’s most prestigious organizations is a testimony of the same.
                
                <br />
                <br />
                In the last few years, the broad adoption of Analytics across all types of industries has opened many new avenues and increased the number of opportunities for Data Science aspirants by many folds. This, along with confidence in our meticulously designed industry-relevant courses and devotion to your success, gives us enough conviction to back our commitment with the “Placement Readiness Program”
                  <br />
                </p>

                <div class="container mt-4 mb-4 d-none d-lg-block">
                  <div class="row">
                    <img
                      alt="student journey"
                      className="lazyloaded"
                      src={StudentJourneyThreeImage}
                      width='100%'
                    />
                  </div>
                </div>

                <div class="container mt-4 mb-4 d-block d-lg-none">
                  <div class="row">
                    <img
                      alt="student journey"
                      className="lazyloaded"
                      src={StudentJourneyThreeMobileImage}
                      width='100%'
                    />
                  </div>
                </div>

                <ol className='Pay-After-Placement-ol-li'>
                  <li><b>Placement Readiness Program (PRP): </b> An optional 2-month, industry-focused module designed to enhance technical and soft skills. It includes coaching, key topic recaps for interviews, practice tests, case studies, and simulated recruitment drives. The program also features mock interviews with an industry panel, providing one-to-one feedback and guidance.</li>
                  <li><b>No Upfront Fees: </b>At AnalytixLabs, we view your career success as a shared goal where both you and the institute are equal partners. To ensure commitment from both sides, we offer a unique pay-after-placement model which comes with zero upfront costs for the PRP module. Instead, you'll only invest 10% of your first-year CTC that too if you secure a job with a minimum salary of INR 7 Lakhs per annum within six months of completing PRP.
                  </li>
                  <li><b>Minimum Salary/CTC Requirement: </b>You are only required to pay the additional fee after placement if you achieve a minimum salary within 6 months of program completion, as follows:
                    <ul className='Pay-After-Placement-ul-li'>
                        <li>Data Analytics or Business Analytics Programs or Data Science or Artificial Intelligence Programs: ₹7 Lakhs per annum</li>
                        <li>Executive Certification in Data Science & AI: ₹10 Lakhs per annum</li>
                        <li>For working professionals, a minimum of 20% salary hike, crossing the threshold mentioned above in points 1 to 2.</li>
                    </ul>
                  </li>
                  <li><b>Flexibility to Opt-in: </b>After certification you can take upto 30 days to decide and opt in for the Placement Readiness Program. This option is ideal for those who are looking for upskilling or a coveted industry-recognized certificate, feel confident in their acquired skills, and wish to pursue job applications independently. These students will still be eligible for basic placement guidance.</li>
                </ol>
                <br />
                <h2 className="text-left">
                  How Does the {' '}
                  <span className="yl-botbod">Pay After Placement Program</span> Work?
                </h2>
                <ol className='Pay-After-Placement-ol-li'>
                  <li><b>Enrollment:</b> Start by enrolling in the program recommended by our learning advisor, tailored to your experience and profile.</li>
                  <li><b>Training & Certification Completion:</b> Complete the training program within a year, which includes assignments, projects, vivas, and other mandatory eligibility criteria for certification.</li>
                  <li><b>Placement Eligibility:</b> To qualify for the Placement Readiness Program (PRP), students must submit a live integrated case study based on their certification coursework and sign an agreement to Pay After Placement.
                  </li>
                  <li><b>Placement Readiness Program (PRP):</b> The 2-month industry-focused PRP module includes:
                  <ol className='Pay-After-Placement-ol-li-type-spell' type="a">
                      <li>Coaching on additional technical and soft skills (excluding basic English communication).</li>
                      <li>Recap of key topics for interview rounds</li>
                      <li>Practice tests and case studies</li>
                      <li>Placement days simulating real-world recruitment drives</li>
                      <li>Mock interviews conducted by an industry panel</li>
                      <li>One-to-one feedback and guidance</li>
                  </ol>
                  </li>
                  <li><b>Placement after PRP:</b> Students must pay the pre-determined after-placement fee in 12 monthly EMIs upon securing a placement with a minimum CTC within 6 months of completing the PRP.</li>
                </ol>

                <h2 className="text-left">
                Which {' '}
                  <span className="yl-botbod">all learning tracks</span>  are covered under it?
                </h2>
                <p className="text-left">
                The Pay After Placement program applies to the following learning tracks but not limited to:
                </p>
                <ol className='Pay-After-Placement-ol-li'>
                    <li><span
                      className={"placement-link"}
                      onClick={() => window.open(`${URL}/data-science-specialization-course`)}
                    >Advanced Certification in Data Science & AI</span></li>
                    <li><span
                      className={"placement-link"}
                      onClick={() => window.open(`${URL}/pg-in-data-analytics`)}
                    >Advanced Certification in Data Analytics</span></li>
                    <li><span
                      className={"placement-link"}
                      onClick={() => window.open(`${URL}/business-analytics-data-science-course`)}
                    >Advanced Certification in Business Analytics</span></li>
                    <li><span
                      className={"placement-link"}
                      onClick={() => window.open(`${URL}/artificial-intelligence-certification-courses-online`)}
                    >Advanced Certification in Full Stack Applied AI</span></li>
                    <li><span
                      className={"placement-link"}
                      onClick={() => window.open(`${URL}/executive-certification-in-data-science-with-ai-specialization`)}
                    >Executive Certification in Data Science with AI Specialization</span></li>
                </ol>

                <p className="text-left">Here is a list of candidates who would benefit from these learning tracks:</p>
                <ol className='Pay-After-Placement-ol-li'>
                  <li>Undergrad (B.Tech/B.E/BSc/BCA) students in engineering, science, mathematics, statistics and econometrics.</li>
                  <li>Grad (MS/MTech/ME/MBA/MCA) students in engineering, science, management, mathematics, statistics and econometrics.</li>
                  <li>For non-STEM backgrounds: Profile will be evaluated on a case-to-case basis at the time of counselling.</li>
                  <li>Working professionals: Developers, Business Intelligence Professionals, Business Analysts, Data Analysts, Startup teams building ML products/services, Product managers, Program managers, Managers, etc.</li>
                </ol>
                <br/>
                <h2 className="text-left">
                Eligibility Requirements
                </h2>
                <ol className='Pay-After-Placement-ol-li'>
                    <li><b>Age and Education:</b> Participants must be 18 years or older and possess a full-time Bachelor's Degree from an accredited institution.</li>
                    <li><b>English Proficiency:</b> Proficiency in spoken and written English is required, as assessed through initial interactions with the counselling team. Some candidates may be advised to work on their communication skills before the PRP.</li>
                    <li><b>Work Eligibility:</b> Participants must be legally eligible to work in India for at least one year following graduation.</li>
                    <li><b>Program Specifics:</b> The guarantee applies only to selected learning tracks and is approved for eligible students at the time of enrollment.</li>
                </ol>

                <h2 className="text-left">
                  Qualifying Position
                </h2>
                <p className="text-left">A “Qualifying Position” is defined as any role in the AI Machine Learning/ Data Science/Data Analytics/ Business Analytics/ Data Engineering field as</p>
                <ul className='Pay-After-Placement-ul-li'>
                    <li>A salaried employee or waged employee working an average of at least 30 hours a week;</li>
                    <li>A full-time contractual role for at least 3 months or longer</li>
                    <li>An intern, in a paid internship, used to evaluate a candidate for a full-time salaried role.</li>
                </ul>
                <p className="text-left mt-2">The Qualifying Position could be remote or in “Metropolitan Areas” - Bangalore, Delhi-NCT, Hyderabad, Pune, Mumbai, Chennai, Indore, Kolkata, Vizag, Kochi, Jaipur & Chandigarh.</p>
                <br/>
                <h2 className="text-left">
                  Immediate Payment of the Balance Amount on Disqualification
                </h2>
                <p className="text-left">Students will be required to pay the remaining amount equal to their tuition fee, regardless of their placement status, under the following circumstances:</p>
                <ol className='Pay-After-Placement-ol-li'>
                    <li>Academic Integrity Violations: Engaging in or enabling plagiarism in any form during assessments.</li>
                    <li>Non-implementation of Feedback: Ignoring feedback on resumes or interviews after receiving two reminders.</li>
                    <li>Non-performance or Completion of PRP: Repeatedly missing PRP deadlines or deliverables, and demonstrating a lack of interest or effort in PRP-related activities.</li>
                    <li>Missed Engagements: Failing to attend scheduled interviews, recruitment drives, or interview assessments without valid reasons. No excuses will be accepted after two instances.</li>
                    <li>Professional Misconduct: Displaying inappropriate behaviour during the interview process or declining suitable job offers.</li>
                    <li>Job Offer Rejection: Turning down job offers for qualifying data positions.</li>
                    <li>Insufficient Job Search Effort: Not actively seeking employment or not applying for Qualifying Data positions.</li>
                    <li>Communication Lapses: Poor communication with the placement team, including failure to report received job offers.</li>
                    <li>Early Termination of Employment: Voluntarily resigning or being terminated due to non-performance.</li>
                    <li>Securing a Job Independently: Obtaining a job on your own, whether for qualifying or non-qualifying positions.</li>
                </ol>
              </div>
              <div className="col-md-12 d-flex justify-content-center">
                <div className="dnld-btn ">
                    <a
                      class="ctc-btn teblet-down ct-cta-big-yellow  "
                      href="javascript:void(0);"
                      data-toggle="modal"
                      data-target="#contactus_form"
                      aria-label="Request call back"
                    >
                      Check Your Eligibility
                    </a>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default memo(PayAfterPlacementDetail)
