/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from 'react'

const ToolsList = memo(({ toolsRecord }) => {
  let toolsList = []
  toolsRecord &&
    toolsRecord.forEach((list, index) => {
      toolsList.push(
        <li key={`__toolsRecord__${index}`} className="col-md-4 ct-mt-15">
          <div className="learning-modes-container ct-mb-3">
            <div className="title-pg-diploma-call ">
              <h5 className='ct-mb-0' dangerouslySetInnerHTML={{ __html: list.title }}></h5>
            </div>
            <p className="fnt-title-pg-diploma">{list.description}</p>
          </div>
        </li>,
      )
    })
  return (
    <section className="watch-part-pg-diploma">
      <div className="tools-list-outer pg-ct-tools-list-outer">
        <div className="container">
          <div
            className="tools-sec-pg-diploma ct-tools-sec-pg-diploma"
            style={{ position: 'relative' }}
          >
            {/* <div className="hat-3d"></div> */}
            <div className="row">{toolsList}</div>
          </div>
        </div>
      </div>
    </section>
  )
})
export default ToolsList
