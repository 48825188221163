import {careerGraphDelhi} from 'data/pgDiplomaAnalyticsDelhi'
import React from 'react'
import CareerGraph from 'views/pgDiplomaAnalytics/careerGraph'
import CommonPGComponent from '../CommonPGComponent'

const DataAnalyticsCourseFee = ({info}) => {
  return (
    <section>
      <div className='mt-5'>
        <CommonPGComponent PGdetails={info.jobScope}/>
        <CareerGraph info={careerGraphDelhi} />
        <CommonPGComponent PGdetails={info.jobScopeOtherPart}/>
        {(info.otherDescription) && 
        <div  className="container"> 
          <div  className="counrse-contn-pg-diploma-analytics mb-2"
          dangerouslySetInnerHTML={{
            __html: info.otherDescription,
          }}></div>
          </div>}
      </div>
    </section>
  )
}

export default DataAnalyticsCourseFee