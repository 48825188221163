/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, memo, useContext } from 'react'
import Select from 'react-select'
import useApi from '../../shared/api'
import { NotificationManager } from 'react-notifications'
import { useHistory } from 'react-router-dom'
import {
  countryCode,
  colourStyles,
  TYPE_REQ_CALL_BACK,
  formatGroupLabel,
  TYPE_PPC_BING_REQ_CALL_BACK,
  TYPE_PPC_BRAND_REQ_CALL_BACK,
  TYPE_PPC_GH_BLR_REQ_CALL_BACK,
  TYPE_PPC_GH_NCR_REQ_CALL_BACK,
  TYPE_PPC_DS_BLR_REQ_CALL_BACK,
} from '../../constants'
import phone from '../../assets/images/footer/phone.png'
import refresh from '../../assets/images/svgs/refresh.png'
import { getDownloadBrochureModal, onCallUsClick } from '../../shared/function'
import {
  formValidationFun,
  getCourseList,
  getHeaderAndFooter,
} from '../../shared/utills'
import { useThankyouContext } from '../../context/ThankyouContext'
import { GeneralContext } from '../../context/GeneralContext'
import { defaultCityList } from 'data/city'
import {URL} from 'shared/settings'

const Callus = memo(() => {
  const { generalData, setGeneralState } = useContext(GeneralContext)
  const courseList = generalData?.course?.course
  const cityList = defaultCityList
  const headerfooter = generalData?.headerfooter
  const history = useHistory()
  const { API } = useApi()
  const initialState = {
    fields: {
      countryCode: countryCode[40],
    },
    errors: {},
  }
  const [contactDetails, setContactDetails] = useState(initialState)
  const [loading, setLoading] = useState(false)
  const [courseLists, setCourseLists] = useState([])
  const { setThankyouData } = useThankyouContext()

  async function callCourse() {
    const params = await getCourseList()
    setGeneralState('course', params)
  }

  async function callFooter() {
    const params = await getHeaderAndFooter()
    setGeneralState('headerfooter', params)
  }

  useEffect(() => {
    callCourse()
    callFooter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    try {
      if (courseList) {
        let course = courseList
          ? courseList.map((data) => ({
              value: data.courseID,
              label: data.name,
            }))
          : []
        setCourseLists(course)
      }
    } catch (err) {
      // Do nothing
    }
  }, [courseList])

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()
    if (handleValidation()) {
      const params = {
        fullName: contactDetails.fields.fullName,
        email: contactDetails.fields.email,
        city: contactDetails.fields.city,
        intrestedIn: contactDetails.fields.intrestedIn,
        mobile:
          contactDetails.fields.countryCode +
          '-' +
          contactDetails.fields.mobile,
        leadFormType: 1,
        findout: 6,
        type: getDownloadBrochureModal(
          TYPE_REQ_CALL_BACK,
          TYPE_REQ_CALL_BACK,
          TYPE_REQ_CALL_BACK,
          TYPE_REQ_CALL_BACK,
          TYPE_PPC_BING_REQ_CALL_BACK,
          TYPE_PPC_BRAND_REQ_CALL_BACK,
          TYPE_PPC_GH_BLR_REQ_CALL_BACK,
          TYPE_PPC_GH_NCR_REQ_CALL_BACK,
          TYPE_PPC_DS_BLR_REQ_CALL_BACK,
          TYPE_REQ_CALL_BACK,
        ),
        url: window?.location?.href,
        tandc: contactDetails.fields.tandc,
      }

      const response = await API.submitForm.post(params)
      if (response.data.message) {
        if (response?.data?.status) {
          NotificationManager.success(response.data.message)
          window.heap.addEventProperties(params)
          window.heap.addUserProperties({
            fullName: params.fullName,
            email: params.email,
            city: params.city,
            mobile: params.mobile,
          })
          setThankyouData({
            ...response.data,
            location: history.location.pathname,
          })
          history.push(`/thank-you-org`)
          setLoading(false)
          setContactDetails(initialState)
        } else {
          NotificationManager.error(response?.data?.message)
          setLoading(false)
        }
      } else {
        NotificationManager.error('Something went wrong, please try again.')
        setLoading(false)
      }
    } else {
      NotificationManager.error('Form has errors.')
      setLoading(false)
    }
  }

  const handleChange = (e, field) => {
    const { type } = e.target;
    let fields = contactDetails.fields
    let errors = contactDetails.errors
    fields[field] = type === 'checkbox' ? e.target.checked : e.target.value
    errors[field] = undefined
    setContactDetails({ ...contactDetails, fields, errors })
  }

  const handleValidation = () => {
    let fields = contactDetails.fields
    let filedList = ['fullName', 'mobile', 'email', 'intrestedIn', 'city']
    let formValidation = formValidationFun(fields, filedList)
    setContactDetails({ ...contactDetails, errors: formValidation?.errors })
    return formValidation?.formIsValid
  }

  const handleSelect = async (data = []) => {
    var result = ''
    let fields = contactDetails.fields
    let errors = contactDetails.errors

    if (data && data.length) {
      var courseIds = data.map((getId) => getId.value)
      result = courseIds && courseIds.length ? courseIds.join() : ''
    }

    fields['intrestedIn'] = result
    errors['intrestedIn'] = undefined
    setContactDetails({ ...contactDetails, fields, errors })
  }

  const handleSelectSingle = (data, field) => {
    let fields = contactDetails.fields
    let errors = contactDetails.errors

    fields[field] = data.value
    errors[field] = undefined
    setContactDetails({ ...contactDetails, fields, errors })
  }

  return (
    <div className="contact-form contact-form-course">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-7">
            <div className="call-usblk">
              <div className="call-inner pt-3">
                <h4>Excited?</h4>
                <h5>Talk to Expert Counselor</h5>
                <p>
                  to understand your profile and <br />
                  plan the next career move!
                </p>
                <a
                  href={`tel:${headerfooter?.footerRecord?.callUs?.country_code}${headerfooter?.footerRecord?.callUs?.number}`}
                  className="call-usbtn ctc-btn mt-20 mt-md-0"
                  onClick={onCallUsClick}
                >
                  <img
                    className="lazyload"
                    src={phone}
                    width="17"
                    height="20.64"
                    alt=""
                  />
                  &nbsp; &nbsp;Call us
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-5">
            <div className="call-bck text-center">
              <h3
                className="Call-head"
                style={{ fontSize: '1.75rem !important' }}
              >
                or Request a Call back
              </h3>
              <div className="login-form-container bg-white p-5 form-signin">
                <form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit}
                >
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="placeholder">Name</label>
                      <input
                        className={`form-control mb-0${
                          contactDetails.errors &&
                          contactDetails.errors.fullName
                            ? ' is-invalid'
                            : ''
                        }`}
                        id="validationCustom1003"
                        placeholder="Name"
                        value={
                          (contactDetails.fields &&
                            contactDetails.fields.fullName) ||
                          ''
                        }
                        onChange={(e) => handleChange(e, 'fullName')}
                        required=""
                      />
                      <div className="invalid-feedback text-left">
                        {contactDetails.errors &&
                          contactDetails.errors.fullName}
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <div className="row">
                        <div className="col-md-4 mb-3">
                          <label htmlFor="validationCustom02">Code</label>
                          <Select
                            options={countryCode.map((code) => ({
                              label: code,
                              value: code,
                            }))}
                            styles={colourStyles}
                            id={`countryCode`}
                            required
                            onChange={(e) =>
                              handleSelectSingle(e, 'countryCode')
                            }
                            defaultValue={{
                              label: countryCode[40],
                              value: countryCode[40],
                            }}
                          />
                        </div>
                        <div className="col-md-8 mb-3">
                          <label htmlFor="mobile">Mobile</label>
                          <input
                            className={`form-control mb-0${
                              contactDetails.errors &&
                              contactDetails.errors.mobile
                                ? ' is-invalid'
                                : ''
                            }`}
                            id="validationCustom1010"
                            placeholder="Mobile Number"
                            value={
                              (contactDetails.fields &&
                                contactDetails.fields.mobile) ||
                              ''
                            }
                            onChange={(e) => handleChange(e, 'mobile')}
                            required=""
                          ></input>
                        </div>
                      </div>
                      {contactDetails.errors && contactDetails.errors.mobile ? (
                        <div
                          className="invalid-feedback text-left"
                          style={{ display: 'block', marginTop: '-10px' }}
                        >
                          {contactDetails.errors.mobile}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-12 mb-3">
                      <label htmlFor="email03">Email</label>
                      <input
                        className={`form-control mb-0${
                          contactDetails.errors && contactDetails.errors.email
                            ? ' is-invalid'
                            : ''
                        }`}
                        id="email03"
                        placeholder="Email"
                        value={
                          (contactDetails.fields &&
                            contactDetails.fields.email) ||
                          ''
                        }
                        onChange={(e) => handleChange(e, 'email')}
                        required=""
                      ></input>
                      <div className="invalid-feedback text-left">
                        {contactDetails.errors && contactDetails.errors.email}
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom02">Select City</label>
                      <Select
                        options={cityList}
                        styles={colourStyles}
                        formatGroupLabel={formatGroupLabel}
                        id={`city`}
                        required
                        placeholder="Select City"
                        onChange={(e) => handleSelectSingle(e, 'city')}
                      />
                      {contactDetails.errors && contactDetails.errors.city ? (
                        <div
                          className="invalid-feedback text-left"
                          style={{ display: 'block' }}
                        >
                          {contactDetails.errors.city}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom02">Select Course</label>
                      <Select
                        options={courseLists}
                        isMulti
                        styles={colourStyles}
                        id={`intrestedIn`}
                        required
                        placeholder="Select Course"
                        onChange={(e) => handleSelect(e)}
                      />
                      {contactDetails.errors &&
                      contactDetails.errors.intrestedIn ? (
                        <div
                          className="invalid-feedback text-left"
                          style={{ display: 'block' }}
                        >
                          {contactDetails.errors.intrestedIn}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-12 mb-3">
                      <div className="ct-form-check">
                        
                        <input
                          className="ct-form-check-input"
                          type="checkbox"
                          id="validationCustomCheckbox"
                          name='tandc'
                          onChange={(e) => {
                            handleChange(e, 'tandc')}}
                          required
                        />
                        <label className="ct-form-check-label" htmlFor="validationCustomCheckbox">I authorise AnalytixLabs & its representatives to contact me with updates and notifications via Email/SMS/WhatsApp/Call. This will override on DND/NDNC (<b><span className={"placement-link"} onClick={() => window.open(`${URL}/privacy`)}>Policy</span>/<span className={"placement-link"} onClick={() => window.open(`${URL}/terms-conditions`)}>Terms & Conditions</span></b>)*</label>
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <button
                        className="call-usbtn ctc-btn mx-auto px-4 py-2 border-0 my-0"
                        type="submit"
                      >
                        {loading ? (
                          <img
                            className="lazyloaded"
                            src={refresh}
                            width="20"
                            style={{ marginTop: -3 }}
                            alt=""
                          />
                        ) : null}
                        {loading ? `${'\t'}Loading...` : 'Submit'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default Callus
