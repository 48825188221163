import React, { memo } from 'react'
import LearningTrack from './LearningTrack'
import badgeImage from '../../../assets/images/bannerImagehome.png'
import ArrowImage from '../../../assets/images/arrow.png'
import { Link } from 'react-router-dom'

const SliderHome = memo(
  ({ header, learningTracks, dummy }) => {
    return (
      <div className="top-bg-banner" style={{ backgroundImage: null }}>
        <div className="container">
          <div className="row">
            <div
              className="col-md-6 slider_halfsection"
            >
              <div className="top-hed">
                <span className="sinc"> {header?.since}</span>
                <span
                  dangerouslySetInnerHTML={{ __html: header?.title }}
                ></span>
              </div>
            </div>
            <div className="col-md-6 d-none d-md-block mb-3">
              <img
                className="ls-is-cached lazyloaded slider_badge-img ct-slider_badge-img ct-main-logo-float-right"
                src={badgeImage}
                height="100%"
                alt="banner"
              />
            </div>
            <div className="col-md-7 ct-sm-mt-10">
              <p className="bnr-cont">{header?.description}</p>
            </div>
            <div className="col-md-6 ct-sm-mt-40 d-block d-md-none">
              <img
                className="ls-is-cached lazyloaded slider_badge-img ct-slider_badge-img"
                src={badgeImage}
                height="100%"
                alt="banner"
              />
            </div>
          </div>
          <div className="row mt-1 mb-ht">
            <div className="col-md-12 col-lg-3">
              <div className="arro-blk">
                <span className="spn-bdg">Featured</span>
                <div className="ttl-arrw">
                  <h5 style={{ fontSize: '26px', lineHeight: '36px' }}>
                    6-Months Job Challenge
                  </h5>
                  <span className="arro-img">
                    <img className="lazyload" src={ArrowImage} alt="banner" />
                  </span>
                </div>
                <div className="butnr dskt-show" style={{ height: '29.6px' }}>
                  <Link
                    className="ctc-btn"
                    to="/courses"
                    style={{ height: '42.4px' }}
                  >
                    Explore All Courses
                  </Link>
                </div>
              </div>
            </div>
            <LearningTrack learningTracks={learningTracks} dummy={dummy} />
          </div>
        </div>
      </div>
    )
  },
  (memoPrevProps, memoNextProps) => {
    if (memoPrevProps.dummy !== memoNextProps.dummy) {
      return false
    } else {
      return true
    }
  },
)
export default SliderHome
