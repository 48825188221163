import { URL } from '../shared/settings'
let homeData = {
  dummy: true,
  learningModes: null,
  announcement: null,
  upcomingBatch: null,
  headerSection: {
    header: {
      since: 'SINCE 2011',
      title: `<h1 className="course-title">India's top ranked DATA SCIENCE <span className="yl-botbod">Institute</span></h1>`,
      description:
        'Industry-Relevant Job Oriented AI & Data Analytics Courses to Get You Hired!',
    },
    statistics: [
      {
        title: 'OVER 15,000',
        description: 'Candidates trained',
        image: null,
      },
      {
        title: '30,000+',
        description: 'Training Hours',
        image: null,
      },
      {
        title: 'OVER 96%',
        description: 'Repeat clients',
        image: null,
      },
      {
        title: '9.7/10',
        image: null,
        description: 'Avg Rating',
      },
    ],
    whyAnalytixlabs: {
      title:
        'AnalytixLabs offers a wide array of Data Analytics Training Courses to enable you to emerge as an "Industry Ready" professional for a successful career.',
      description:
        'We are led by a team of Mckinsey, IIT, and IIM alumni with deep industry experience. Our team meticulously designs every course and maps it to sought-after job roles in Data Engineering, Data Science, and Artificial Intelligence. We are committed to your success, whether you are looking to make a career transition or begin your career in Data Science! ',
      characteristics: [
        {
          image: null,
          title: 'One-to-one mentorship & assured placement assistance',
        },
        {
          image: null,
          title: 'Meticulously designed industry relevant curriculum',
        },
        {
          image: null,
          title: 'Experiential learning with real life hands-on projects',
        },
        {
          image: null,
          title: 'Extensive doubts support post class sessions',
        },
      ],
      cities: [
        {
          name: 'Bangalore',
        },
        {
          name: 'Gurgaon',
        },
        {
          name: 'Noida',
        },
      ],
    },
  },
  popularCourses: [
    {
      name: 'Full Stack Applied AI Course',
      fullURL: `${URL}/applied-ai-and-machine-learning-course`,
    },
    {
      name: 'Business Analytics Course',
      fullURL: `${URL}/business-analytics-data-science-course`,
    },
    {
      name: 'Data Science With Python',
      fullURL: `${URL}/data-science-using-python`,
    },
    {
      name: 'Big Data Analytics Course',
      fullURL: `${URL}/big-data-analytics-hadoop-spark-training-course-online`,
    },
    {
      name: 'Machine Learning Course',
      fullURL: `${URL}/machine-learning-course-certification-training`,
    },
  ],
  learningTracks: [
    {
      courseIncludedRecord: [
        {
          courseID: 61,
          name: 'Analytics Edge',
          learningMode:
            'Instructor led Interactive online || Classroom & Bootcamps || Self Paced eLearning',
          amountINR: 25000,
          discountType: 1,
          discountAmount: 0,
        },
        {
          courseID: 63,
          name: 'Data Science With Python',
          learningMode:
            'Self Paced eLearning || Instructor led Interactive online || Classroom & Bootcamps',
          amountINR: 20000,
          discountType: 1,
          discountAmount: 0,
        },
        {
          courseID: 65,
          name: 'Machine Learning Course',
          learningMode:
            'Instructor led Interactive online || Self Paced eLearning || Classroom & Bootcamps',
          amountINR: 20000,
          discountType: 1,
          discountAmount: 0,
        },
      ],
      name: 'Data Science 360',
      slug: 'data-science-specialization-course',
      hoursCount: '500',
      classCount: '46',
      robotImage: null,
      totalAmount: 45000,
    },
    {
      courseIncludedRecord: [
        {
          courseID: 63,
          name: 'Data Science With Python',
          learningMode:
            'Self Paced eLearning || Instructor led Interactive online || Classroom & Bootcamps',
          amountINR: 20000,
          discountType: 1,
          discountAmount: 0,
        },
        {
          courseID: 65,
          name: 'Machine Learning Course',
          learningMode:
            'Instructor led Interactive online || Self Paced eLearning || Classroom & Bootcamps',
          amountINR: 20000,
          discountType: 1,
          discountAmount: 0,
        },
        {
          courseID: 70,
          name: 'Deep Learning With Python',
          learningMode:
            'Classroom & Bootcamps || Self Paced eLearning || Instructor led Interactive online',
          amountINR: 25000,
          discountType: 1,
          discountAmount: 0,
        },
      ],
      name: 'Full Stack Applied AI',
      slug: 'applied-ai-and-machine-learning-course',
      hoursCount: '400',
      classCount: '50',
      robotImage: null,
      totalAmount: 48000,
    },
    {
      courseIncludedRecord: [
        {
          courseID: 68,
          name: 'Certified Big Data Course',
          learningMode:
            'Instructor led Interactive online || Classroom & Bootcamps || Self Paced eLearning',
          amountINR: 35000,
          discountType: 1,
          discountAmount: 0,
        },
        {
          courseID: 63,
          name: 'Data Science With Python',
          learningMode:
            'Self Paced eLearning || Instructor led Interactive online || Classroom & Bootcamps',
          amountINR: 20000,
          discountType: 1,
          discountAmount: 0,
        },
        {
          courseID: 65,
          name: 'Machine Learning Course',
          learningMode:
            'Instructor led Interactive online || Self Paced eLearning || Classroom & Bootcamps',
          amountINR: 20000,
          discountType: 1,
          discountAmount: 0,
        },
      ],
      name: 'Advance Big Data Science',
      slug: 'big-data-analytics-python-spark-training',
      hoursCount: '500',
      classCount: '46',
      robotImage: null,
      totalAmount: 48000,
    },
  ],
}

let companiesLogoList = [
  {
    image: require('../assets/images/companylogo/1.png'),
  },
  {
    image: require('../assets/images/companylogo/2.png'),
  },
  {
    image: require('../assets/images/companylogo/3.png'),
  },
  {
    image: require('../assets/images/companylogo/4.png'),
  },
  {
    image: require('../assets/images/companylogo/5.png'),
  },
  {
    image: require('../assets/images/companylogo/6.png'),
  },
  {
    image: require('../assets/images/companylogo/7.png'),
  },
  {
    image: require('../assets/images/companylogo/8.png'),
  },
  {
    image: require('../assets/images/companylogo/9.png'),
  },
  {
    image: require('../assets/images/companylogo/10.png'),
  },
  {
    image: require('../assets/images/companylogo/11.png'),
  },
  {
    image: require('../assets/images/companylogo/12.png'),
  },
  {
    image: require('../assets/images/companylogo/13.png'),
  },
]

export { homeData, companiesLogoList }
