import { BASE_URL } from './settings'
import { axiosInstance } from './api'
import {
  TYPE_AVAIL_THE_OFFER,
  TYPE_INQUIRE_NOW,
  TYPE_PG_DIPLOMA,
  TYPE_REQ_CALL_BACK,
  TYPE_REQ_CALL_BACK_PPC,
  TYPE_DOWNLOAD_BROCHURE,
  TYPE_PPC_DOWNLOAD_BROCHURE,
  TYPE_PPC_BANGALORE_DOWNLOAD_BROCHURE,
  TYPE_PPC_NCR_DOWNLOAD_BROCHURE,
  TYPE_PPC_FB_DOWNLOAD_BROCHURE,
  TYPE_PPC_YTB_DOWNLOAD_BROCHURE,
  TYPE_PG_DIPLOMA_DOWNLOAD_BROCHURE,
  TYPE_TAKE_DEMO,
  TYPE_FREE_DEMO,
  TYPE_PPC_BANGALORE_SIGNUP_FOR_DEMO,
  TYPE_PPC_SIGNUP_FOR_DEMO,
  TYPE_PPC_NCR_SIGNUP_FOR_DEMO,
  TYPE_PPC_FB_SIGNUP_FOR_DEMO,
  TYPE_PPC_YTB_SIGNUP_FOR_DEMO,
  TYPE_PPC_DS_BLR_SIGNUP_FOR_DEMO,
  TYPE_PPC_GH_NCR_SIGNUP_FOR_DEMO,
  TYPE_PPC_Gh_Blr_SIGNUP_FOR_DEMO,
  TYPE_PPC_Brand_SIGNUP_FOR_DEMO,
  TYPE_PPC_Bing_SIGNUP_FOR_DEMO,
  TYPE_PPC_BING_DOWNLOAD_BROCHURE,
  TYPE_PPC_BRAND_DOWNLOAD_BROCHURE,
  TYPE_PPC_GH_BLR_DOWNLOAD_BROCHURE,
  TYPE_PPC_GH_NCR_DOWNLOAD_BROCHURE,
  TYPE_PPC_DS_BLR_DOWNLOAD_BROCHURE,
} from '../constants'

export const getImage = (imageURL) => {
  if (imageURL) {
    if (imageURL.includes('http')) return imageURL
    return `${BASE_URL}${imageURL}`
  }
  return ''
}

var nextDay = new Date()
nextDay.setDate(nextDay.getDate() + 1)
nextDay.setHours(11)
nextDay.setMinutes(0)
nextDay.setSeconds(0)

export const eleAM = nextDay

const dsscUrls = [
  'gh_ds_bangalore_bing',
  'gh_ds_bangalore',
  'ds_bangalore1',
  'ds_bangalore2',
]
const dscidUrls = ['gh_ds_delhi_bing']
const dscinUrls = ['gh_ds_noida_bing']
const dscigUrls = ['gh_ds_gurgaon_bing']

export const canonicalTag = (pathName) => {
  const courseSlug = pathName.split('/')[1]
  const urlEndPoint = pathName.split('/')[2]
  if (dsscUrls.includes(urlEndPoint)) {
    return 'data-science-course-training-bangalore'
  } else if (dscidUrls.includes(urlEndPoint)) {
    return 'data-science-course-in-delhi'
  } else if (dscinUrls.includes(urlEndPoint)) {
    return 'data-science-course-in-noida'
  } else if (dscigUrls.includes(urlEndPoint)) {
    return 'data-science-course-in-gurgaon'
  }
  return courseSlug
}

export async function getCityList() {
  const response = await axiosInstance.get('city')
  let cities = []
  let metroCities = []
  let internationalCities = []
  response.data.city.forEach((city) => {
    if (city.label === 'metro') {
      metroCities.push({ label: city.name, value: city.cityID })
    } else if (city.label === 'intenational') {
      internationalCities.push({ label: city.name, value: city.cityID })
    } else if (city.label === 'local') {
      cities.push({ label: city.name, value: city.cityID })
    }
  })
  const params = {
    city: [
      { label: 'metro', options: metroCities },
      { label: 'intenational', options: internationalCities },
      { label: 'rest of india', options: cities },
    ],
  }
  return params
}

export async function getCourseList() {
  const response = await axiosInstance.get('frCourseList')
  const params = {
    course: response.data.courseRecord.rows,
  }
  return params
}

export async function getHeaderAndFooter() {
  const response = await axiosInstance.get('frHeaderAndFooter')
  const params = {
    headerRecord: {
      announcement: response.data.headerRecord.announcement,
    },
    footerRecord: {
      branches: response.data.footerRecord.branches,
      callUs: response.data.footerRecord.call_us,
      weAreSocial: response.data.footerRecord.we_are_social,
      support: response.data.footerRecord.support,
    },
  }
  return params
}

export const getFormType = (modalType) => {
  switch (modalType) {
    case 'availTheOffer':
      return TYPE_AVAIL_THE_OFFER
    case 'inquireNow':
      return TYPE_INQUIRE_NOW
    case 'PG':
      return TYPE_PG_DIPLOMA
    case 'requestCallback':
      return TYPE_REQ_CALL_BACK
    case 'ppcrequestCallback':
      return TYPE_REQ_CALL_BACK_PPC
    case 'downloadBrochure':
      return TYPE_DOWNLOAD_BROCHURE
    case 'ppcDownloadBrochure':
      return TYPE_PPC_DOWNLOAD_BROCHURE
    case 'ppcBangaloreDownloadBrochure':
      return TYPE_PPC_BANGALORE_DOWNLOAD_BROCHURE
    case 'ppcNcrDownloadBrochure':
      return TYPE_PPC_NCR_DOWNLOAD_BROCHURE
    case 'ppcFbDownloadBrochure':
      return TYPE_PPC_FB_DOWNLOAD_BROCHURE
    case 'ppcYtbDownloadBrochure':
      return TYPE_PPC_YTB_DOWNLOAD_BROCHURE
    case 'PGDownloadBrochure':
      return TYPE_PG_DIPLOMA_DOWNLOAD_BROCHURE
    case 'freeDemo':
      return TYPE_FREE_DEMO
    case 'takeDemo':
      return TYPE_TAKE_DEMO
    case 'ppcSignUpDemo':
      return TYPE_PPC_SIGNUP_FOR_DEMO
    case 'ppcBangaloreSignUpDemo':
      return TYPE_PPC_BANGALORE_SIGNUP_FOR_DEMO
    case 'ppcNcrSignUpDemo':
      return TYPE_PPC_NCR_SIGNUP_FOR_DEMO
    case 'ppcFbSignUpDemo':
      return TYPE_PPC_FB_SIGNUP_FOR_DEMO
    case 'ppcYtbSignUpDemo':
      return TYPE_PPC_YTB_SIGNUP_FOR_DEMO
    case 'ppcBingSignUpForDemo':
      return TYPE_PPC_Bing_SIGNUP_FOR_DEMO
    case 'ppcBrandSignUpForDemo':
      return TYPE_PPC_Brand_SIGNUP_FOR_DEMO
    case 'ppcGhBlrSignUpForDemo':
      return TYPE_PPC_Gh_Blr_SIGNUP_FOR_DEMO
    case 'ppcGhNcrSignUpForDemo':
      return TYPE_PPC_GH_NCR_SIGNUP_FOR_DEMO
    case 'ppcDsBlrSignUpForDemo':
      return TYPE_PPC_DS_BLR_SIGNUP_FOR_DEMO
    case 'ppcBingDownloadBrochure':
      return TYPE_PPC_BING_DOWNLOAD_BROCHURE
    case 'ppcBrandDownloadBrochure':
      return TYPE_PPC_BRAND_DOWNLOAD_BROCHURE
    case 'ppcGhBlrDownloadBrochure':
      return TYPE_PPC_GH_BLR_DOWNLOAD_BROCHURE
    case 'ppcGhNcrDownloadBrochure':
      return TYPE_PPC_GH_NCR_DOWNLOAD_BROCHURE
    case 'ppcDsBlrDownloadBrochure':
      return TYPE_PPC_DS_BLR_DOWNLOAD_BROCHURE
    case 'signUpDemo':
    default:
      return undefined
  }
}

export const formValidationFun = (
  fields,
  fieldsList,
  modalParams,
  modalType,
) => {
  let errors = {}
  let formIsValid = true
  if (fieldsList.includes('fullName') && !fields['fullName']) {
    formIsValid = false
    errors['fullName'] = 'Cannot be empty'
  }
  if (
    fieldsList.includes('fullName') &&
    fields['fullName'] &&
    /<(.|\n)*?>/i.test(fields['fullName'])
  ) {
    formIsValid = false
    errors['fullName'] = 'Name invalid'
  }
  if (fieldsList.includes('email') && !fields['email']) {
    formIsValid = false
    errors['email'] = 'Cannot be empty'
  }
  if (
    fieldsList.includes('email') &&
    fields['email'] &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(fields['email'])
  ) {
    formIsValid = false
    errors['email'] = 'Email invalid'
  }
  if (fieldsList.includes('mobile') && !fields['mobile']) {
    formIsValid = false
    errors['mobile'] = 'Cannot be empty'
  } else if (
    fieldsList.includes('mobile') &&
    !/^\d{7,}$/.test(fields['mobile']) &&
    fields["countryCode"] !== "+91"
  ) {
    formIsValid = false;
    errors["mobile"] = "Should be at least 7 digits";
  } else if (
    fieldsList.includes("mobile") &&
    !/^\d{10}$/.test(fields["mobile"]) &&
    fields["countryCode"] === "+91"
  ) {
    formIsValid = false
    errors['mobile'] = 'Should be 10 digits'
  } else if (
    fieldsList.includes('mobile') &&
    !/^[1-9]\d*$/.test(fields['mobile'])
  ) {
    formIsValid = false
    errors['mobile'] = 'Mobile number should not be start with 0'
  }
  if (modalParams) {
    if (
      !(modalParams && modalParams.offerID) &&
      !fields['intrestedIn'] &&
      modalType !== 'PG_diploma'
    ) {
      formIsValid = false
      errors['intrestedIn'] = 'Cannot be empty'
    }
  } else {
    if (fieldsList.includes('intrestedIn') && !fields['intrestedIn']) {
      formIsValid = false
      errors['intrestedIn'] = 'Please select course'
    }
  }
  if (fieldsList.includes('city') && !fields['city']) {
    formIsValid = false
    errors['city'] = 'Cannot be empty'
  }
  if (
    fieldsList.includes('preferredDateAndTime') &&
    !fields['preferredDateAndTime']
  ) {
    formIsValid = false
    errors['preferredDateAndTime'] =
      ' Please select your Preferred Date & Time to proceed further!'
  }
  if (
    fieldsList.includes('preferredDateAndTime') &&
    fields['preferredDateAndTime'] &&
    /<(.|\n)*?>/i.test(fields['preferredDateAndTime'])
  ) {
    formIsValid = false
    errors['preferredDateAndTime'] = 'preferred Date & Time invalid'
  }
  if (fieldsList.includes('inputPassword') && !fields['inputPassword']) {
    formIsValid = false
    errors['inputPassword'] = 'Cannot be empty'
  }
  if (
    fieldsList.includes('inputPassword') &&
    fields['inputPassword'] &&
    fields['inputPassword'].length < 5
  ) {
    formIsValid = false
    errors['inputPassword'] = 'Should be at least 5 digits'
  }
  if (fieldsList.includes('company') && !fields['company']) {
    formIsValid = false
    errors['company'] = 'Cannot be empty'
  }
  if (fieldsList.includes('designation') && !fields['designation']) {
    formIsValid = false
    errors['designation'] = 'Cannot be empty'
  }
  if (
    (fieldsList.includes('message') && !fields['message']) ||
    fields['message']
  ) {
    errors['message'] = 'Cannot be empty'
  }
  return { formIsValid, errors }
}

export const DateDiff = (lastDate) => {
  let diff = new Date().getTime() - new Date(lastDate).getTime()
  let daydiff = diff / (1000 * 60 * 60 * 24)
  daydiff = Math.floor(daydiff)
  return daydiff
}
