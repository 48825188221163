import TagManager from 'react-gtm-module'
import ContactNumber from 'data/ppcData'
import {
  checkExtraUrl,
  courseLgPagesSlug,
  courseSlugOfLgPage,
  getSlugAfterFromUrlByIndex,
  getSlugFromUrlByIndex,
  lgPages,
  selectedLgPages,
  selectedPagesForDSSCBangalore,
  selectedPagesForDSSCNcr,
  typeForPPCBing,
  typeForPPCBrand,
  typeForPPCDSBLR,
  typeForPPCGHBLR,
  typeForPPCGHNCR,
} from '../constants'

export function checkPPCPage() {
  if (
    (courseSlugOfLgPage.includes(getSlugFromUrlByIndex(3, true)) && courseLgPagesSlug.includes(getSlugFromUrlByIndex(4, true)))  ||
    lgPages.includes(getSlugFromUrlByIndex(2)) ||
    selectedLgPages.includes(getSlugFromUrlByIndex(2)) ||
    (checkExtraUrl &&
      (lgPages.includes(getSlugAfterFromUrlByIndex(2)) ||
        selectedLgPages.includes(getSlugAfterFromUrlByIndex(2))))
  ) {
    return true
  }
  return false
}

export function gtag_report_conversion(url) {
  var callback = function () {
    if (typeof(url) !== 'undefined') {
      window.location = url;
    }
  };
  window.gtag('event', 'conversion', {
    'send_to': 'AW-783236209/__HmCMiv3_4BEPH4vPUC',
    'event_callback': callback
  });
  return false;
}

var contactNumber = checkPPCPage()
  ? ContactNumber.ppcContactNumber
  : ContactNumber.otherContactNumber

export const onWhatsappClick = () => {
  // var callback = function () {
  //   let url = window.location.pathname
  //   if (typeof url != 'undefined') {
  //     window.location = url
  //   }
  // }
  // TagManager.dataLayer({
  //   dataLayer: {
  //     event: 'conversion',
  //     send_to: 'AW-783236209/KopRCOSv4dQBEPH4vPUC',
  //     event_callback: callback,
  //   },
  // })
  gtag_report_conversion(window.location.href);
  if (window?.innerWidth && window.innerWidth <= 767) {
    window.open(`https://api.whatsapp.com/send?phone=${contactNumber}&text=`)
  } else {
    window.open(`https://web.whatsapp.com/send?phone=${contactNumber}&text=`)
  }
}

export const onCallUsClick = () => {
  var callback = function () {
    let url = window.location.pathname
    if (typeof url != 'undefined') {
      window.location = url
    }
  }

  TagManager.dataLayer({
    dataLayer: {
      event: 'conversion',
      send_to: 'AW-783236209/ri1aCMnGoewBEPH4vPUC',
      event_callback: callback,
    },
  })

  window.uetq = window.uetq || []
  window.uetq.push('event', 'contact', {})
}

export const onDownloadClick = () => {
  var callback = function () {
    let url = window.location.pathname
    if (typeof url != 'undefined') {
      window.location = url
    }
  }
  TagManager.dataLayer({
    dataLayer: {
      event: 'conversion',
      send_to: 'AW-783236209/X6KhCIu63-0BEPH4vPUC',
      event_callback: callback,
    },
  })

  window.uetq = window.uetq || []
  window.uetq.push('event', 'subscribe', {})
}

export const onCallUsClickPPC = () => {
  var callback = function () {
    let url = window.location.pathname
    if (typeof url != 'undefined') {
      window.location = url
    }
  }

  TagManager.dataLayer({
    dataLayer: {
      event: 'conversion',
      send_to: 'AW-783236209',
      event_callback: callback,
    },
  })

  window.uetq = window.uetq || []
  window.uetq.push('event', 'contact', {})
}



export const onWhatsappClickPPC = () => {
  // var callback = function () {
  //   let url = window.location.pathname
  //   if (typeof url != 'undefined') {
  //     window.location = url
  //   }
  // }
  // TagManager.dataLayer({
  //   dataLayer: {
  //     event: 'conversion',
  //     send_to:'AW-783236209/__HmCMiv3_4BEPH4vPUC',
  //     event_callback: callback,
  //   },
  // })
  gtag_report_conversion(window.location.href);
  if (window?.innerWidth && window.innerWidth <= 767) {
    window.open(`https://api.whatsapp.com/send?phone=${contactNumber}&text=`)
  } else {
    window.open(`https://web.whatsapp.com/send?phone=${contactNumber}&text=`)
  }
}

export const getDownloadBrochureModal = (
  ppcBangalore,
  ppcNcr,
  ppcFb,
  ppcYtb,
  ppcBing,
  ppcBrand,
  PPCGhBlr,
  ppcGhNcr,
  ppcDsBlr,
  ppc,
) => {
  if (typeForPPCBing.includes(getSlugAfterFromUrlByIndex(2))) {
    return ppcBing
  } else if (typeForPPCBrand.includes(getSlugAfterFromUrlByIndex(2))) {
    return ppcBrand
  } else if (typeForPPCGHBLR.includes(getSlugAfterFromUrlByIndex(2))) {
    return PPCGhBlr
  } else if (typeForPPCGHNCR.includes(getSlugAfterFromUrlByIndex(2))) {
    return ppcGhNcr
  } else if (typeForPPCDSBLR.includes(getSlugAfterFromUrlByIndex(2))) {
    return ppcDsBlr
  } else if (
    getSlugAfterFromUrlByIndex(2) === 'lg-bangalore' ||
    getSlugAfterFromUrlByIndex(2) === 'lg-bangalore2' ||
    selectedPagesForDSSCBangalore.includes(getSlugAfterFromUrlByIndex(2))
  ) {
    return ppcBangalore
  } else if (
    getSlugAfterFromUrlByIndex(2) === 'lg-ncr' ||
    getSlugAfterFromUrlByIndex(2) === 'lg-ncr1' ||
    getSlugAfterFromUrlByIndex(2) === 'lg-ncr2' ||
    selectedPagesForDSSCNcr.includes(getSlugAfterFromUrlByIndex(2))
  ) {
    return ppcNcr
  } else if (getSlugAfterFromUrlByIndex(2) === 'lg-fb') {
    return ppcFb
  } else if (getSlugAfterFromUrlByIndex(2) === 'lg-ytb') {
    return ppcYtb
  } else {
    return ppc
  }
}

export function checkLgPageForCustomUrl(url, position) {
  if (
    lgPages.includes(url.split('/')[position]) ||
    selectedPagesForDSSCNcr.includes(url.split('/')[position]) ||
    selectedPagesForDSSCBangalore.includes(url.split('/')[position]) ||
    (url.includes('?') &&
      (lgPages.includes(url[position].split('?')[0]) ||
        selectedPagesForDSSCNcr.includes(url[position].split('?')[0]) ||
        selectedPagesForDSSCBangalore.includes(url[position].split('?')[0])))
  ) {
    return true
  }
  return false
}

export const checkLgUrl = (url) => {
  return url && Array.isArray(url.split('/')) && url.split('/').length > 2
    ? true
    : false
}
