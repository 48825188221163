import React, { memo } from 'react'
import OwlCarousel from 'react-owl-carousel'
import StudentReviewCard from 'components/StudentReviewCard'
import './TestimonialStudent.css'
import { useRouteMatch } from 'react-router-dom'

const TestimonialStudent = memo(({ testimonials}) => {
  let isLgPage =false
  const route = useRouteMatch()
  if(route.path === '/courses/lg'){
    isLgPage = true
  }
  let Options = {
    loop: true,
    margin: 10,
    nav: true,
    autoplayTimeout: 2000,
    autoplaySpeed: 2000,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
        nav: true,
      },
      600: {
        items: 2,
        nav: false,
      },
      1000: {
        items: 3,
        nav: true,
        loop: false,
      },
    },
  }
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-md-12" id="demos">
            <div
              className="large-12 columns owl-silder testmonial_owl-slider"
            >
              {!(
                !testimonials ||
                (testimonials && testimonials.length <= 0)
              ) ? (
                <OwlCarousel
                  className="owl-theme owl-loaded owl-drag"
                  {...Options}
                >
                  {testimonials &&
                    testimonials.length > 0 &&
                    testimonials.map((review) => {
                      let student = {
                        name: review.User.Student.name,
                        profileImage: review.User.Student.profileImage,
                        companyImage: review.User.Student.organizationImage,
                        linkdin: review.User.Student.linkedinUrl,
                        coruseName: review.Course && review.Course.name,
                        decription: review.reviewText,
                        rating: review.ratingNumber,
                        tags:
                          review.Course &&
                          review.Course.CourseTags &&
                          review.Course.CourseTags.length > 0 &&
                          review.Course.CourseTags,
                        link: '',
                        userReviewsId: review.userReviewsId,
                      }
                      if (
                        review?.User?.Student?.designation &&
                        review?.User?.Student?.organization
                      ) {
                        student.designtions = `(${review.User.Student.designation}, ${review.User.Student.organization})`
                      } else if (review?.User?.Student?.designation) {
                        student.designtions = `(${review.User.Student.designation})`
                      } else if (review?.User?.Student?.organization) {
                        student.designtions = `(${review.User.Student.organization})`
                      }
                      return (
                        <StudentReviewCard
                          review={student}
                          key={`StudentReview${review.userReviewsId}`}
                        />
                      )
                    })}
                </OwlCarousel>
              ) : (
                <div />
              )}
            </div>
          </div>
        </div>
        {!isLgPage &&<div className="row">
          <div className="col-md-12">
            <div className="checkout-mr text-center mb-2 mt-4">
              <a href="/reviews-complaints-testimonials" className="ctc-btn">
                Check out More!
              </a>
            </div>
          </div>
        </div>}
      </div>
    </section>
  )
})
export default TestimonialStudent
