import React, { memo } from 'react'
import { getImage } from 'shared/utills'
import { useStudentContext } from '../../context/StudentContext'
const StudentModal = memo(() => {
  const { student } = useStudentContext()
  return (
    <div
      className="modal fade"
      id="readmore"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="readmoreLabel"
      aria-hidden="true"
      style={{ zIndex: '99999999999' }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="readmoreLabel">
              {' '}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="bg-white business_box green_border">
              {student?.profileImage && (
                <img
                  src={getImage(student?.profileImage)}
                  className="lazyload mx-auto d-block mt-3 mb-5 rounded-circle shadow"
                  width="150"
                  height="150"
                  alt="img"
                />
              )}
              <p className="text-center">
                <strong>{student?.name}</strong>
                <br />
                {student?.designtions && <small>{student?.designtions}</small>}
                <br />
                <br />
                {student?.decription}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
export default StudentModal
