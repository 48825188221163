/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useState, useEffect } from 'react'
import useApi from 'shared/api'
import Select from 'react-select'
import { useHistory } from 'react-router-dom'
import {
  countryCode,
  colourStylesLg,
  formatGroupLabel,
  TYPE_PG_DIPLOMA,
} from '../../../constants'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { NotificationManager } from 'react-notifications'
import refresh from '../../../assets/images/svgs/refresh.png'
import { eleAM, formValidationFun, getImage } from '../../../shared/utills'
import { useThankyouContext } from '../../../context/ThankyouContext'
import { defaultCityList } from 'data/city'
import Setting from 'assets/images/setting.png'
import LogoText from 'assets/images/pg-diploma-analytics/IITG-logoText.png'
import LogoIIT from 'assets/images/pg-diploma-analytics/IITGlogo.png'

const CourseDetailHeader = memo(({ courseDetails, bannerRecord,toolsRecord, pgCityWisePage }) => {
  const initialState = {
    fields: {
      countryCode: countryCode[40],
      intrestedIn: courseDetails.courseID,
      isZoom: false,
      preferredDateAndTime: new Date(eleAM),
    },
    errors: {},
  }
  const { API } = useApi()
  const cityList = defaultCityList
  const [loading, setLoading] = useState(false)
  const [contactDetails, setContactDetails] = useState(initialState)
  const [checked, setChecked] = useState(false)
  const history = useHistory()
  const [defaultDateTime, setdefaultDateTime] = useState(new Date())
  const { setThankyouData } = useThankyouContext()

  let toolsList = []
  toolsRecord &&
    toolsRecord.forEach((list, index) => {
      toolsList.push(
         <li className='col-6 col-md-3 mt-2 mb-2' key={`__toolsRecord__${index}`}>
          <a href={list.link} style={{ height: '96', width: '40' }}>
            <img
              className=" ls-is-cached lazyloaded"
              src={`${getImage(list.largeImage)}`}
              style={{ height: '100%', width: '100%', objectFit: 'contain' }}
              alt=""
            />
          </a>
       </li>
      )
    })

  useEffect(() => {
    let dateTime = new Date()
    dateTime.setHours(11)
    dateTime.setMinutes(0)
    dateTime.setSeconds(0)
    dateTime.setDate(new Date().getDate() + 1)
    setdefaultDateTime(dateTime)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleValidation = () => {
    let fields = contactDetails.fields
    let filedList = [
      'fullName',
      'mobile',
      'email',
      'preferredDateAndTime',
      'city',
    ]
    let formValidation = formValidationFun(fields, filedList)
    setContactDetails({ ...contactDetails, errors: formValidation?.errors })
    return formValidation?.formIsValid
  }

  const handleSelectSingle = (data, field) => {
    let fields = contactDetails.fields
    let errors = contactDetails.errors

    fields[field] = data.value
    errors[field] = undefined
    setContactDetails({ ...contactDetails, fields, errors })
  }

  const handleCheckChange = () => {
    setChecked(!checked)
  }

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()
    if (handleValidation()) {
      const params = {
        fullName: contactDetails.fields.fullName,
        email: contactDetails.fields.email,
        city: contactDetails.fields.city,
        intrestedIn: `${courseDetails.courseID}`,
        mobile:
          contactDetails.fields.countryCode +
          '-' +
          contactDetails.fields.mobile,
        leadFormType: 4,
        findout: 6,
        type: TYPE_PG_DIPLOMA,
        preferredDateAndTime: moment(
          contactDetails.fields.preferredDateAndTime,
        ).format('MMM DD y h:mm:ss a'),
        url: window?.location?.href,
        isZoom: checked,
      }

      const response = await API.submitForm.post(params)
      if (response.data.message) {
        if (response?.data?.status) {
          NotificationManager.success(response.data.message)
          window.heap.addEventProperties(params)
          window.heap.addUserProperties({
            fullName: params.fullName,
            email: params.email,
            city: params.city,
            mobile: params.mobile,
          })
          setThankyouData({
            ...response.data,
            location: history.location.pathname,
          })
          history.push(`/thank-you`)
          setLoading(false)
          setContactDetails(initialState)
        } else {
          NotificationManager.error(response?.data?.message)
          setLoading(false)
        }
      } else {
        NotificationManager.error('Something went wrong, please try again.')
        setLoading(false)
      }
    } else {
      // NotificationManager.error('Form has errors.')
      setLoading(false)
    }
  }
  const handleChange = (e, field) => {
    let fields = contactDetails.fields
    let errors = contactDetails.errors
    fields[field] = e.target.value
    errors[field] = undefined
    setContactDetails({ ...contactDetails, fields, errors })
  }

  const handleDateChangeChange = (e, field) => {
    let fields = contactDetails.fields
    let errors = contactDetails.errors
    fields[field] = e
    errors[field] = undefined
    setContactDetails({ ...contactDetails, fields, errors })
  }
  return (
    <section className="watch-part-pg-diplom pg-diploma-header-analytics">
      <div className="container">
        <div className="row">
          <div className="col-md-12 mb-4">
            <ul className="watch-banner-pg-diploma">
              <li className="watch-banner-l1-pg-diploma ct-watch-banner-l1-pg-diploma">
                <div className="cour-head-pg">
                  <h1 style={{ color: '#003368' }}>
                    {courseDetails?.courseFullName}
                  </h1>
                  <h2 className={pgCityWisePage &&'ct-pg-mobile'}>
                    {courseDetails?.shortDescription &&
                      courseDetails?.shortDescription.replace('<br />', '\n')}
                  </h2>
                  <div className="blue-line mt-2"></div>
                </div>
                <div className="cour-sub-head-pg mt-3 mb-5">
                  <div className="row ml-1">
                    <div className='row text-center text-md-left ct-w-100'>
                      <div className='col-md-8 text-left'>
                        <img
                          src={LogoText}
                          alt=""
                          width="460"
                          className="ct-sm-dimension"
                        />
                      </div>
                      <div className='col-md-4 ct-w-100 ct-position-relative'>
                        <div className='ct-position-absolute ct-sm-position-relative ct-iitLogo-position ct-sm-mb-4'>
                          <img
                            src={LogoIIT}
                            alt=""
                            width="175"
                            className=" ls-is-cached lazyloaded"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                className="tools-sec-pg-analytics ct-tools-sec-pg-analytics"
                style={{
                  minHeight:
                    window?.innerWidth &&
                    window?.innerWidth &&
                    window.innerWidth > 767
                      ? '250px'
                      : '360px',
                }}
              >
                <h5>
                  <img
                    src={Setting}
                    alt=""
                    height="32"
                    width="47"
                    className=" ls-is-cached lazyloaded"
                  />
                  TOOLS
                </h5>
                <ul className='row d-flex justify-content-around'>{toolsList}</ul>
              </div>
                </div>
              </li>
              <li className="last-img-pg-diploma form-outer">
                <div
                  className={`${
                    window?.innerWidth &&
                    window.innerWidth > 765 &&
                    window.innerWidth < 821
                      ? 'bg-white mt-5 contact-card ct-contact-card form-pg-diploma display-f-h-center'
                      : 'bg-white contact-card ct-contact-card form-pg-diploma display-f-h-center'
                  }`}
                >
                  <form
                    className="needs-validation"
                    noValidate=""
                    onSubmit={handleSubmit}
                    style={{ width: '90%' }}
                  >
                    <div className="mt-4" style={{ width: '100%' }}>
                      <div className="col-md-12 mb-2">
                        <label htmlFor="placeholder" className="mb-0">
                          Name
                        </label>
                        <input
                          className={`form-control-lg mb-0 ${
                            contactDetails.errors &&
                            contactDetails.errors.fullName
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="validationCustom1003"
                          placeholder="Name"
                          value={
                            (contactDetails.fields &&
                              contactDetails.fields.fullName) ||
                            ''
                          }
                          onChange={(e) => handleChange(e, 'fullName')}
                          required=""
                        />
                        <div className="invalid-feedback text-left">
                          {contactDetails.errors &&
                            contactDetails.errors.fullName}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-5 mb-2">
                            <label
                              htmlFor="validationCustom02"
                              className="mb-0"
                            >
                              Code
                            </label>
                            <Select
                              options={countryCode.map((code) => ({
                                label: code,
                                value: code,
                              }))}
                              styles={colourStylesLg}
                              id={`countryCode`}
                              required
                              onChange={(e) =>
                                handleSelectSingle(e, 'countryCode')
                              }
                              defaultValue={{
                                label: countryCode[40],
                                value: countryCode[40],
                              }}
                              className="fn-10"
                            />
                          </div>
                          <div className="col-md-7 mb-2">
                            <label htmlFor="mobile" className="mb-0">
                              Mobile
                            </label>
                            <input
                              className={`form-control-lg mb-0 ${
                                contactDetails.errors &&
                                contactDetails.errors.mobile
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              id="validationCustom1010"
                              placeholder="Mobile Number"
                              value={
                                (contactDetails.fields &&
                                  contactDetails.fields.mobile) ||
                                ''
                              }
                              onChange={(e) => handleChange(e, 'mobile')}
                              required=""
                            ></input>
                          </div>
                        </div>
                        {contactDetails.errors &&
                        contactDetails.errors.mobile ? (
                          <div
                            className="invalid-feedback text-left"
                            style={{ display: 'block', marginTop: '-10px' }}
                          >
                            {contactDetails.errors.mobile}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-md-12 mb-2">
                        <label htmlFor="email03" className="mb-0">
                          Email
                        </label>
                        <input
                          className={`form-control-lg mb-0 ${
                            contactDetails.errors && contactDetails.errors.email
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="email03"
                          placeholder="Email"
                          value={
                            (contactDetails.fields &&
                              contactDetails.fields.email) ||
                            ''
                          }
                          onChange={(e) => handleChange(e, 'email')}
                          required=""
                        ></input>
                        <div className="invalid-feedback text-left">
                          {contactDetails.errors && contactDetails.errors.email}
                        </div>
                      </div>
                      <div className="col-md-12 mb-2">
                        <label htmlFor="validationCustom02" className="mb-0">
                          Select City
                        </label>
                        <Select
                          options={cityList}
                          styles={colourStylesLg}
                          formatGroupLabel={formatGroupLabel}
                          id={`city`}
                          required
                          placeholder="Select City"
                          onChange={(e) => handleSelectSingle(e, 'city')}
                        />
                        {contactDetails.errors && contactDetails.errors.city ? (
                          <div
                            className="invalid-feedback text-left"
                            style={{ display: 'block' }}
                          >
                            {contactDetails.errors.city}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-md-12 mb-2">
                        <label htmlFor="placeholder" className="mb-0">
                          Preferred Date & Time
                        </label>
                        <DatePicker
                          wrapperClassName="w-100-p"
                          className={`form-control mb-0 ${
                            contactDetails.errors &&
                            contactDetails.errors.preferredDateAndTime
                              ? 'is-invalid'
                              : ''
                          }`}
                          selected={
                            (contactDetails.fields &&
                              contactDetails.fields.preferredDateAndTime) ||
                            defaultDateTime
                          }
                          onChange={(e) =>
                            handleDateChangeChange(e, 'preferredDateAndTime')
                          }
                          showTimeSelect
                          minDate={new Date().setDate(new Date().getDate() + 1)}
                          maxDate={new Date().setDate(new Date().getDate() + 7)}
                          minTime={new Date().setTime(930807000000)}
                          maxTime={new Date().setTime(930839400000)}
                          dateFormat="MMM dd y h:mm:ss a"
                          placeholderText="Preferred Date & Time"
                        />
                        {contactDetails.errors &&
                        contactDetails.errors.preferredDateAndTime ? (
                          <div
                            className="invalid-feedback text-left"
                            style={{ display: 'block' }}
                          >
                            {contactDetails.errors.preferredDateAndTime}
                          </div>
                        ) : null}
                      </div>
                      <div
                        className="col-md-12 mb-2"
                        style={{ display: 'flex' }}
                      >
                        <div>
                          <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 ">
                            <input
                              type="checkbox"
                              name="isZoom"
                              onChange={handleCheckChange}
                              checked={checked}
                            />
                          </label>
                        </div>
                        <div className="ml-2">
                          I would also like to attend the upcoming webinar
                        </div>
                      </div>
                      <div className="col-md-12 mb-4">
                        <button
                          className="pg-diploma-signup-usbtn ctc-btn border-0 my-0"
                          type="submit"
                        >
                          {loading ? (
                            <img
                              className="lazyloaded"
                              src={refresh}
                              width="20"
                              style={{ marginTop: -3 }}
                              alt=""
                            />
                          ) : null}
                          {loading ? `${'\t'}Loading...` : 'Signup'}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
})
export default CourseDetailHeader
