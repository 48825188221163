import logoNasscomMinistry from 'assets/images/pg-diploma/logo-nasscom-ministry-removebg.png'
import daFresher from 'assets/images/svgs/userWithBg.svg'
import seniorDA from 'assets/images/svgs/presentation.svg'
import daIV from 'assets/images/svgs/searchAnalyst.svg'


const bannerRecordBangalore = {
  courseID: 66,
  name: 'Data Science 360 Course',
  bannerType: null,
  robotImage: 'image/course/thumbnail-1616501509.svg',
  courseDetailsImage: 'image/course/thumbnail-1616501509-1660822396508.svg',
  introURL: '',
  bannerFileName: 'image/course/bg-banner-1644823150421.png',
  hoursCount: '500',
  classCount: '46',
}

const toolsList =[
  {
    "id" : 1,
    "title": "Excel",
    "link": null,
    "largeImage": "image/toolsImage/excel-1601020530983.png"

  },
  {
    "id" : 2,
    "title": "PowerBi",
    "link": null,
    "largeImage": "image/toolsImage/PowerBI-1662105782661.jpg"

  },
  {
    "id" : 3,
    "title": "SQL",
    "link": null,
    "largeImage": "image/toolsImage/sql-1601021568250.png"

  },
  {
    "id" : 4,
    "title": "Python",
    "link": null,
    "largeImage": "image/toolsImage/tool-lg1-1596020742035.png"

  },
  {
    "id" : 5,
    "title": "Jupyter Notebook",
    "link": null,
    "largeImage": "image/toolsImage/1200px-Jupyter_logo-1707735380727.png"

  },
  {
    "id" : 6,
    "title": "GIT",
    "link": null,
    "largeImage": "image/toolsImage/Git-logo-1707482493040.png"

  },
  {
    "id" : 7,
    "title": "Statsmodel",
    "link": null,
    "largeImage": "image/toolsImage/images-1707482464267.png"

  },
  {
    "id" : 8,
    "title": "Seaborn",
    "link": null,
    "largeImage": "image/toolsImage/Seaborn-1601022428840.png"

  },
  {
    "id" : 9,
    "title": "Pandas",
    "link": null,
    "largeImage": "image/toolsImage/pandas-1601020776154.jpg"

  },
  {
    "id" : 10,
    "title": "NumPy",
    "link": null,
    "largeImage": "image/toolsImage/asset2tools-1595857644274.png"

  },
  {
    "id" : 11,
    "title": "Matplotlib",
    "link": null,
    "largeImage": "image/toolsImage/Matplotlib-1601031916549.png"

  },
]

const faqsBangalore = {
  faqTitle: 'FAQs',
  faqDescription: null,
  faqsData: [
    {
      FaqID: 1,
      title: 'Is there anything specific about the Bangalore job market covered in the course?',
      description:
        `<p>Recognizing the dynamic nature of Bangalore's data analytics landscape, AnalytixLabs has diligently integrated elements into the course content and career guidance that specifically address the requisites and trends prevalent in Bangalore's local job market.</p><p>
        The course keeps you updated on the latest trending technologies Bangalore companies use in data analytics. This could involve tools like big data platforms, cloud computing, and machine learning. </p><p>
        The course utilizes projects simulating real-world scenarios faced by Bangalore businesses. This ensures you develop practical skills directly relevant to the local job market. </p><p>
        The course offers career advice specific to job search strategies and professional development within the Bangalore data analytics landscape. This can include guidance on resume writing, interview preparation, and networking tips suitable for the local market.
        </p>`,
    },
    {
      FaqID: 2,
      title: 'Can I connect with other data analytics professionals in Bangalore through the course? (Community events, networking opportunities)',
      description:
        `<p>AnalytixLabs emphasizes fostering a vibrant and collaborative learning community. Our Data Analytics certification course in Bangalore provides participants with valuable knowledge and the opportunity to connect and network with other data analytics professionals in the region.</p><p>
        Bangalore is the Silicon Valley of India, so various community events and networking opportunities are frequently organized. Our data analytics course in Bangalore covers all the industry-related and trending concepts with projects, so attending these discussions and conferences will be fruitful for your career and will help widen your knowledge horizons.
        </p>`,
    },
    {
      FaqID: 3,
      title: 'Does the course location or schedule consider Bangalore commuting times? (Evening classes, online options)',
      description:
        `<p>AnalytixLabs acknowledges the significance of convenience and flexibility, especially in a bustling city like Bangalore. To cater to individuals' commuting challenges and varied schedules, our Data Analytics certification course in Bangalore offers flexible options that consider Bangalore's unique commuting patterns.</p><p>
        The course schedule includes evening classes, allowing participants to attend sessions after regular working hours. This flexibility is particularly advantageous for daytime professionals seeking to enhance their skills or transition to a career in data analytics.</p><p>
        Furthermore, recognizing the growing trend of remote learning, our course provides online options. This enables participants to engage with the course content and attend classes from the comfort of their homes, eliminating the need for time-consuming commutes. </p><p>
        The online format not only enhances accessibility but also allows participants to structure their learning around their schedules, promoting a conducive environment for effective skill acquisition.
        </p>`,
    },
    {
      FaqID: 4,
      title: 'What are the benefits of taking a data analytics course in Bangalore?',
      description:
        `<p>Enrolling in our Data Analytics course in Bangalore offers unique advantages:</p>
        <ul class="pg-lg-ul-r-20">
          <li><p><b>Tech Hub Access :</b> Bangalore's status as a tech hub provides proximity to industry leaders, fostering networking opportunities and real-world exposure.
          </p></li>
          <li><p><b>Tailored Curriculum :</b> Courses align with local industry trends, ensuring relevance and addressing specific regional challenges.</p></li>
          <li><p><b>Networking Events :</b> The city hosts frequent industry events, conferences, and meetups, facilitating connections with professionals and potential employers.</p></li>
          <li><p><b>Career Placement Support :</b> Robust placement services enhance the chances of securing relevant employment in Bangalore's active job market.</p></li>
          <li><p><b>Flexible Learning :</b> Many courses offer flexible options like evening classes and online modules to accommodate diverse schedules.</p></li>
          <li><p><b>Access to Experts :</b> Bangalore attracts top professionals, with courses often featuring industry practitioners as instructors or guest speakers.</p></li>
          <li><p><b>Innovation Hub :</b> The city's innovation and entrepreneurship culture provides exposure to innovative projects and startup opportunities.</p></li>
        </ul>`,
    },
    {
      FaqID: 5,
      title: 'What are the next steps after completing the course?',
      description:
        `<p>After completing the data analytics course, take these steps:
        </p>
        <ul class="pg-lg-ul-r-20">
          <li><p><b>Job Search :</b> Apply to relevant openings, tailor your resume, and attend interviews.</p></li>
          <li><p><b>Networking :</b> Connect with professionals, attend events, and join online forums.</p></li>
          <li><p><b>Portfolio :</b> Showcase your skills with a project portfolio.</p></li>
          <li><p><b>Continuous Learning :</b> Stay updated on trends and consider advanced courses.</p></li>
          <li><p><b>Professional Development :</b> Attend workshops, seek mentorship, and obtain certifications.</p></li>
          <li><p><b>Freelancing or Consulting :</b> Gain practical experience and build a client portfolio.</p></li>
          <li><p><b>Advanced Specialization :</b> Consider deeper expertise in a specific data analytics area.</p></li>
        </ul>`,
    },
    {
      FaqID: 6,
      title: 'What are the career opportunities for data analysts in Bangalore?',
      description:
        `<p>Many job opportunities await you after completing the Data Analytics course in Bangalore. Data analysts in Bangalore can explore diverse career paths in IT, finance, healthcare, e-commerce, consulting, government, marketing, telecommunications, education, and manufacturing.</p><p>
        With an average starting salary of ₹504,938/year, you can venture into various roles such as Analytics Consultant, Business Analyst, Data Analyst, MIS Analyst, Statistical Analyst, and more. The dynamic job market in Bangalore offers a wide spectrum of opportunities for individuals with expertise in data analytics.
        </p>`,
    },
    {
      FaqID: 7,
      title: 'What are the prerequisites for this data analytics course?',
      description:
        `<p>Our data analytics courses in Bangalore don't require in-depth pre-examination of concepts. A foundational understanding of mathematics, statistics, and some critical thinking is sufficient; our experts will assist you in mastering all the necessary concepts.</p><p>Core requirements include: </p>
        <ul class="pg-lg-ul-r-20">
          <li><p>Strong analytical thinking and problem-solving skills are crucial for success in data analysis. This field involves identifying patterns, analyzing information, and drawing meaningful conclusions from data.</p></li>
          <li><p>Curiosity and Passion: Curiosity and a genuine interest in learning are invaluable assets as you embark on your data analytics journey. A passion for exploring data and uncovering insights will fuel your motivation and perseverance.</p></li>
        </ul>
        <p>To kickstart your preparation, you can consult the article provided below:</p>
        <ul class="pg-lg-ul-r-20">
          <li><p><a class="ct-lg-linkUpdate" target="_blank" href="https://www.analytixlabs.co.in/blog/data-analytics-application/">Data Analytics: Applications</a></p></li>
          <li><p><a class="ct-lg-linkUpdate" target="_blank" href="https://www.analytixlabs.co.in/blog/data-analytics-project-for-students/">Top 10 Data Analytics Projects in 2024</a></p></li>
          <li><p><a class="ct-lg-linkUpdate" target="_blank" href="https://www.analytixlabs.co.in/blog/career-in-data-analytics/">Data Analyst Career Guide 2024</a></p></li>
          <li><p><a class="ct-lg-linkUpdate" target="_blank" href="https://www.analytixlabs.co.in/blog/data-analytics-application/">Data Analytics: Applications</a></p></li>
          <li><p><a class="ct-lg-linkUpdate" target="_blank" href="https://www.analytixlabs.co.in/blog/data-analytics-project-for-students/">Top 10 Data Analytics Projects in 2024</a></p></li>
          <li><p><a class="ct-lg-linkUpdate" target="_blank" href="https://www.analytixlabs.co.in/blog/career-in-data-analytics/">Data Analyst Career Guide 2024</a></p></li>
        </ul>`,
    },
    {
      FaqID: 8,
      title: 'What are the key topics covered in the course?',
      description:
        `<p>With a duration of 5 months, our data analyst course in Bangalore module will contain the following topics:</p>
        <ul class="pg-lg-ul-r-20">
          <li>
              <p><b>Foundations of Mathematics &amp; Statistics</b></p>
              <ul>
                  <li>
                      <p>Master key mathematical and statistical concepts for data analysis.</p>
                  </li>
                  <li>
                      <p>Develop the skills of probability, hypothesis testing, and regression analysis techniques.</p>
                  </li>
              </ul>
          </li>

          <li>
              <p><b>Fundamentals of Programming</b></p>
              <ul>
                  <li>
                      <p>Acquire proficiency in programming languages like Python, R, or SQL to manipulate and analyze data.</p>
                  </li>
                  <li>
                      <p>Learn programming fundamentals such as variables, loops, functions, and conditional statements.</p>
                  </li>
              </ul>
          </li>

          <li>
              <p><b>Business Intelligence, Analytics &amp; Data Visualization</b></p>
              <ul>
                  <li>
                      <p>Explore tools and techniques for transforming raw data into actionable insights.</p>
                  </li>
                  <li>
                      <p>Gain expertise in data visualization tools like Tableau &amp; Power BI to create compelling visualizations that facilitate decision-making.</p>
                  </li>
              </ul>
          </li>

          <li>
              <p><b>Exploratory Data Analysis &amp; Data Management</b></p>
              <ul>
                  <li>
                      <p>Develop data cleaning, preprocessing, and transformation skills to ensure data quality and usability for analysis.</p>
                  </li>
                  <li>
                      <p>Identify patterns and anomalies to extract meaningful insights.</p>
                  </li>
              </ul>
          </li>

          <li>
              <p><b>Python for Analytics</b></p>
              <ul>
                  <li>
                      <p>Gain expertise in Python libraries like Pandas, NumPy, and Matplotlib.</p>
                  </li>
                  <li>
                      <p>Master Python programming for data analysis and manipulation tasks.</p>
                  </li>
              </ul>
          </li>

          <li>
              <p><b>Data Analytics in Practice</b></p>
              <ul>
                  <li>
                      <p>Apply analytical skills to real-world projects across industries.</p>
                  </li>
                  <li>
                      <p>Learn to collaborate with peers to solve complex analytical problems and deliver stakeholder insights.</p>
                  </li>
              </ul>
          </li>
        </ul>`,
    },
    {
      FaqID: 9,
      title: 'What does instructor-led training mean?',
      description:
        `<p>In our Data Analytics training in Bangalore, instructor-led training is the learning format where experienced professionals guide and instruct participants in a live and interactive setting. </p><p>
        Our qualified instructors lead the training sessions, providing real-time explanations, clarifications, and engagement opportunities. This approach ensures a dynamic and personalized learning experience, allowing participants to interact with the instructor, discuss questions, and collaborate with fellow learners, enhancing comprehension and skill acquisition.
        </p>`,
    },
    {
      FaqID: 10,
      title: 'Will I be working on real-world projects?',
      description:
        `<p>AnalytixLabs provides meticulously aligned capstone projects with industry demands as part of the Bangalore Data Analytics Certification Course. These projects enable participants to apply theoretical knowledge in practical settings and delve into impactful initiatives like sports event analysis and reporting, airline data analysis, and more.</p><p>
        These real-world applications reinforce learning and equip participants with the practical skills essential for success in the dynamic field of data analytics.
        </p>`,
    },
    {
      FaqID: 11,
      title: 'What is the level of difficulty for this course?',
      description:
        `<p>Designed with beginners in mind, our Data Analytics Certification Course in Bangalore is meticulously crafted. This comprehensive program ensures a solid grasp of fundamental concepts, providing hands-on experience and in-depth insights. </p><p>
        The course focuses on practical applications and aims to empower participants with a robust understanding of data analytics, making it an ideal starting point for newcomers to the field. Moreover, we've carefully managed the difficulty level, ensuring a smooth and accessible learning journey for all participants.
        </p>`,
    },
    {
      FaqID: 12,
      title: 'Do I need any coding experience?',
      description:
        `<p>Starting a career in data analysis doesn't require an extensive coding background. Our data analyst training in Bangalore is designed to impart crucial programming concepts, making coding accessible to all, irrespective of prior experience. </p><p>
        We're committed to guiding you through this journey and ensuring that your lack of coding proficiency will not impede your success in pursuing a career in data analytics.
        </p>`,
    },
    {
      FaqID: 13,
      title: 'Are business analytics and data analytics the same?',
      description:
        `<p>Business analytics and data analytics often overlap, prompting many to consider these two synonymous. However, the two have differences that become more evident when considering practical implementations. </p><p>
        Data analytics refers to analyzing databases to determine their data content. Data analytical techniques allow you to take raw data and discover patterns to derive useful insights.</p><p>
        Business Analytics is the real-time application of statistical analysis that provides actionable advice. Analysts concentrate on implementing the information they obtain from data in this area.</p><p>
        Business Analytics vs. Data Analytics involves multiple factors, such as requirements, operation type, attached project, and data. They have separate or complementary applications in individual fields of operation and fundamental requirements for specific data management purposes. Read our <a class="ct-lg-linkUpdate" target="_blank" href="https://www.analytixlabs.co.in/blog/business-analytics-vs-data-analytics/">Data Analytics vs. Business Analytics</a> blog to learn the differences and overlaps.
        </p>`,
    },
  ],
}

const toolsRecordBangalore = [
  {
    title: 'Industry-Aligned<br/>Bootcamp Certification',
    description:
      'Aligned with Industry Standards and Designed for practical, job-oriented learning. Cost-effective program with high ROI, making it worth every penny you invest.',
  },
  {
    title: 'For Technical &<br/>Non-technical Candidates',
    description:
      'Industry-first approach to maximize the job prospects for beginner-to-mid career candidates from a technical or non-technical background.',
  },
  {
    title: 'Classroom & Online<br/>Blended',
    description:
      'Choose from 100% online or classroom-blended, hands-on learning with extensive student support and mentorship by seasoned industry experts.',
  },
]

const courseDetailsBangalore = {
  courseFullName: 'DATA ANALYST COURSE IN BANGALORE',
  slug: 'data-analyst-training-course-in-Bangalore',
  shortDescription: 'Certified Data Analytics Course with Placements',
  highHighRegulatory: {
    title: 'Learn high in-demand job skills',
    images: [
      {
        imageUrl: logoNasscomMinistry,
        height: '90%',
        width: '90%',
        col: '4',
      },
    ],
  },
  hoursCount: '500',
  classCount: '46',
  classHours: '+ 74 hrs e-learning',
  projectCount: '18',
  ratingValue: 4.73,
  bestRating: 5,
  worstRating: 2,
  ratingCount: 4860,
  metaTitle: 'Best Data Analyst Courses & Training in Bangalore with Placement',
  metaDescription:
    'Discover the best Data Analyst courses and training in Bangalore with placement opportunities. Learn about top institutes, course content, and fees. Enroll now!',
  metaKeywords:
    'data science course, data analytics certificate, data analytics certification, data analytics online course, data analytics course in India, data analytics course in Bangalore, data analytics training in Bangalore, data analytics course in Bangalore',
  selfStudyHoursCount: '260',
  classCountSubline: ' (8-10 hrs/ week)',
  hoursPerClassCount: '46 x 3 = 138 hrs',
  CourseLearningModes: {
    amountINR: 38000,
    maxAmountINR: 48000,
  },
  taxPercentage: '18',
  LMSCourse: 'ckepznduu00attqjl6qni9nwp',
  courseID: 9997,
}

const PGdetailsBangalore = [
  {
    mainTitle: 'Introduction to Data Analytics Training in Bangalore',
    longDescription: `<p>Data analytics has become a coveted job role for data enthusiasts, and why not. With data becoming the new currency, professionals with analytical thinking and skills have become more essential for any business looking to thrive. </p><p>
    Among India's metropolitan cities, Bangalore remains the center for data growth. This city sees exponential growth year over year, rightfully earning the tagline of being India's Silicon Valley. Frequent tech conferences, events, and new job opportunities have established Bangalore as a central hub for tech and data enthusiasts. </p><p>
    Research shows that the Indian big data realm will reach <a class="ct-lg-linkUpdate" target="_blank" href="https://www.mordorintelligence.com/industry-reports/investment-opportunities-of-big-data-technology-in-india/market-size">$3.38 billion by 2029</a>, with major contributions from top-tier 1 cities like Bangalore, Delhi, and Pune. So, if you want to start a data analytics career, now is the right time. </p><p>
    AnalytixLabs offers meticulously curated data analytics courses in Bangalore (in addition to Noida, Delhi, and Gurgaon). Whether you are just starting or an experienced professional looking to upskill, our data analytics training course in Bangalore will enable you to keep up with the thriving trends of the data domain. </p><p>
    P.S. Download our free industry reports and insights to keep up with the changing data landscape. Gain salary insights, learn about the top learning tools, and understand where the data world is heading. These reports are designed and curated by our in-house industry experts, who combine facts and experience to build a cohesive resource. <a class="ct-lg-linkUpdate" target="_blank" href="https://www.analytixlabs.co.in/free-resources">Download for FREE</a>. </p><p>
    Our data analytics training course in Bangalore uses a comprehensive educational approach to help you navigate this dynamic field easily. In a short time, you will learn to integrate learning tools with practical, hands-on methodologies seamlessly. </p><p>
    Whether you are new to data analytics or an experienced professional, our data analyst training in Bangalore is designed to suit every learner's needs. The course includes all the basics before the core and advanced learning. </p><p>
    Enroll in our data analytics course and develop versatile analytics skills, including data visualization, descriptive analytics, and predictive modeling.
    </p>
    `,
    needHelp: true,
    subDetails : [
      {
        subBulletsPointDescription: '<p>The Learning Objectives for our data analytics training in Bangalore are as follows:</p>' ,
        SubBulletPoint:[
          {
            title : '<b>To master core tools and skillsets to build an analytical approach to various business problems.</b> You will start with the foundations of mathematics, statistics, and programming basics before moving on to statistical analysis and modeling in Python and visualization tools like Excel and PowerBI. Our data analyst course in Bangalore covers all the foundational knowledge and gradually moves into advanced learning.',
          },
          {
            title : `<b>This course aims to equip you with soft skills for wholesome learning.</b> You will learn the art of analytical thinking and problem-solving and how to present your findings in layman's terms. Additionally, we will help you develop the right attitude toward any business problem, create strategies independently, and make business decisions effectively.`,
          },
          {
            title : `<b>Become a Pro Analytics professional.</b> The last objective of this data analytics certification course in Bangalore is to help you become a pro analytics professional. You will gain experience constructing robust data models, learn to leverage various analytical tools, and empower your organization with smart and informed strategies to become a game changer. `,
            description: `<p class='mt-3'>By the end of this course, you will learn how to assess and articulate the precise operational, functional, and ethical impacts of data analytics in structured and unstructured environments, aligning with business goals and values. </p>`,
          }
        ]
      },
    ],
  },
  {
    title: `What is Data Analytics?`,
    longDescription: `<p>Data analytics involves inspecting, transforming, modeling, and cleaning raw data to obtain valuable insights for decision-making. It employs various techniques and tools to analyze large datasets and uncover patterns, trends, and correlations, ultimately deriving meaningful information.</p><p>
    Data analytics encompasses various methods, including descriptive analytics for summarizing and describing data, diagnostic analytics for identifying the causes of past events, predictive analytics for forecasting future trends, and prescriptive analytics for recommending actions based on analysis results.</p><p>
    Organizations leverage data analytics to enhance decision-making, optimize processes, improve efficiency, and gain a competitive edge across various industries. This multidisciplinary field combines elements of statistics, mathematics, computer science, and domain-specific knowledge to extract actionable insights from data.</p><p>
    Mastering these skills through a data analytics course in Bangalore will enable you to transform raw data into actionable intelligence, empowering you to shape the future by uncovering hidden patterns in the data.
    </p>`,
    bulletsPointDescription: `<h3 class="h3 light-blue-color mt-4 mb-3"><b>Types of Data Analytics</b></h3>` ,
    noShowArrow : true,
    subDetails : [
      {
        SubBulletPoint:[
          {
            title : `<h4>Descriptive Analytics</h4>`,
            description: `<p>This foundational type addresses the question, "What happened?" It involves summarizing and organizing data to uncover basic trends, patterns, and statistics. Think of it as painting a picture of the past.</p>`,
            notShowArrow : true,
          },
          {
            title : `<h4>Diagnostic Analytics</h4>`,
            description: `<p>This type seeks to answer, "Why did it happen?" It delves into the root causes behind trends and identifies factors influencing them. Consider it akin to detective work for data!</p>`,
            notShowArrow : true,
          },
          {
            title : `<h4>Predictive Analytics</h4>`,
            description: `<p>Looking ahead, this type asks, "What will happen?" It utilizes statistical models and machine learning to forecast trends and anticipate potential outcomes. It's like having a crystal ball powered by data.</p>`,
            notShowArrow : true,
          },
          {
            title : `<h4>Prescriptive Analytics</h4>`,
            description: `<p>Going beyond predictions, this type asks, "What should we do?" It recommends optimal actions based on insights from all other types, guiding future decisions. Think of it as having a data-driven advisor at your fingertips.</p>`,
            notShowArrow : true,
          },
          {
            title : ``,
            description: `<h5 class="mt-5 ct-pg-font-h5"><blockquote>
            <p class="ct-ml-60 ct-pg-blockquote-color" >For more insights, you may also read: <a class="ct-lg-link-blockquote" href="https://www.analytixlabs.co.in/blog/types-of-business-analytics/" target="_blank">Types of Data Analytics</a></p>
            </blockquote></h5>`,
            notShowArrow : true,
          },
        ]
      },
    ],

  },
  {
    title: `Course Syllabus of Data Analytics Course in Bangalore`,
    BTNTitleShow: true,
    longDescription: `<p>Our data analyst training in Bangalore is suitable for individuals from diverse backgrounds, including Finance, Mathematics, Engineering, and Business Management, who aim to enhance their skills and embark on a career in Analytics.</p><p>
    AnalytixLab's data analyst course in Bangalore is thoughtfully designed to accommodate college graduates' and working professionals' diverse needs and career aspirations. The program offers flexibility, allowing participants to choose specialized modules, opt for different learning intensities, and tailor the curriculum to their specific learning styles and career goals.
    </p>
    `,
    bulletPoint:[
      {
        title : `TERM 1`,
        description: `<p>This term prepares participants for a strong start in structured problem-solving. It covers foundational concepts in mathematics, statistics, and programming, employing a blend of eLearning. </p><p>
          The curriculum incorporates business intelligence, analytics, and data visualization using Excel and PowerBI. Participants also gain knowledge in exploratory data analysis, data management, and SQL for both traditional and cloud environments. This comprehensive approach ensures participants acquire essential skills and knowledge to excel in data analytics.
        </p><ul>
          <li class='mb-2'>Orientation - Setting up for success</li>
          <li class='mb-2'>Problem-Solving - A structured approach to problem-solving</li>
          <li class='mb-2'>Building Blocks - Foundations of Mathematics & Statistics, Fundamentals of Programming (Blended eLearning)</li>
          <li class='mb-2'>Business Intelligence, Analytics & Data Visualization (using Excel & <a class='ct-lg-linkUpdate' href="https://powerbi.microsoft.com/en-us/" target="_blank">PowerBI</a>)</li>
          <li class='mb-2'>Exploratory Data Analysis & Data Management (RDBMS - ETL - <a class='ct-lg-linkUpdate' href="https://www.analytixlabs.co.in/blog/sql-in-data-science/" target="_blank">SQL</a> for Data Science - SQL on Cloud)
          </li>
        </ul>
        `,
      },
      {
        title : `TERM 2`,
        description: `<p>Term 2 of the course focuses on "Python for Analytics," encompassing core Python, data analysis, statistical methods, and predictive modeling. The curriculum is structured to cover essential aspects such as Python foundations, exploratory data analysis, statistical analysis, and linear and logistic regression modeling.</p><p>
        Additionally, the curriculum delves into the practical applications of data analytics across various industries, including marketing, operations, risk analytics, retail, eCommerce, banking, insurance, and telecom. </p><p>
        The term includes practice bootcamps with weekday sessions to reinforce practical skills. These sessions provide hands-on experience and enhance the understanding of data analytics in real-world scenarios.
        </p><h4 class="h5 light-blue-color mt-3 mb-3"><b>Python for Analytics - Statistical Analysis and Modelling using Python :</b></h4><ul>
        <li class='mb-2'><a class='ct-lg-linkUpdate' href="https://www.analytixlabs.co.in/deep-learning-with-python" target="_blank">Python</a> Foundation (Core Python) - Python Packages  </li>
        <li class='mb-2'>Exploratory Data Analysis - Data Visualization</li>
        <li class='mb-2'>Statistical Analysis (Basic Statistics - Statistical Methods)</li>
        <li class='mb-2'>Predictive Modeling using Linear & Logistic Regression</li>
        </ul>
        <h4 class="h5 light-blue-color mt-3 mb-3"><b>Data Analytics Applications - Industries & Functions (24 hrs eLearning)</b></h4><ul>
        <li class='mt-2'>Marketing Analytics</li>
        <li class='mt-2'>Operations Analytics</li>
        <li class='mt-2'>Risk Analytics</li>
        <li class='mt-2'>Industry Application of Analytics in Retail, eCommerce, Banking, Insurance, Telecom, etc.</li>
        <li class='mt-2'>Practice Bootcamps (Weekday sessions)</li>
        </ul>
        `,
      },
      {
        title : `TERM 3`,
        description: `<p>This term focuses on the practical application of data analytics through an industry capstone project. This project encompasses end-to-end pipeline development, business requirement gathering, and comprehensive documentation. The curriculum also integrates analytics project management principles and strongly emphasizes honing problem-solving skills.</p><p>
        Furthermore, Term 3 prioritizes career preparation with a comprehensive approach. This includes readiness for interviews, participation in mock interviews, placement assistance, and involvement in a success accelerator program that covers resume building and interview preparation. </p><p>
        The goal is to equip students with technical expertise and the essential skills and tools needed for a successful and seamless transition into the professional realm of data analytics.
        </p><h4 class="h5 light-blue-color mt-3 mb-3"><b>Data Analytics in Practice - Industry Capstone Project work - Final Viva</b></h4><ul>
        <li class='mb-2'>Data Analytics Project - End-to-End Pipeline</li>
        <li class='mb-2'>Business Requirement Gathering - Problem-Solving</li>
        <li class='mb-2'>Documentation - best practices </li>
        <li class='mb-2'>Analytics Project Management</li>
        </ul><h4 class="h5 light-blue-color mt-3 mb-3"><b>Placement Preparation - Interview Preparation - Mock Interviews</b></h4>
        <ul>
        <li class='mb-2'>Placement Readiness Programme</li>
        <li class='mb-2'>Success Accelerator</li>
        <li class='mb-2'>Career Assistance (Resume, Mock Sessions, Interview prep, etc.)</li>
        </ul>
        <p>The curriculum is strategically designed to build upon the foundation laid in each preceding term, offering our students a seamless and progressive educational experience. The program spans four days a week, covering both weekdays and weekends.</p><p>
        You can engage in interactive learning through live online sessions or attend classroom sessions in Gurgaon, Bangalore, and Noida. The program incorporates a blended e-learning approach, live doubt support, and practice bootcamps. Additionally, students will have access to session recordings for future reference and self-study, enhancing the overall learning experience.
        </p>
        `,
      },
    ],
  },
  {
    mainTitle: `Data Analytics Certification Details`,
    longDescription: `<p>With our extensive domain expertise and esteemed clientele, AnalytixLabs Certification holds significant prestige within the industry, domestically and internationally. As a top institute for data analysis courses in Bangalore, we prioritize maintaining the integrity of our certification process.</p>`,
  },
  {
    title: `Benefits of our certification include:`,
    subDetails : [
      {
        SubBulletPoint:[
          {
            title : `Certification is awarded upon a thorough assessment, including mandatory case studies, assignments, multiple-choice questions (MCQs), and viva assessments embedded in the course curriculum.`,

          },
          {
            title : `Completion of certification is required within one year of course registration to ensure timely progression and proficiency.`,
          },
          {
            title : `Trainees facing difficulties with assignments and projects are urged to seek assistance and support for improvement. However, academic integrity is non-negotiable, and plagiarism will not be tolerated in the evaluation process.`,
            description: `<p class='mt-3'>These measures are in place to uphold the credibility of our certification and accurately showcase the true capabilities of our certified professionals.</p>
            `
          },
        ]
      },
    ],
  },
  {
    title: `Capstone Projects Included in Data Analytics Bangalore Course`,
    longDescription: `<p>Our data analyst course Bangalore also includes the following assignments and projects for self-study and hands-on learning.</p>`,
    needHelp: true,
    bulletPoint:[
      {
        title : `Data Analytics Project - End-to-End Pipeline`,
        description: `<p>Our Data Analytics Project boasts a robust end-to-end pipeline that effortlessly manages data from collection through cleaning, transformation, and analysis. We meticulously sourced and cleaned diverse datasets, applied advanced transformation techniques and utilized cutting-edge tools for insightful analytics.</p><p>
        The streamlined pipeline enhances efficiency and guarantees high-quality and actionable results. This comprehensive approach underscores our commitment to excellence in data analytics and empowers informed decision-making.
        </p>`,
      },
      {
        title : `Business Requirement Gathering - Problem-Solving`,
        description: `<p>In tackling business challenges, our problem-solving journey commences with the meticulous process of defining the business problem. This initial step lays the foundation for subsequent phases, commencing with scoping the requirements.</p><p>
        Our systematic problem-solving approach advances from defining the business problem to deploying solutions. It initiates by scoping requirements, establishing analytics objectives, and meticulously gathering and preparing data. </p><p>
        The journey continues through solution development, validation, and implementation, concluding with the pivotal stages of tracking and fine-tuning. This comprehensive methodology ensures a strategic and effective approach to addressing business challenges.
        </p>`
      },
      {
        title : `Documentation - Best Practices`,
        description: `<p>Effective documentation stands as a cornerstone for successful projects, ensuring clarity, continuity, and collaboration. Embracing best practices in documentation streamlines processes and enhances overall project efficiency.</p><p>
        The emphasis is on clarity, coherence, and academic rigor, specifically integrating data analytics in practice. This highlights the contemporary relevance of data analytics in research methodologies, ensuring that the documentation meets academic standards and contributes meaningfully to the field of study.
        </p>`
      },
    ],
  },
]

const PGdetailsBangaloreSecondSections = [
  {
    title: `Admission and Application Process`,
    needHelp: true,
    isShowRequestACallback:true,
    longDescription: `<p>We take a personalized approach to admission for all our courses. To enroll in our data analytics certification course in Bangalore, you must express interest by contacting us by phone, using an inquiry form, or email. Once we receive your interest, one of our learning advisors will contact you within 24 working hours.</p><p>
    Our learning advisor will help you understand the end goal of this course and how relevant it is for you. This will be based on your academics, professional know-how, and current location. Our courses have both online and offline learning modes. Our learning advisors will help you understand which mode is perfect for you. </p><p>
    We want to ensure that you get the most out of our courses, and we do that by understanding your expectations. Our learning advisors will thoroughly and transparently guide you through course details, job scenarios, location details, and learning preferences. </p><p>
    Once you and your learning advisor are on the same page, you may pay the admission fees to reserve your spot for the course for the next upcoming batch.
    </p>`,
  },
  {
    title: `Data Analytics Course Eligibility`,
    longDescription: `<p>The Data Analytics certification course is well-suited for beginners, recent graduates, and early-stage professionals seeking to enhance their skills. Our certification programs cater to learners familiar with the fundamentals of data analytics and offer a pathway to more experienced roles. </p><p>
    Upon completing this course, participants can pursue any of our diploma courses based on their chosen specialization.</p><p>
    Notably, our Data Analytics certification training course in Bangalore has no prerequisites for prior knowledge or experience in data analytics. It is specifically designed to accommodate individuals with diverse educational backgrounds keen on exploring the data field.
    </p>`,
    bulletsPointDescription: `<h3 style='color:#013368'>While not mandatory, general requisites include:</h3>` ,
    bulletPoint:[
      {
        title : `Bachelor's degree`,
        description: `(Preferred in relevant fields like statistics, computer science, or mathematics) While not obligatory, having a bachelor's degree in quantitative fields is a bonus, particularly for university-level programs.
        <p class='mt-3'>Additional requirements that can be an advantage for you while learning this course - </p>        `,
      },
      {
        title : `Basic Understanding of Statistics and Mathematics:`,
        description: `A foundational understanding of statistics and mathematics is beneficial because most data analytics courses involve working with data.`,
      },
      {
        title : `Programming Skills:`,
        description: `Proficiency in programming languages like Python or R is advantageous, as many data analysis tasks may require such skills. Some courses may require prior programming knowledge, while others offer introductory lessons for newcomers.
        <p class='mt-4'>
        <b>Note:</b> Our data analytics course will <strong><u>cover all the foundational basics</u></strong> of programming, problem-solving, and data management. If you don’t have any prior knowledge or experience in the data field, you can learn the same in our first set of learning sessions.
        </p>
        `,
      },
    ],
  },
  {
    mainTitle: `Online Data Analytics Course in Bangalore- Learning Methodology`,
    longDescription: `<p>AnalytixLabs is voted one of the premium data science and analytics institutes in Bangalore, India. We are home to data enthusiasts from various fields, domains, and industries since 2011 and still counting. Our data analytics certification course in Bangalore focuses on practical learning. We offer an experiential learning environment that is designed to transform your career. </p><p>
    Our course curriculums are regularly updated and modified to align with the changing demands of the industries and new trends in the domain. We offer three distinct learning modes to meet our students' needs and preferences. Whether you choose weekday sessions, weekend bootcamps, or full online learning, we have an option that is right for you. </p><p>
    Our learning instructors bring global experience that combines practical knowledge implementation, corporate know-how, and years of experience in this domain, which has seen transitions at best (and worst). Their insights, rooted in real-world experiences, keep students abreast of ongoing trends.</p><p>
    We encourage students to choose from diverse learning modes, including classroom bootcamps, interactive live online sessions, and self-paced e-learning modules. Throughout the course, all students are granted access to a cutting-edge learning system that is continuously updated to meet industry requirements.
    </p>`,
    bulletPoint:[
      {
        title : `Self-paced eLearning`,
        description: `<p>Learn through on-demand e-learning video sessions in your learning management system. Extensive doubt support is provided over mail, online, and in person.</p>`,
      },
      {
        title : `Classroom & Bootcamp`,
        description: `<p>Experience experiential learning with in-person mentorship. We offer weekday, weekend, and evening data analytics courses in Bangalore.</p>`,
      },
      {
        title : `Interactive online classes`,
        description: `<p>You can attend live online data analytics courses in Bangalore from anywhere. These fully interactive sessions facilitate real-time discussions and questions/answers.</p>`,
      },
    ],
    subDetails : [
      {
        longDescription: `<p>
        In our data analytics training in Bangalore, we prioritize a personalized approach with small batch sizes and individualized counseling, fostering high dedication and personal attention.</p><p>
        The curriculum is designed to integrate practical exercises and real-world business datasets into practice assignments, ensuring that knowledge retention is comprehensive and practical.
        </p>
        `,
      },
    ],
  },
  {
    title: `Learning Benefits with AnalytixLabs`,
    longDescription: `<p>Our program adopts an experiential learning approach, enhancing the value of your education and instilling confidence in your abilities as a proficient data analyst. With placement assistance included, this data analytics course stands out as one of the premier options in Bangalore.</p>`,
    subDetails : [
      {
        subBulletsPointDescription: `` ,
        SubBulletPoint:[
          {
            title: `<strong>Industry-focused curriculum: </strong><span>Our courses cater to all career goals, whether you are looking for general data analysis skills, machine learning tools, or specific industry-based learning. We offer tailored programs designed to keep you industry relevant at all times.</span>`,
          },
          {
            title: `<strong>Experienced instructors: </strong><span>Our instructors' panel includes experienced data professionals who bring practical insights. Our instructors combine their experiences with the course syllabus to offer learning experiences beyond theoretical know-how.</span>`,
          },
          {
            title: `<strong>Hands-on learning: </strong><span>We offer hands-on learning experience and help you prepare for real problem-solving through elaborate case studies, assignments, and projects. Learning by doing is crucial in data analytics, which is the core of our learning module. </span>`,
          },
          {
            title: `<strong>Career support: </strong><span>We offer personalized career guidance, from resume-building to interview preparation. Our instructors will help you streamline your job search, job application, interview, and final consultation to help you choose the right job offer.</span>`,
          },
          {
            title: `<strong>Active community: </strong><span>AnalytixLabs has an active and thriving community of novice and experienced data enthusiasts. Connect with peers and get real-time help in data, learning, and implementation. Our students and instructors foster an active learning community where you can contribute forever.</span>`,
          }
        ],
      },
    ],
  },
  {
    title: `Skills You Will  Learn in this course`,
    longDescription: `<p>A comprehensive set of tools and library packages is included in the data analytics certification course in Bangalore, including but not limited to:</p>
    `,
    needHelp: true,
    subDetails : [
      {
        subBulletsPointDescription: `<h3 class="h3 light-blue-color mt-4 mb-3"><b>Tools Included</b></h3>` ,
        SubBulletPoint:[
          {
            title: `<strong>Excel</strong><span> - </span><span>Widely used spreadsheet software for data organization and analysis .</span>`,
          },
          {
            title: `<strong>PowerBI</strong><span> - </span><span>Microsoft-developed tool for efficient data analysis with user-friendly features.</span>`,
          },
          {
            title: `<strong>SQL Server:</strong><span> - </span><span>Cloud-based SQL Server for managing relational databases.</span>`,
          },
          {
            title: `<strong>Python</strong><span> - </span><span>Versatile programming language used for web development, data analysis, and machine learning.</span>`,
          },
          {
            title: `<strong>Jupyter Notebook</strong><span> - </span><span>Interactive web-based platform for creating and sharing documents with live code.</span>`,
          },
          {
            title: `<strong>GIT</strong><span> - </span><span>Version control system for tracking changes in source code during software development.</span>`,
          },
          {
            title: `<strong>MS PowerPoint</strong><span> - </span><span>Presentation software for creating slideshows and visual content.</span>`,
          },
          {
            title: `<strong>Statsmodel</strong><span> - </span><span>Python library for data analysis, data science, and statistical modeling.</span>`,
          },
          {
            title: `<strong>Seaborn</strong><span> - </span><span>Python library for creating statistical visualizations built on Matplotlib.</span>`,
          },
          {
            title: `<strong>Pandas</strong><span> - </span><span>Python data analysis library specializing in data manipulation and cleaning.</span>`,
          },
          {
            title: `<strong>NumPy</strong><span> - </span><span>Versatile array processing package for efficient scientific computations in Python.</span>`,
          },
          {
            title: `<strong>Matplotlib</strong><span> - </span><span>Python library for 2D plotting graphics, offering diverse data visualization capabilities.</span>`,
          },
          {
            title: `<strong>DAX</strong><span> - </span><span>Data Analysis Expressions, a formula language for data analysis, often associated with Microsoft Excel and PowerBI.</span>`,
            description: `<p class='mt-3'>Moreover, you will develop soft skills like business acumen and business analysis and the ability to articulate data findings in a way that everyone understands, enhancing the comprehensibility of your predictions.</p><p>
            The course focuses on fostering two pivotal skill sets, namely:
            </p>`,
          },
        ],
      },
      {
        longDescription: `<h3 class="h3 light-blue-color mt-4 mb-3"><b>Technical Skills</b></h3><p>This course encompasses instruction on many key technical languages and tools, including </p>`,
        SubBulletPoint:[
          {
            title : `<h4>Core Python</h4>`,
            description: `<p>Core Python encompasses the fundamental and essential elements of the Python programming language. This includes basic syntax, data types, control structures (such as loops and conditionals), functions, and essential libraries inherent to Python.</p><p>
            Mastering core Python is essential for establishing a robust programming foundation. It equips developers with the skills necessary to handle various tasks, from simple scripting to more intricate software development. As the language's foundational framework, proficiency in core Python concepts is indispensable for anyone aspiring to become skilled in Python programming.
            </p>`
          },
          {
            title : `<h4>JIRA</h4>`,
            description: `<p><a class='ct-lg-linkUpdate' href="https://www.atlassian.com/software/jira/guides/getting-started/introduction" target="_blank"><b>JIRA</b></a> is an essential business analytics tool for evaluating team performance and creating detailed, visually enriched data reports. Particularly useful in operational analytics, it can also serve as a database for tracking and managing various aspects of projects.</p>`
          },
          {
            title : `<h4>Power BI</h4>`,
            description: `<p><a class='ct-lg-linkUpdate' href="https://powerbi.microsoft.com/en-us/" target="_blank">PowerBI</a>, developed by Microsoft, is a powerful business analytics tool offering comprehensive and interactive data visualization and analysis capabilities. It provides a rich set of features for data preparation, modeling, and creating visually compelling reports and dashboards.</p><p>
            Designed to facilitate data-driven decision-making, Power BI boasts a user-friendly interface and robust tools that empower users to explore and understand their data effectively. Whether dealing with structured or unstructured data, Power BI enables users to transform raw information into meaningful insights, making it a valuable asset for businesses aiming to leverage their data strategically. </p><p>
            Its versatility and functionality make it a popular choice for organizations looking to enhance their analytical capabilities and gain actionable insights from their data.
            </p>`
          },
          {
            title : `<h4>Microsoft Excel</h4>`,

            description: `<p>Microsoft Excel is a highly popular spreadsheet software within the Microsoft Office suite, designed for various data-related tasks. It offers a user-friendly interface for creating, editing, and manipulating spreadsheets containing numerical and text data.</p><p>
            One of Excel's notable features is the inclusion of pivot tables, which empower users to analyze and summarize large datasets efficiently. Pivot tables provide a dynamic way to organize and interpret data, facilitating insightful data analysis.</p><p>
            Excel is widely utilized for tasks such as data entry, calculation, charting, and financial analysis, and it proves to be a versatile tool suitable for personal and professional use. Its capabilities make it an integral part of many workplaces and an essential tool for individuals managing and analyzing data.
            </p>`
          },
          {
            title : `<h4>Microsoft PowerPoint</h4>`,
            description: `<p>PowerPoint, a Microsoft Office suite component, is a valuable tool for data visualization, particularly when communicating data insights to non-technical team members and stakeholders. When used in conjunction with other Office tools, it operates as part of an analytical suite.
            </p>`
          },
          {
            title : `<h4>Azure (Cloud Computing)</h4>`,
            description: `<p><a class='ct-lg-linkUpdate' href="https://azure.microsoft.com/en-in/" target="_blank">Azure</a> Analysis Services offers cloud-based, enterprise-grade data models. These models allow the assembly, refinement, and security of data from multiple sources in a tabular semantic data model. They contribute to cloud-based data analytics and management.</p>`
          },
          {
            title : `<h4>Tableau (Optional)</h4>`,
            description: `<p>An end-to-end data analytics platform designed to prepare data for analysis. You can collaborate and share your data insights with this tool. The visual analysis in Tableau is highly powerful and is easily shared across organizations.
            </p>`
          },
        ]
      },
      {
        longDescription: `<h3 class="h3 light-blue-color mt-4 mb-3"><b>Soft Skills</b></h3><p>Soft skills are instrumental in professionals' success across various domains; data analysis is no exception. A proficient data analyst not only possesses technical prowess but also excels in essential soft skills that enhance effectiveness in the dynamic and collaborative nature of data-driven environments. </p><p>
        Here, we delve into the core soft skills that distinguish a skilled data analyst:
        </p>`,
        SubBulletPoint:[
          {
            title : `<h4>Communication Skills</h4>`,
            description: `<p>Data analysts must effectively convey complex technical information to non-technical stakeholders. Concise and clear communication is essential for sharing insights and recommendations.</p>`
          },
          {
            title : `<h4>Critical Thinking</h4>`,
            description: `<p>It is vital to think critically and analyze data objectively. Data analysts must evaluate information, identify trends, and draw meaningful conclusions to support decision-making.</p>`
          },
          {
            title : `<h4>Problem-Solving</h4>`,
            description: `<p>Data analysts often encounter challenges in data quality, incomplete datasets, or ambiguous requirements. Strong problem-solving skills enable analysts to navigate obstacles and find effective solutions.</p>`
          },
          {
            title : `<h4>Attention to Detail</h4>`,
            description: `<p>Precision is crucial in data analysis. Data analysts must pay close attention to details to ensure accuracy in their work, from cleaning and processing data to creating visualizations.</p>`
          },
          {
            title : `<h4>Time Management</h4>`,
            description: `<p>Data projects often have deadlines, and data analysts must manage their time efficiently to meet them. Prioritizing tasks and balancing workloads is key to success.</p>`
          },
          {
            title : `<h4>Adaptability</h4>`,
            description: `<p>The field of data analysis is dynamic, with evolving technologies and methodologies. Data analysts must stay adaptable, embracing new tools and techniques to stay current and effectively address evolving challenges in the data landscape.</p>`
          },
        ]
      },
    ],

  },
]

const MarketingAnalyticsBangalore = [
  {
    title: `Top Job Roles You Can Explore in Bangalore`,
    bulletsPointDescription: `<p>Upon completing the course, you will have the opportunity to explore a range of career paths aligned with your acquired skills and knowledge. This exploration will enable you to apply your expertise in diverse professional settings, discovering the most suitable and fulfilling career opportunities for your unique skill set.</p>` ,
    bulletPoint:[
      {
        title : `Data Analyst`,
        description: `<p>Responsibilities: Data Analysts review and analyze data to extract meaningful insights, enhancing overall business performance. This role involves interpreting patterns, identifying trends, and providing actionable recommendations for strategic decisions.</p>`,
      },
      {
        title : `Analytics Consultant`,
        description: `<p>Responsibilities: This role entails analyzing large datasets to extract insights crucial for business objectives. You'll develop data-driven strategies to optimize processes and drive growth, collaborating with teams to communicate findings effectively and provide actionable recommendations.</p>`,
      },
      {
        title : `Business Analyst`,
        description: `<p>Responsibilities: Business Analysts bridge the gap between data and business strategy. This role requires a comprehensive understanding of business processes, identifying areas for improvement, efficiency enhancement, and strategic alignment. Extracted insights guide decision-makers toward informed choices.</p>`,
      },
      {
        title : `Business Intelligence Analyst`,
        description: `<p>Responsibilities: In the dynamic field of Business Intelligence, a BI Analyst translates raw data into actionable insights. Prime responsibilities include transforming complex information into comprehensible reports and dashboards, impacting internal and external stakeholders for informed decision-making.</p><p>
        Join our vibrant community and elevate your data analytics journey. Opt for our data analytics training in Bangalore to unlock your potential in the thrilling realm of data!
        </p>`,
      },
    ],
  },
]

const PlacementAndSupportBangalore = [
  {
    isShowRequestACallbackOnlyCTA: true,
    title: `Data Analytics Course with Placement Support`,
    longDescription: `<p>At AnalytixLabs, you get end-to-end learning and career support. After completing our data analyst training in Bangalore, our learning experts will guide you step by step for the next lap. </p><p>
    We offer placement assistance in which we will help you build your online profile, have personal interview training sessions, and filter the right kind of job opportunities. </p><p>
    We take pride in placing our students in various Fortune 500 companies across multiple industries. </p><p>
    Job-relevant skills are central to our philosophy, which shapes a comprehensive data analyst course in Bangalore focusing on placement opportunities. Our learning experts will aid you as long as you need, guiding you to choose the right career. We will actively support you by connecting and introducing you to various companies that fit you.
    </p>
    <p>AnalytixLabs is committed to helping you launch your career successfully. Our default placement guidance program focuses on developing job-relevant skills. You'll receive expert guidance on resume building, interview preparation and how to apply for relevant job roles. For those seeking an even greater advantage, our optional Placement Readiness Program (PRP) offers bouquet of benefits:</p>
    `,
    bulletPoint:[
      {
        title : `No Upfront Fees`,
        description: `<p>We believe in shared success. That's why we offer a unique pay-after-placement model which comes with zero upfront costs. Instead, you'll only invest 10% of your first-year CTC that too if you secure a job with a minimum salary of INR 7 Lakhs per annum within six months of completing PRP.</p>`,
      },
      {
        title : `Placement Readiness Program`,
        description: `<p>An optional 2-month, industry-focused module designed to enhance technical and soft skills. It includes coaching, key topic recaps for interviews, practice tests, case studies, and simulated recruitment drives. The program also features mock interviews with an industry panel, providing one-to-one feedback and guidance.</p>`,
      },
      {
        title : `Flexible Opt-in`,
        description: `<p>After certification you can take upto 30 days to decide and opt in for the Placement Readiness Program. This option is ideal for those who are looking for upskilling or a coveted industry-recognized certificate, feel confident in their acquired skills, and wish to pursue job applications independently. These students will still be eligible for basic placement guidance.</p>`,
      },
      {
        title : `Continuous Support`,
        description: `<p>After you successfully complete PRP, we will continue offering ongoing support as long as you need it. Many of our students benefit from numerous interview calls and rewarding career prospects attributed to the skills acquired during the course.</p>`,
      },
    ],

  },
  {

    title: `Career Stories by AnalytixLabs Alumni`,
    longDescription: `<p>We are proud and humbled by the excellent achievements of our course graduates. Our students have seamlessly translated their learning into tangible results, leading critical projects and securing coveted positions in top brands across multiple industries. </p><p>
    These success stories speak volumes of the knowledge our students have acquired through our experiential and guided learning approach. We give our learning experts their due credits in building a learning environment that focuses on hands-on learning and a cohesive learning approach. </p><p>
    We continue to encourage our students to participate in activities, complete the assignments, and take advantage of one-on-one sessions with their mentors to derive the maximum out of the course. And we are more than elated to see our approach transcending into successful careers for our students since 2011.
    </p>`,
  },

]
const UpcomingBatchesBangalore = [
  {
    title: `Data Analytics Course in Bangalore- Upcoming Batches`,
    longDescription: `<p>Tailor your experience with our Flexible Learning options: Classroom, Interactive Live Online, & Blended eLearning.</p>`,
    needHelp: false,
  },
]

const careerGraphBangalore = {
  title: 'CAREER GRAPH [IN INR]',
  careerList: [
    {
      imageUrl: daFresher,
      upperContent: 'Data Analyst [Fresher]',
      lowerContent: '5-7 L/Year',
    },
    {
      imageUrl: seniorDA,
      upperContent: 'Senior Data Analyst',
      lowerContent: '12-20 L/Year',
    },
    {
      imageUrl: daIV,
      upperContent: 'Data Analyst IV',
      lowerContent: '20+ L/Year',
    },
  ],
}

const DataAnalyticsCourseFeeDataBangalore = {
  mainTitle :  `Data Analytics Course in Bangalore - Fees and Duration`,
  table : {
    title: `Data Analytics Course Fee and Duration`,
    mobileViewTable:
    `<div class='pg-table-width'>
    <table style="border-collapse: collapse; width: 100%; border: 1px solid #000000; padding: 10px 20px;">
    <tbody>
    <tr>
    <td style="border-right: 1px solid #000000; border-bottom: 1px solid #1c4587; padding: 25px 20px 10px; background-color: #1c4587; width: 40%;" rowspan="2">
    <p style="text-align:center"><span style="color: #ffffff;"><strong>Data Analytics Course in Bangalore</strong></span></p>
    </td>
    <td style="border: 1px solid #000000; padding: 20px 20px 10px; background-color: #1c4587; text-align: center;">
    <p><span style="color: #ffffff;"><strong>Duration</strong></span></p>
    </td>
    <td style="border: 1px solid #000000; padding: 20px 20px 10px; background-color: #1c4587; text-align: center;">
    <p><span style="color: #ffffff;"><strong> Fees</strong></span></p>
    </td>
    </tr>
    <tr>
    <td style="border: 1px solid #000000; padding: 20px 20px 10px; background-color: #cfe2f3; width: 30%; text-align: center;">
    <p><span style="color: #1c458e;">5 months</span></p>
    </td>
    <td style="border: 1px solid #000000; padding: 20px 20px 10px; background-color: #cfe2f3; text-align: center;">
    <p><span style="color: #1c458e;">INR 52,000/- plus taxes</span></p>
    </td>
    </tr>
    </tbody>
    </table>
    </div>`
      ,
  },
  jobScope : [
    {
      longDescription: `<div class="menu-title-pg-diploma mb-3 mt-4 menu-title-pg-diploma-analytics"><h1>Data Analytics Career Graph in Bangalore </h1></div></div><p>Bangalore's tech scene makes it ideal for your data analytics education. With its cutting-edge infrastructure, Bangalore is India's prominent tech hub. Major tech companies like TCS, INFOSYS, AIRTEL, ICICI, HDFC, and others actively recruit business analytics professionals. </p><p>
      A recent report underscores the substantial growth anticipated in the data analytics industry, projecting the creation of over <a class="ct-lg-linkUpdate" target="_blank" href="https://www.ibef.org/blogs/scope-of-data-analytics-in-india-and-future">11 million jobs</a> by 2026.</p><p>
      Enrolling in a data analytics course in Bangalore offers numerous advantages. You can network and collaborate with many tech companies and startups, particularly those specializing in data analytics. </p><p>
      This presents an opportunity to gain invaluable access to industry resources and expertise right at your doorstep. Imagine learning alongside potential colleagues and exploring internship opportunities within this dynamic ecosystem.</p><p>
      Data analysts have an average compensation of INR 4 to 10 LPA in Bangalore, depending on their industry & experience. For more information, refer to our <a class="ct-lg-linkUpdate" target="_blank" href="https://www.analytixlabs.co.in/blog/data-science-101-guide/">Data Science Salary Report</a>.</p><p>
      Overall, the career graph for a data analyst in Bangalore looks like this:
      </p>`,
    },
  ],
  otherDescription: `<p class='mt-3'><b>*</b> The above numbers are the annual base pay for the said role</p>`,
  jobScopeOtherPart: [
  ]
}

const  DataAnalyticsBangaloreTable = {
  title: `Data Analytics Course in Bangalore- Fees and Duration`,
  mobileViewTable:
  `<div class='pg-table-width' style="border-collapse: collapse; width: 80%;">
  <table style="border-collapse: collapse; width: 100%; border: 1px solid #000000; padding: 10px 20px;">
  <tbody>
  <tr>
  <td style="border-right: 1px solid #000000; border-bottom: 1px solid #1c4587; padding: 25px 20px 10px; background-color: #1c4587; width: 35%;" rowspan="2">
  <p style="text-align:center"><span style="color: #ffffff;"><strong>Data Analytics Course in Bangalore</strong></span></p>
  </td>
  <td style="border: 1px solid #000000; padding: 20px 20px 10px; background-color: #1c4587; text-align: center;">
  <p><span style="color: #ffffff;"><strong>Duration</strong></span></p>
  </td>
  <td style="border: 1px solid #000000; padding: 20px 20px 10px; background-color: #1c4587; text-align: center;">
  <p><span style="color: #ffffff;"><strong> Fees</strong></span></p>
  </td>
  </tr>
  <tr>
  <td style="border: 1px solid #000000; padding: 20px 20px 10px; background-color: #cfe2f3; width: 30%; text-align: center;">
  <p><span style="color: #1c458e;">5 months</span></p>
  </td>
  <td style="border: 1px solid #000000; padding: 20px 20px 10px; background-color: #cfe2f3; text-align: center;">
  <p><span style="color: #1c458e;">INR 80,000/- Plus GST</span></p>
  </td>
  </tr>
  </tbody>
  </table>
  </div>`
    ,
  longDescription: `<p class='mt-3'><span style="font-weight: 400;">Our data analytics course in Bangalore has industry-standard fees. The course duration is <b>5 months</b> or <b>850 learning hours</b>, and the total fee is <b>INR 80,000</b><span style="font-weight: 400;"> Plus GST.</span></p>`,
}

const skillsAndToolsBangalore = {
  title: 'Industries Covered & Functions',
  longDescription:
    '<p>Data analytics training Bangalore explores real-time data analytics applications across diverse industries, including:</p>',
  descWithBg: [
    {
      label:
        'Retail/eCommerce, BFSI (Finance and Insurance), Healthcare, Telecom, Hospitality, Manufacturing',
    }
  ],
  LastDescription:
    `<p>Our data analytics course in Bangalore encompasses various industries to prepare you for every business challenge. The course offers a cohesive learning of the various functions that require data analytics professionals, be it any industry you are in.
    <p>`,
}

const PGskillsAndToolsBangalore = [
  {
    bulletPoint:[
      {
        title : `Marketing Analytics`,
        description: `<p>Data analytics plays a crucial role in comprehending customer behavior, uncovering the reasons behind their actions, and optimizing the overall return on investment (ROI) for marketing activities.</p><p>
        In this course, you will learn the techniques and methodologies for analyzing marketing data, identifying trends and patterns, and adopting an analytical approach to refining marketing campaigns. You will better understand user behavior and be able to facilitate smart marketing strategies and cultivate customer loyalty.
        </p>`,
      },
      {
        title : `Operational Analytics`,
        description: `<p>Operational analytics is geared towards enhancing the efficiency of business operations, constituting a subset of business analytics. It emphasizes synchronizing data from the warehouse into diverse business tools, ensuring accessibility for all employees, irrespective of their technical expertise. </p><p>
        This course will help you learn to monitor current and real-time operations to improve productivity and streamline daily business activities.
        </p>`,
      },
      {
        title : `Risk Analytics`,
        description: `<p>Risk analytics equips individuals with the skills to quantify and predict risks precisely. Participants will learn to leverage various technologies to explore business data, formulate risk-driven scenarios, and accurately predict potential future risks. </p><p>
        Applications of data analytics in risk management assist businesses in identifying, analyzing, and evaluating risks, thereby preventing large-scale debacles.
        </p>`,
      }
    ],
  },
]

const demoBrochureBangalore = {
  header : 'DEMO & BROCHURE',
}
const feesRecordPGBangalore = '70,000';

export {
  toolsList,
  toolsRecordBangalore,
  courseDetailsBangalore,
  bannerRecordBangalore,
  faqsBangalore,
  PGdetailsBangalore,
  careerGraphBangalore,
  DataAnalyticsCourseFeeDataBangalore,
  skillsAndToolsBangalore,
  MarketingAnalyticsBangalore,
  PlacementAndSupportBangalore,
  DataAnalyticsBangaloreTable,
  PGskillsAndToolsBangalore,
  PGdetailsBangaloreSecondSections,
  demoBrochureBangalore,
  feesRecordPGBangalore,
  UpcomingBatchesBangalore
}

// TODO: SampleArrayObject use in this file.

// const SampleArrayObject = {
//   mainTitle : ``,
//   title: ``,
//   needHelp: boolean,
//   BTNTitleShow: boolean,
//   longDescription: `<p></p>`,
//   bulletsPointTitle : ``,
//   bulletsPointDescription: `` ,
//   bulletPoint:[
//     {
//       title : ``,
//       description: ``,
//     }
//   ],
//   subDetails : [
//     {
//       title: ``,
//       longDescription: ``,
//       subBulletsPointTitle :``,
//       subBulletsPointDescription: `` ,
//       SubBulletPoint:[
//         {
//           title : ``,
//           description: ``
//         },
//       ]
//     },
//   ],
// }
