/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from 'react'
import FAQItem from './faqItem'

const FAQSection = memo(({ faqRecord, isShowHeadTag }) => {
  let faqsList = []
  faqRecord?.faqsData &&
    faqRecord.faqsData.length > 0 &&
    faqRecord.faqsData.forEach((faq, index) => {
      faqsList.push(<FAQItem key={`__faqRecord__${index}`} faq={faq} />)
    })
  return (
    <section id="projects">
      <div className="container">
        <div className="row m-0">
          <div className="right-block-inner">
            <div className="row mt-4">
              <div className="col-md-12">
                <a className="row m-0">
                {isShowHeadTag ? 
                  <h2 className="question-title-pg-diploma-analytics mb-3">
                    {faqRecord.faqTitle}
                  </h2> : 
                  <span className="question-title-pg-diploma-analytics mb-3">
                    {faqRecord.faqTitle}
                  </span>
                }
                </a>
              </div>
              {faqsList}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
})
export default FAQSection
