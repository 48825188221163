import powerBISmallIcon from 'assets/images/pg-diploma-analytics/logo-01.svg'
import logoNasscomMinistry from 'assets/images/pg-diploma/logo-nasscom-ministry-removebg.png'
import daFresher from 'assets/images/svgs/userWithBg.svg'
import seniorDA from 'assets/images/svgs/presentation.svg'
import daIV from 'assets/images/svgs/searchAnalyst.svg'

const toolsList =[
  {
    "id" : 1,
    "title": "Excel",
    "link": null,
    "largeImage": "image/toolsImage/excel-1601020530983.png"

  },
  {
    "id" : 2,
    "title": "PowerBi",
    "link": null,
    "largeImage": "image/toolsImage/PowerBI-1662105782661.jpg"

  },
  {
    "id" : 3,
    "title": "SQL",
    "link": null,
    "largeImage": "image/toolsImage/sql-1601021568250.png"

  },
  {
    "id" : 4,
    "title": "Python",
    "link": null,
    "largeImage": "image/toolsImage/tool-lg1-1596020742035.png"

  },
  {
    "id" : 5,
    "title": "Jupyter Notebook",
    "link": null,
    "largeImage": "image/toolsImage/1200px-Jupyter_logo-1707735380727.png"

  },
  {
    "id" : 6,
    "title": "GIT",
    "link": null,
    "largeImage": "image/toolsImage/Git-logo-1707482493040.png"

  },
  {
    "id" : 7,
    "title": "Statsmodel",
    "link": null,
    "largeImage": "image/toolsImage/images-1707482464267.png"

  },
  {
    "id" : 8,
    "title": "Seaborn",
    "link": null,
    "largeImage": "image/toolsImage/Seaborn-1601022428840.png"

  },
  {
    "id" : 9,
    "title": "Pandas",
    "link": null,
    "largeImage": "image/toolsImage/pandas-1601020776154.jpg"

  },
  {
    "id" : 10,
    "title": "NumPy",
    "link": null,
    "largeImage": "image/toolsImage/asset2tools-1595857644274.png"

  },
  {
    "id" : 11,
    "title": "Matplotlib",
    "link": null,
    "largeImage": "image/toolsImage/Matplotlib-1601031916549.png"

  },
]

const toolsRecord = [
  {
    title: 'E&ICT Academy,<br/>IIT Guwahati Certification',
    description:
      'Upon successful completion of the course and stipulated requirements, you will receive a Joint certificate from AnalytixLabs and E&ICT Academy, Indian Institutes of Technology Guwahati.',
  },
  {
    title: 'For Technical &<br/>Non-technical Candidates',
    description:
      'Industry-first approach to maximize the job prospects for beginner-to-mid career candidates from a technical or non-technical background.',
  },
  {
    title: 'Classroom & Online<br/>Blended',
    description:
      'Choose from 100% online or classroom-blended, hands-on learning with extensive student support and mentorship by seasoned industry experts.',
  },
]

const courseDetails = {
  courseFullName: 'PG Course in Data Analytics',
  name: 'Data analytics 360 Course',
  slug: 'pg-in-data-analytics',
  shortDescription: 'Certified Data Analytics Course with Placement Readiness!',
  highHighRegulatory: {
    title: 'High Regulatory Standards &<br/>Accreditation Framework',
    images: [
      {
        imageUrl: logoNasscomMinistry,
        height: '90%',
        width: '90%',
        col: '4',
      },
    ],
  },
  hoursCount: '500',
  classCount: '46',
  classHours: '+ 74 hrs e-learning',
  projectCount: '18',
  ratingValue: 4.73,
  bestRating: 5,
  worstRating: 2,
  ratingCount: 4860,
  metaTitle: 'Data Analyst Certification Courses: Duration, Fees & Placements',
  metaDescription:
    'Explore Best Data Analytics courses in India with placements. Get details on fees, duration, syllabus and PostGrad. Become a certified Data Analyst. Start now!',
  metaKeywords:
    'data science course, data analytics certificate, data analytics certification, data analytics online course, data analytics course in India, data analytics course in Delhi, data analytics training in Bangalore, data analytics course in Noida',
  selfStudyHoursCount: '260',
  classCountSubline: ' (8-10 hrs/ week)',
  hoursPerClassCount: '46 x 3 = 138 hrs',
  CourseLearningModes: {
    amountINR: 38000,
    maxAmountINR: 48000,
  },
  taxPercentage: '18',
  LMSCourse: 'ckepznduu00attqjl6qni9nwp',
  courseID: 9997,
}

const certificateAboveText = {
  longDescription:
    '<p>Our postgraduate courses are designed with industry-relevant updates and are taught by experts passionately involved in the analytics and data science domain.</p><p>PG courses in Data Analytics are available in two modules - A PG Diploma course, a one-year dedicated diploma with Generative AI tools, and a certification program course of a 5-month duration.</p>',
}

const certificatebelowText = {
  longDescription: `<p>Upskill your analytics profile with our Certified Data Analyst Bootcamp course. This course combines the powerful pedagogy of world-class experts and hands-on practical training to fast-track your career graph as a Data Analyst. From beginners' concepts to advanced subjects, our Certified Data Analyst Bootcamp course covers everything you will need to make a mark as an analyst. Based on the track you choose, this course will earn you a job-oriented Certification in Data Analytics.</p>`,
}

const pgdpcta = [
  {
    title: 'Bootcamp Certificate in Data Analytics',
    description: 'Brochure',
    modalType: 'PGCPADownloadBrochure',
  },
  {
    title: 'PG Diploma in Data Analytics',
    description: 'Brochure',
    modalType: 'PGDADownloadBrochure',
  },

]

const pgdVspgcc = {
  title: 'Bootcamp Certificate Course v/s PG in Data Analytics',
  mobileViewTable:
    `
    <div>
    <table style="border-collapse: collapse; border: 1px solid rgb(0, 0, 0); padding: 10px 20px;">
        <tbody>
            <tr>
                <td style="border-right: 1px solid rgb(0, 0, 0); border-bottom: 1px solid rgb(28, 69, 135); padding: 10px 18px; background-color: rgb(28, 69, 135); width: 19.8402%; vertical-align: middle;">
                  <span style="color: rgb(28, 69, 142);"><br></span>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0); padding: 10px 18px; background-color: rgb(28, 69, 135); vertical-align: middle;">
                    <p style="text-align: center; line-height: 1.15; margin:8px 0;">
                      <span style="color: rgb(255, 255, 255);">
                        <strong> Bootcamp Certificate in Data Analytics</strong>
                      </span>
                    </p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0); padding: 10px 18px; background-color: rgb(28, 69, 135); vertical-align: middle;">
                    <p style="text-align: center; line-height: 1.15; margin:8px 0;">
                      <span style="color: rgb(255, 255, 255);">
                        <strong> PG Diploma in Data Analytics </strong>
                      </span>
                    </p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0); padding: 10px 18px; background-color: rgb(28, 69, 135); width: 19.8402%; vertical-align: middle;">
                    <p style="text-align: center; line-height: 1.15; margin:8px 0;">
                        <span style="color: rgb(255, 255, 255);">
                          <strong>Duration</strong>
                        </span>
                    </p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0); padding: 10px 18px; background-color: rgb(207, 226, 243); width: 45%; vertical-align: middle;">
                    <p style="text-align: center; line-height: 1.15; margin:8px 0;">
                        <span style="color: rgb(28, 69, 142);">
                          6 months
                        </span>
                    </p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0); padding: 10px 18px; background-color: rgb(207, 226, 243); vertical-align: middle;">
                    <p style="text-align: center; line-height: 1.15; margin:8px 0;">
                        <span style="color: rgb(28, 69, 142);">
                          10 months
                        </span>
                    </p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0); padding: 10px 18px; width: 19.8402%; background-color: rgb(28, 69, 135); vertical-align: middle;">
                    <p style="text-align: center; line-height: 1.15; margin:8px 0;">
                      <span style="color: rgb(255, 255, 255);">
                        <strong>Learning Hours</strong>
                      </span>
                    </p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0); padding: 10px 18px; width: 45%; background-color: rgb(207, 226, 243); vertical-align: middle;">
                    <p style="text-align: center; line-height: 1.15; margin:8px 0;">
                      <span style="color: rgb(28, 69, 142);">
                        500 hours
                      </span>
                    </p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0); padding: 10px 18px; background-color: rgb(207, 226, 243); vertical-align: middle;">
                    <p style="text-align: center; line-height: 1.15; margin:8px 0;">
                      <span style="color: rgb(28, 69, 142);">
                        1500 hours
                      </span>
                    </p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0); padding: 10px 18px; width: 19.8402%; background-color: rgb(28, 69, 135); vertical-align: middle;">
                    <p style="text-align: center; line-height: 1.15; margin:8px 0;">
                      <span style="color: rgb(255, 255, 255);">
                        <strong>Fees</strong>
                      </span>
                    </p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0); padding: 10px 18px; width: 45%; background-color: rgb(207, 226, 243); vertical-align: middle;">
                    <p style="text-align: center; line-height: 1.15; margin:8px 0;">
                      <span style="color: rgb(28, 69, 142);">
                        INR 80,000 + GST
                      </span>
                    </p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0); padding: 10px 18px; background-color: rgb(207, 226, 243); vertical-align: middle;">
                    <p style="text-align: center; line-height: 1.15; margin:8px 0;">
                      <span style="color: rgb(28, 69, 142);">
                        INR 2,75,000 + GST
                      </span>
                    </p>
                </td>
            </tr>
        </tbody>
    </table>
</div>

    `,
  noteMsg: "Easy monthly payments of ₹6,878/- with No Cost EMI*"
};

const bannerRecord = {
  courseID: 66,
  name: 'Data Science 360 Course',
  bannerType: null,
  robotImage: 'image/course/thumbnail-1616501509.svg',
  courseDetailsImage: 'image/course/thumbnail-1616501509-1660822396508.svg',
  introURL: '',
  bannerFileName: 'image/course/bg-banner-1644823150421.png',
  hoursCount: '500',
  classCount: '46',
}

const courseOverview = {
  title: 'Course Overview:',
  longDescription: '<p></p>',
}

const courseInfo = {
  title: 'DATA ANALYTICS COURSE',
  longDescription:
    '<p>Data Analytics certification course will teach you the art of analyzing raw data and driving conclusions to enable business performance, making predictions, and making strategic decisions.</p><p>Data Analytics and data analysis may sound similar but have very fine differences. In this Data Analyst course, you will learn such intricate concept differentiations.</p><p>Data Analysis is part of data analytics but solely deals with extracting the meaning of data. On the other hand, Data Analytics is a holistic concept that goes beyond analysis it includes data science to theorize and predict and data engineering to build data systems.&nbsp;</p><p>This data analyst course is designed to equip you with all the broader concepts and subsets of data analytics, how it is used, what skills you must master, and how to be job-ready.</p><p>Want to explore more options in Classroom Learning? We also offer Bootcamp and Classroom <strong><a class="ct-lg-linkUpdate-italic" target="_blank" href="https://www.analytixlabs.co.in/data-analyst-training-course-in-gurgaon">Data Analytics Courses in Gurgaon</a>, <a class="ct-lg-linkUpdate-italic" target="_blank" href="https://www.analytixlabs.co.in/data-analytics-training-course-in-bangalore">Bangalore</a></strong>,<span class="ct-lg-linkUpdate-italic"> and</span> <Strong><a class="ct-lg-linkUpdate-italic" target="_blank" href="https://www.analytixlabs.co.in/data-analyst-training-course-in-noida">Noida</a></Strong>. Choose your nearest location and boost up your job prospects with this extensive program offering prestigious certification from the E&ICT Academy, IIT Guwahati, and AnalytixLabs.</p>',
}

const courseAllDetails = [
  {
    title: 'Learning Objectives',
    longDescription:
      '<p>The data analytics course is designed to help you start a career in data analytics. If you are an experienced professional looking to brush up your skills, this course will be a perfect fit for you.</p><p>For those looking for an integrated option to earn both certification and diploma credentials - you can opt for our post-graduate machine learning course that covers more advanced analytics in addition to this course. Together, you can earn a diploma certificate from AnalytixLabs.</p><p>You can start with our certified data analytics course and then opt for more advanced courses based on your interest.</p>',
  },
  {
    title: 'Why Data Analytics?',
    longDescription:
      '<p>Data analytics enables businesses to derive valuable information from huge data sets and make impactful business decisions.</p><p>It involves exploring and analyzing humungous data sets and identifying patterns or unseen trends. Data analytics helps in drawing correlations and deriving insights for business predictions. A skilled data analyst will know how to use data analytics concepts to fastrack business decision-making and improve overall business efficiency.</p>',
  },
  {
    title: 'How to use data analytics?',
    longDescription:
      '<p>Our data analytics certification course will teach you four essential methods to use data analytics concepts in the real world.',
    subDetails: [
      {
        title: 'Smart decision making',
        description:
          '<p>You will learn to put data at the center of business decision-making. Data analytics will help you understand your target customers better - helping you to derive better results based on customer behavior. From understanding buyers’ intent to monitoring buyers’ preferences, data analytics will help you make more informed customer-oriented decisions.</p>',
      },
      {
        title: 'Reduce and manage business risks',
        description:
          '<p>Data analytics will help you to combat business risks with effective strategies. From issues with loan default or credit card fraud - data analytics can help identify the core risk areas and the underlying causes. Simultaneously, you will learn about the types of Risk (including credit, corporate, market, and operations risk), Credit Risk, data requirements, risk reporting, various types of credit risk models, etc.</p>',
      },
      {
        title: 'Enhance business security',
        description:
          '<p>Businesses rely on data analytics to examine past security breaches and identify the vulnerable areas that caused the breach. This helps security experts to ramp up security measures and prevent such breaches in the future. In this data analysis course, you will learn how Analytical models can identify any abnormal behavior or pattern that can send instant alerts to security experts.</p>',
      },
      {
        title: 'Predict trends',
        description:
          '<p>The market changes in a snap, and businesses have to adapt to these changes really quickly. It is always helpful to understand where the market is heading, what kind of user behavior changes a business can expect, and, accordingly, be ready for it. You will learn how data analytics can help understand the evolving market and identify upcoming trends impacting the business.</p><p>Our data analytics course will equip you with all this know-how and comprehensive training on using data analytics tools.</p>',
      },
    ],
  },
  {
    title: 'Who should do this course?',
    longDescription:
      '<p>Our Advanced Certification in Data Analytics program is designed for absolute beginners with no prior programming background. The course curriculum is in collaboration with Electronics & ICT Academy, IIT-Guwahati. With our comprehensive Data Science, Analytics and AI courses, you learn to analyze data and enable organizations in making data-driven decisions.</p>',
  },
  {
    title: 'Data Analyst Course Fees',
    longDescription:
      '<p>Compared to any PostGrad Programs of particular repute, our programs are unbelievably priced to make them highly affordable. The fee for PostGrad Diploma is only 2,10,000 + Taxes, and the Certified Data Analyst Bootcamp is just ₹ 80,000 + GST/-</p>'
  },
  {
    title: 'Data Analytics Course Duration',
    longDescription: `<p>We understand how challenging it is to manage time in today's fast-paced world; hence we try to provide maximum convenience with flexible learning modes, i.e., Bootcamp and Blended eLearning. The duration for the Certified Data Analyst bootcamp program is around 6 months, and for PostGrad Diploma, it is around 10 months.</p>`,
  },
]

const jobMarket = {
  title: '',
  subList: [
    {
      title: 'Job Responsibilities',
      description:
        '<p>As a data analyst, your job role will include responsibilities like: </p>',
      longDescription:
        '<ul style="margin-left: 0in;"> <li>Collect data from various sources, and arrange data sequentially.&nbsp;</li><li>Clean and process data. You must know to treat a missing value, clean invalid or wrong information, spot errors, and remove unwanted information.&nbsp;</li><li>Know-how of different statistical tools like Python or SaS to interpret data.&nbsp;</li><li>Manage and optimize data according to present and upcoming market or seasonal trends and make accurate prediction models.&nbsp;</li><li>Prepare detailed reports that are easy to understand and visually enriched.&nbsp;</li><li>Identify security breaches, potential threats and enable organizations to combat them.&nbsp;</li></ul>',
    },
    {
      title: 'Job scope',
      description:
        '<p>Demand for data analytics services and training in data analysis is soaring high. As per reports, there will be about 250,000 openings for data analysts in 2023 alone. IBM recently predicted that data science and analytics jobs would cross 350,000 by 2025.</p><p>There are ample scopes for certified data analysts. Learn and acquire the technical and soft skills to start a lucrative career in data analytics.</p>',
    },
    {
      title: 'Salary',
      description:
        '<p>On average, the salary for a data analyst in India ranges from INR 3L to 14L, depending on your  experience and industry.</p>',
    },
  ],
}

const SyllabusOutLineData = {
  title: 'Course Curriculum',
  termsHeader: {
    name: 'Certificate in Data Analytics for Business',
    titles: [
      {title: 'Class Hours', classAddOn: 'col-lg-3'},
      {title: 'Assessment', classAddOn: 'col-lg-3'},
      {title: 'Self Study', classAddOn: 'col-lg-3'},
      {title: 'Term-Hours', classAddOn: 'col-lg-3'},
    ],
    totalHours: 'Total Hours',
  },
  terms: [
    {
      totalHours: 370,
      name: 'Term 1',
      classHourse: 95,
      assessment: 20,
      slefStudy: 255,
      termHours: 370,
      CourseCurriculumDetails: [
        {
          title: 'Orientation - Setting up for success',
          classHourse: 4,
          assessment: 0,
          slefStudy: 0,
          termHours: 4,
          totalHours: 4,
        },
        {
          title: 'Problem Solving - A structured approach to problem solving',
          classHourse: 6,
          assessment: 0,
          slefStudy: 10,
          termHours: 16,
          totalHours: 16,
        },
        {
          title:
            'Building Blocks - Foundations of Mathematics & Statistics, Fundamentals of Programming (Blended eLearning)',
          classHourse: 25,
          assessment: 0,
          slefStudy: 25,
          termHours: 50,
          totalHours: 50,
        },
        {
          title:
            'Business Intelligence, Analytics & Data Visualization (using EXCEL & POWER BI)',
          classHourse: 30,
          assessment: 10,
          slefStudy: 110,
          termHours: 150,
          totalHours: 150,
        },
        {
          title:
            'Exploratory Data Analysis & Data Management (RDBMS - ETL - SQL for Data Science - SQL on Cloud)',
          classHourse: 30,
          assessment: 10,
          slefStudy: 110,
          termHours: 150,
          totalHours: 150,
        },
      ],
    },
    {
      totalHours: 408,
      name: 'Term 2',
      classHourse: 216,
      assessment: 12,
      slefStudy: 180,
      termHours: 300,
      CourseCurriculumDetails: [
        {
          title:
            'Python for Analytics - Statistical Analysis and Modelling using Python :',
          classHourse: 54,
          assessment: 6,
          slefStudy: 90,
          termHours: 150,
          totalHours: 150,
          subCurriculumDetalis: [
            {
              title: 'Python Foundation (Core Python) - Python Packages',
              classHourse: 0,
              assessment: 0,
              slefStudy: 20,
              termHours: 20,
              totalHours: 20,
            },
            {
              title: 'Exploratory Data Analysis - Data Visualizaiton',
              classHourse: 0,
              assessment: 0,
              slefStudy: 30,
              termHours: 30,
              totalHours: 30,
            },
            {
              title:
                'Statistical Analysis (Basic Statistics - Statistical Methods)',
              classHourse: 0,
              assessment: 0,
              slefStudy: 20,
              termHours: 20,
              totalHours: 20,
            },
            {
              title: 'Predictive Modeling using Linear & Logistic Regression',
              classHourse: 0,
              assessment: 0,
              slefStudy: 20,
              termHours: 20,
              totalHours: 20,
            },
          ],
        },
        {
          title:
            'Data Analytics Applications - Industries & Functions (24 hrs eLearning)',
          classHourse: 30,
          assessment: 10,
          slefStudy: 110,
          termHours: 150,
          totalHours: 150,
          subCurriculumDetalis: [
            {
              title: 'Marketing Analytics',
              classHourse: 6,
              assessment: 0,
              slefStudy: 20,
              termHours: 0,
              totalHours: 26,
            },
            {
              title: 'Operations Analytics',
              classHourse: 6,
              assessment: 0,
              slefStudy: 20,
              termHours: 0,
              totalHours: 26,
            },
            {
              title: 'Risk Analytics',
              classHourse: 6,
              assessment: 0,
              slefStudy: 20,
              termHours: 0,
              totalHours: 26,
            },
            {
              title:
                'Industry Application of Analytics in Retail, eCommerce, Banking, Insurance, Telecom, etc.',
              classHourse: 12,
              assessment: 0,
              slefStudy: 50,
              termHours: 0,
              totalHours: 62,
            },
            {
              title: 'Practice Bootcamps (Weekday sessions)',
              classHourse: 54,
              assessment: 0,
              slefStudy: 0,
              termHours: 0,
              totalHours: 54,
            },
          ],
        },
      ],
    },
    {
      totalHours: 450,
      name: 'Term 3',
      classHourse: 29,
      assessment: 30,
      slefStudy: 115,
      termHours: 174,
      CourseCurriculumDetails: [
        {
          title:
            'Data Analytics in Practice - Industry Capstone Project work - Final Viva (12 hrs eLearning)',
          classHourse: 20,
          assessment: 30,
          slefStudy: 100,
          termHours: 150,
          totalHours: 60,
          subCurriculumDetalis: [
            {
              title: 'Data Analytics Project - End-to-End Pipeline',
              classHourse: 0,
              assessment: 0,
              slefStudy: 0,
              termHours: 0,
              totalHours: 0,
            },
            {
              title: 'Business Requirement Gathering - Problem-Solving',
              classHourse: 0,
              assessment: 0,
              slefStudy: 0,
              termHours: 0,
              totalHours: 0,
            },
            {
              title: 'Documentation - best practices',
              classHourse: 0,
              assessment: 0,
              slefStudy: 0,
              termHours: 0,
              totalHours: 0,
            },
            {
              title: 'Analytics Project Management',
              classHourse: 0,
              assessment: 0,
              slefStudy: 0,
              termHours: 0,
              totalHours: 0,
            },
          ],
        },
        {
          title:
            'Placement Preparation - Interview Preparation - Mock Interviews',
          classHourse: 10,
          assessment: 0,
          slefStudy: 20,
          termHours: 30,
          totalHours: 48,
          subCurriculumDetalis: [
            {
              title: 'Placement Readiness Programme',
              classHourse: 0,
              assessment: 0,
              slefStudy: 0,
              termHours: 0,
              totalHours: 0,
            },
            {
              title: 'Success Accelerator',
              classHourse: 0,
              assessment: 0,
              slefStudy: 0,
              termHours: 0,
              totalHours: 0,
            },
            {
              title:
                'Career Assistance (Resume, Mock Sessions, Interview prep, etc.)',
              classHourse: 0,
              assessment: 0,
              slefStudy: 0,
              termHours: 0,
              totalHours: 0,
            },
          ],
        },
      ],
    },
  ],
  termsTotalBottom: {
    name: 'Total Hours',
    titles: [
      {title: '263', classAddOn: 'col-lg-3'},
      {title: '66', classAddOn: 'col-lg-3'},
      {title: '575', classAddOn: 'col-lg-3'},
      {title: '850', classAddOn: 'col-lg-3'},
      // {title: '30', classAddOn: 'col-lg-2'},
    ],
    totalHours: 1772,
  },
}

const termsInfo = {
  title: 'Course progression roadmap',
  termsDetails: [
    {
      title: 'Term 1: Introduction to the basics',
      description:
        '<p>In the onboarding or introductory session, you will get acquainted with your mentors, learning modules, and how the course will progress. Throughout this term, you will cover all the basic concepts like -  </p>',
      subPoints: [
        {
          title: 'How to approach a business problem and solve it.',
        },
        {
          title: 'Basics of mathematics, statistics, and programming',
        },
        {
          title: 'Learn data visualization using tools like Excel and PowerBI',
        },
        {
          title: 'Comprehensive sessions on SQL data analysis and management',
        },
      ],
      notes: 'Sessions: 4 days/week',
    },
    {
      title: 'Term 2:',
      description:
        '<p>The second term will introduce you to Python for data analysis and analytical applications across industries and functions. You will learn to use Python for basic statistical analysis, drive marketing analytics, reduce risks, and increase efficiency across multiple industries like eCommerce and retail.</p>',
      subPoints: [
        {
          title:
            'Python for Data Analysis - Statistical Analysis and Modelling using Python',
        },
        {
          title:
            'Data analytics application - Industries & Function [24-hour e-learning]',
        },
      ],
      courseSubPointDetails: [
        {
          title:
            'PYTHON FOR DATA ANALYSIS: STATISTICAL ANALYSIS AND MODELING USING PYTHON',
          topicDetails: [
            {
              title: 'Python Foundation (Core Python) - Python Packages',
              description:
                '<p>Python is a free and open-source language that is easy to learn. It is essential for data analysis and does not require any advanced programming knowledge. Core Python is a branch of Python that includes basic commands and data structure.</p><p>You will learn about Python packages, operators in Python, the basics of object-oriented programming, user-defined functions, data types in Python, and so much more</p>',
            },
            {
              title: 'Exploratory Data Analysis [EDA] - Data Visualization',
              description:
                '<p>You will learn to understand data better, which aids in further data processing. Following this, you will also understand how visualization can make the exploratory data analysis process through interactive plots and charts. Exploratory data analysis [EDA] helps data analysts to dig deeper into data sets and underlying data structures.</p>',
            },
            {
              title:
                'Statistical Analysis (Basic Statistics - Statistical Methods)',
              description:
                '<p>Statistical analysis consists of two methodologies 0 descriptive and inferential statistics. This module will teach you how to summarize data using indexes like mean and median (descriptive statistics) and draw conclusions from data via statistical tests like t-tests (inferential statistics). These are two primary methods. You will also learn other types of statistical analysis, like associational statistical analysis, predictive analysis, prescriptive analysis, causal analysis, and more.</p>',
            },
            {
              title: 'Predictive Modeling using Linear & Logistic Regression',
              description:
                '<p>The last module of this term will teach you to predict future behavioir using statistical methods. Predictive modeling works by analyzing historical and present data and generating a model to predict future outcomes. This module covers two types of algorithms - linear regression and logistic regression. Linear regression provides continuous outputs and handles regression problems. Logistic regression gives discreet outputs and can handle classification problems. You will learn everything about these algorithms and model generation using Python.</p>',
            },
          ],
        },
        {
          title: 'DATA ANALYTICS APPLICATIONS - INDUSTRIES & FUNCTIONS',
          topicDetails: [
            {
              title: 'Marketing Analytics',
              description:
                '<p>Data analytics can help businesses understand customer actions, their reason, and how to optimize the overall ROI for any marketing activity. You will learn the techniques and methodologies to analyze marketing data, spot trends and patterns, and take an analytical approach to understand user behavior, refine marketing campaigns, and ensure customers return to your brand.</p>',
            },
            {
              title: 'Operational analytics',
              description:
                '<p>Operational analytics will teach you to improve the operational nature of a business. It is part of business analytics that focuses on syncing data from the warehouse into your business tools so that everyone in your organization can access the same kind of data, irrespective of their technical know-how. You learn to focus on monitoring current and real-time operations that can boost productivity and organize daily operations for a business.</p>',
            },
            {
              title: 'Risk analytics',
              description:
                '<p>Risk analytics will enable you to quantify and predict risks with maximum accuracy. You will learn to use various technologies to dig deeper into business data, formulate likely risk-driven scenarios, and accurately predict future risks. Data analytics help businesses avoid large-scale debacles via proper identification, analysis, and risk evaluation.</p>',
            },
            {
              title:
                'Industry Application of Analytics in Retail, eCommerce, Banking, Insurance, Telecom, etc.',
              description:
                '<p>As the name suggests, you will have real-time learning of how data analytics is implemented across various business domains, industries, and functions. This will give you hands-on learning on what kind of business problems arise, how to approach them, and what models you can create to mitigate large-scale risks - all-thewhile learning to modify your knowledge based on the industry or function you are working in.</p>',
            },
            {
              title: 'Practice Bootcamps',
              description:
                '<p>This is where you get ample scope to put your learning into practice under the expert guidance of your mentors.</p><p>Sessions: 4 days/week [2-weekend sessions + 2-weekday practice sessions]</p>',
            },
          ],
        },
      ],
    },
    {
      title: 'Term 3:',
      description:
        '<p>The last term of this course comprises practice sessions, project work, final assessment, and placement preparations bringing the entire data analyst training session to a full wrap.</p><p>Data Analytics in Practice - Industry Capstone Project work - Final Viva (12 hrs eLearning)&nbsp;<br>Placement Preparation - Interview Preparation - Mock Interviews</p>',
      courseSubPointDetails: [
        {
          title:
            'DATA ANALYTICS IN PRACTICE - INDUSTRY CAPSTONE PROJECT + FINAL VIVA',
          topicDetails: [
            {
              title: 'Data Analytics Project - End-to-End Pipeline',
            },
            {
              title: 'Business Requirement Gathering - Problem-Solving',
            },
            {
              title: 'Documentation - best practices',
            },
            {
              title: 'Business Requirement Gathering - Problem-Solving',
            },
          ],
        },
        {
          title:
            'PLACEMENT PREPARATIONS: INTERVIEW PREPARATIONS + MOCK INTERVIEWS',
          topicDetails: [
            {
              title: 'Placement Readiness Programme',
              description:
                '<p>This program will guide you to get ready for placement. Your mentor will guide you to complete all your assignments successfully and decide the career graph you want.</p>',
            },
            {
              title: 'Success Accelerator',
              description:
                '<p>The next step in this program is to help you understand the role you want to take up and what will help you quickly develop interview skills.</p>',
            },
            {
              title:
                'Career Assistance (Resume, Mock Sessions, Interview prep, etc.)',
              description:
                '<p>Last, you get unhindered assistance in designing your resume, engaging in mock interviews, and consulting with your mentors to prepare for your interviews, crack them, and start your career as a data analyst.</p>',
            },
          ],
        },
      ],
    },
  ],
}

const skillsAndTools = {
  title: 'INDUSTRIES, SKILLS AND TOOLS COVERED',
  description:
    '<p>The data analytics for business certification course covers the following tools and library packages -</p>',
  descWithBg: [
    {
      label:
        'Excel, PowerBI, SQL Server on Cloud, Python, Jupyter Notebook, GIT,',
    },
    {
      label:
        'MS PowerPoint, Statsmodel, Seaborn, Pandas, NumPy, Matpolib, R, DAX',
    },
  ],
  longDescription:
    '<p>Add to this; you acquire soft skills like business acumen, business analysis, and translating data findings into layman&rsquo;s terms for everyone to understand your predictions.</p><br/><p>The two most important types of skills that you will learn in this course are -</p>',
}

const decisionMakingSkills = {
  title: 'DECISION-MAKING SKILLS',
  description:
    '<p>This is one of the core soft skills covered in all data analytics certification courses. You cannot make a mark as a data analyst without decision-making skills. You must know to represent your findings and outcomes to various executives.</p><br/><p>With a deeper analysis, you will be able to make logical and factual decisions that will be beneficial for your organization. As a data analyst, you must have a 360-degree view of a situation and strategically approach it before suggesting an optimum solution.</p>',
}

const industriesCovered = {
  title: 'INDUSTRIES COVERED:',
  description:
    '<p>The Data Analytics Certification and PGDA course cover the following industries as part of understanding real-time data analytics applications.</p><p>1. Retail/eCommerce</p><p>2. BFSI (Finance and Insurance)</p><p>3. Healthcare</p><p>4. Telecom</p><p>5. Hospitality</p><p> 6. Manufacturing</p>',
}

const technicalSkills = {
  title: 'TECHNICAL SKILLS',
  description:
    '<p>This course will cover several important technical languages and tools-: </p>',
  subList: [
    {
      title: 'Core Python',
      longDescription:
        '<p>It is an open-source and free programming language for data analysis processes.</p>',
    },
    {
      title: 'Microsoft Excel',
      longDescription:
        '<p>One of the most used tools for data organization, management, and representation is Microsoft Excel. Excel is an extremely powerful tool with built-in pivot tables for data visualization. You can easily import, explore, clean, analyze, and visualize large data sets.</p>',
    },
    {
      title: 'Power BI',
      longDescription: `<p>It is a cloud-based analytical tool that gives a quick insight into data and helps in data extraction and visualization. It combines multiple sources to view an organization's assets comprehensively.</p>`,
    },
    {
      title: 'JIRA',
      longDescription: `<p>Jira is an important tool in business analytics. You can use Jira to evaluate team performance. Jira's visualization aspect helps make detailed and visually enriched data reports. Jira comes most handy in operational analytics. Add to this, Jira can also serve as a database.</p>`,
    },
    {
      title: 'Microsoft Powerpoint',
      longDescription:
        '<p>Microsoft Powerpoint helps in data visualization, especially when framing your data insights for non-technical team members and stakeholders. You use POwerpoint just to represent the data you are talking about. Microsoft Powerpoint and the other tools in the MS Office suite serve as an analytical suite.</p>',
    },
    {
      title: 'Azure [Cloud computing]',
      longDescription:
        '<p>Azure analysis services offers cloud-based enterprise-grade data models. You can use the advanced mashup and modeling features to assemble data from multiple sources, refine them, define the metrics, and secure your data in a tabular semantic data model.</p>',
    },
    {
      title: 'Tableau (Optional)',
      longDescription:
        '<p>An end-to-end data analytics platform designed to prepare data for analysis. You can collaborate and share your data insights with this tool. The visual analysis in Tableau is highly powerful and is easily shared across organizations.</p>',
    },
  ],
}

const faqs = {
  faqTitle: 'FAQs',
  faqDescription: null,
  faqsData: [
    {
      FaqID: 1,
      title: 'What is a data analytics course?',
      description:
        '<p>Courses for data analyst are designed to help analytics aspirants and professionals to learn the tools and techniques to analyze data, predict outcomes, and visually represent them.</p><p>There are four main components to study:&nbsp;</p><p>1. Understanding Excel and PowerBI</p><p>2. Exploratory data analysis and data management</p><p>3. Python for data analytics and data visualization</p><p>4. Data analytics applications - industries and functions</p><p>These four major components will help you analyze data better and create accurate data prediction models to aid business decision-making.</p>',
    },
    {
      FaqID: 2,
      title: 'Which data analytics course is the best?',
      description:
        '<p>One of the best data analytics courses is our Data Analytics certification course. We are touted as one of the premium institutes in India that offers courses in data science, AI, ML, and Data analytics for business.</p><p>Our course curriculum is designed in collaboration with global partners is updated according to the present market scenario.</p><p>For additional learning, our team of experts will continue to share free and easy-to-access resources and reading materials for easy upskilling. Meanwhile, you can read our blogs on Data Analytics and related topics.</p>',
    },
    {
      FaqID: 3,
      title: 'Can I become a data analyst in 3 months?',
      description: `<p>The ideal data analytics certification course duration is 6 months. If you can finish all self-learning materials in less time, you can fast-track your learning. Ideally, 3 months will be a tough timelines for most candidates.</p><p>The PG Diploma course is 12 months. Since it covers advanced topics, you will have to complete the course in a year&rsquo;s time. There is no way you can shorten the time span.</p>`,
    },
    {
      FaqID: 4,
      title: 'Is data analytics course worth it?',
      description:
        '<p>Yes. Training in data analysis and data visualization can help you build a lucrative career in multiple job roles. You can become a business analyst, a data engineer, cyber security lead, and more. Your knowledge in data analytics can find you a role in various industries like e-commerce, aviation, finance, medical, agriculture, genomics, cyber security, and more.</p>',
    },
    {
      FaqID: 5,
      title: 'Is the PGDA course equivalent to MBA?',
      description:
        '<p>If you upgrade to our PG course after completing our certification course, your final degree is equivalent to a master&apos;s degree. However, MBA is different from data analytics.</p><p>While both are masters, the respective subjects are different. MBA covers the administrative side of a business, while data analytics is more at the core of business decision-making based on customer/client preferences and intents.</p>',
    },
    {
      FaqID: 6,
      title: 'Is data analytics a good career option?',
      description:
        '<p>Yes. Skilled data analysts are core to any business. Statistics show businesses are more driven by the need to create a holistic buyer/client experience. They need data analysts who can read between the data and help predict buyer behaviors, market trends, and business outcomes.</p>',
    },
    {
      FaqID: 7,
      title: 'Who is eligible for the data analytics course?',
      description:
        '<p>The certification course is for anyone looking to start a career in analytics. A background in science, mathematics, or computer science can help but is not mandatory. Our course curriculum is designed to help novices get on board quickly.</p><p>The Post Graduation course applies to students who have completed the certification or equivalent degree course. It is more advanced and requires basic knowledge of the core data analytics concepts.</p>',
    },
    {
      FaqID: 8,
      title:
        'What is the difference between Business Analytics and Data Analytics?',
      description:
        '<p>Data Analytics involves analyzing data to draw conclusions and address certain issues. While Business Analytics is focused on using data to optimize corporate performance and decision-making.</p><p>Here, at AnalytixLabs, we have both the <span ><a target="_blank" href="https://www.analytixlabs.co.in/business-analytics-data-science-course" rel="noopener noreferrer"><span style="color: rgb(41, 105, 176);">Business Analytics 360</span></a></span> course and the Data Analytics PG course to help you learn from scratch and excel in the field.</p>',
    },
    {
      FaqID: 9,
      title:
        'Does AnalytixLabs have dedicated classroom training sessions? How do I locate the nearest classroom?',
      description:
        '<p>Yes, AnalytixLabs also offers dedicated classroom learning sessions for those who want to experience in-person learning. We understand the importance of offline learning, and all our modules are designed to give you the best learning modes—online or offline. Our dedicated classroom learning offers traditional in-house learning clubbed with a modern approach to smart and interactive classes. We have learning centers for our <strong><span class="ct-lg-linkUpdate-italic">Data Analytics Courses in </span><a class="ct-lg-linkUpdate-italic" target="_blank" href="https://www.analytixlabs.co.in/data-analyst-training-course-in-gurgaon"> Gurgaon</a>, <a class="ct-lg-linkUpdate-italic" target="_blank" href="https://www.analytixlabs.co.in/data-analytics-training-course-in-bangalore">Bangalore</a>, <a class="ct-lg-linkUpdate-italic" target="_blank" href="https://www.analytixlabs.co.in/data-analyst-training-course-in-delhi">Delhi</a></strong>,<span class="ct-lg-linkUpdate-italic"> and </sapn><Strong><a class="ct-lg-linkUpdate-italic" target="_blank" href="https://www.analytixlabs.co.in/data-analyst-training-course-in-noida">Noida</a></Strong>.</p>',
    },
  ],
}

const careerGraph = {
  title: 'CAREER GRAPH [IN INR]',
  careerList: [
    {
      imageUrl: daFresher,
      upperContent: 'Data analyst [fresher]',
      lowerContent: '5.7L/Year',
    },
    {
      imageUrl: seniorDA,
      upperContent: 'Senior data analyst',
      lowerContent: '19L/Year',
    },
    {
      imageUrl: daIV,
      upperContent: 'DATA ANALYST IV',
      lowerContent: '14L/YEAR',
    },
  ],
}

const pgDiplomaAnalyticsCard = {
  courseIncludedRecord: [
    {
      name: 'Analytics with Excel, SQL & PowerBI',
    },
    {
      name: 'Data Science using Python',
    },
    {
      name: 'Stats & Predictive Modelling',
    },
    {
      name: 'Industry & Applications',
    },
    {
      name: 'Generative AI',
    },
  ],
  name: 'Bootcamp in Data Analytics',
  slug: 'pg-in-data-analytics',
  hoursCount: '500+',
  classCount: '46',
  robotImage: powerBISmallIcon,
  totalAmount: 50000,
  plusTaxes:'',
  isStatic: true,
  yearCount: '6',
}
const PlacementAndSupportPGAnalytics = [
  {
    isShowRequestACallbackOnlyCTA: true,
    title: `Career Support`,
    longDescription: `<p>AnalytixLabs is committed to helping you launch your career successfully. Our default placement guidance program focuses on developing job-relevant skills. You'll receive expert guidance on resume building, interview preparation and how to apply for relevant job roles. For those seeking an even greater advantage, our optional Placement Readiness Program (PRP) offers bouquet of benefits:</p>`,
    bulletPoint:[
      {
        title : `No Upfront Fees`,
        description: `<p>We believe in shared success. That's why we offer a unique pay-after-placement model which comes with zero upfront costs. Instead, you'll only invest 10% of your first-year CTC that too if you secure a job with a minimum salary of INR 7 Lakhs per annum within six months of completing PRP.</p>`,
      },
      {
        title : `Placement Readiness Program`,
        description: `<p>An optional 2-month, industry-focused module designed to enhance technical and soft skills. It includes coaching, key topic recaps for interviews, practice tests, case studies, and simulated recruitment drives. The program also features mock interviews with an industry panel, providing one-to-one feedback and guidance.</p>`,
      },
      {
        title : `Flexible Opt-in`,
        description: `<p>After certification you can take upto 30 days to decide and opt in for the Placement Readiness Program. This option is ideal for those who are looking for upskilling or a coveted industry-recognized certificate, feel confident in their acquired skills, and wish to pursue job applications independently. These students will still be eligible for basic placement guidance.</p>`,
      },
      {
        title : `Continuous Support`,
        description: `<p>After you successfully complete PRP, we will continue offering ongoing support as long as you need it. Many of our students benefit from numerous interview calls and rewarding career prospects attributed to the skills acquired during the course.</p>`,
      },
    ],

  },
]

export {
  toolsList,
  toolsRecord,
  courseDetails,
  bannerRecord,
  certificateAboveText,
  courseOverview,
  courseAllDetails,
  courseInfo,
  termsInfo,
  technicalSkills,
  faqs,
  pgDiplomaAnalyticsCard,
  pgdpcta,
  pgdVspgcc,
  SyllabusOutLineData,
  skillsAndTools,
  decisionMakingSkills,
  jobMarket,
  careerGraph,
  certificatebelowText,
  industriesCovered,
  PlacementAndSupportPGAnalytics
}
