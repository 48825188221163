import React, { memo, useEffect } from 'react'
import './App.css'
import { ModalTypeProvider } from '../context/modalTypeContext'
import SmallHeader from 'components/SmallHeader'
// import { Nitrate } from "@n7-io/nitrate-react";
import { handleGA } from '../shared/settings'
import BottomFooterPPC from 'components/BottomFooterPPC'
import {
  checkExtraUrl,
  courseLgPagesSlug,
  courseSlugOfLgPage,
  getSlugAfterFromUrlByIndex,
  getSlugFromUrlByIndex,
  lgPageConst,
  lgPages,
  pathNameUrl,
  selectedLgPages,
} from '../constants'
import { useRouteMatch } from 'react-router-dom'
const ContactUsBtSection = React.lazy(() =>
  import('components/ContactUsBtSection/ContactUsBtSection'),
)
const Footer = React.lazy(() => import('components/Footer/Footer'))
const BottomFooter = React.lazy(() => import('components/BottomFooter'))
const WAChat = React.lazy(() => import('components/WAChat'))
const AvailOfferModal = React.lazy(() =>
  import('components/CommonModals/AvailOfferModal'),
)
const ContactUsModal = React.lazy(() =>
  import('components/CommonModals/ContactUsModal'),
)
const FreeDemoModal = React.lazy(() =>
  import('components/CommonModals/FreeDemoModal'),
)
const DownloadBrochureModal = React.lazy(() =>
  import('components/CommonModals/DownloadBrochureModal'),
)
const ContactUsModalLG = React.lazy(() =>
  import('components/CommonModals/ContactUsModalLG'),
)
const UpArrorScroll = React.lazy(() => import('components/UpArrorScroll'))

const MainLayout = memo(({ children }) => {
  useEffect(() => {
    handleGA()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname])
  const route = useRouteMatch()
  function checkLandingPage() {
    if (
      (courseSlugOfLgPage.includes(getSlugFromUrlByIndex(3, true)) && courseLgPagesSlug.includes(getSlugFromUrlByIndex(4, true)))  ||
      lgPages.includes(getSlugFromUrlByIndex(2)) ||
      selectedLgPages.includes(getSlugFromUrlByIndex(2)) ||
      (checkExtraUrl && lgPages.includes(getSlugAfterFromUrlByIndex(2))) ||
      selectedLgPages.includes(getSlugAfterFromUrlByIndex(2))
    ) {
      return true
    }
    return false
  }
  return (
    <ModalTypeProvider>
      {!checkLandingPage() && <SmallHeader />}
      <div className="App">{children}</div>
      {/* <Nitrate> */}
      {pathNameUrl.includes(window.location.pathname) ? (
        <ContactUsBtSection nitrateRef="globalExtras" />
      ) : (
        <div />
      )}
      {checkLandingPage() ? (
        !lgPageConst.lgPageSlugList.includes(route.path) &&
        <BottomFooterPPC nitrateRef="globalExtras" />
      ) : (
        <>
          <Footer nitrateRef="globalExtras" />
          <BottomFooter nitrateRef="globalExtras" />
        </>
      )}
      {window?.innerWidth && window.innerWidth > 767 && (
        <WAChat nitrateRef="globalExtras" />
      )}
      <AvailOfferModal nitrateRef="globalExtras" />
      <ContactUsModal nitrateRef="globalExtras" />
      <ContactUsModalLG nitrateRef="globalExtras"/>
      <FreeDemoModal nitrateRef="globalExtras" />
      <DownloadBrochureModal nitrateRef="globalExtras" />
      <UpArrorScroll nitrateRef="globalExtras" />
      {/* </Nitrate> */}
    </ModalTypeProvider>
  )
})
export default MainLayout
