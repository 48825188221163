/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useEffect } from 'react'
// import mobOffers from '../../assets/images/svgs/mob-offers.png'
import download from '../../assets/images/footer/download-1.png'
import graduationCap from '../../assets/images/menu/graduation-cap.png'
import { useModalTypeContext } from '../../context/modalTypeContext'
// import { nitrateStore } from '@n7-io/nitrate-react'
import './header.css'
import {
  checkExtraUrl,
  getSlugAfterFromUrlByIndex,
  getSlugFromUrlByIndex,
  lgPages,
  selectedPagesForDSSCBangalore,
  selectedPagesForDSSCNcr,
} from '../../constants'

function SmallHeader() {
  const { setModalType } = useModalTypeContext()

  useEffect(() => {
    // const exploreFooterHeaderRecord = nitrateStore('exploreFooterHeaderRecord')
    // if (exploreFooterHeaderRecord) {
    //   const transformedData = {
    //     headerRecord: {
    //       announcement: exploreFooterHeaderRecord.headerRecord.announcement,
    //     },
    //     footerRecord: {
    //       branches: exploreFooterHeaderRecord.footerRecord.branches,
    //       callUs: exploreFooterHeaderRecord.footerRecord.call_us,
    //       weAreSocial: exploreFooterHeaderRecord.footerRecord.we_are_social,
    //       support: exploreFooterHeaderRecord.footerRecord.support,
    //     },
    //   }
    //   setGeneralState('headerRecord', transformedData.headerRecord)
    // } else

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  function getMobileHeader() {
    if (
      lgPages.includes(getSlugFromUrlByIndex(2)) ||
      selectedPagesForDSSCNcr.includes(getSlugFromUrlByIndex(2)) ||
      selectedPagesForDSSCBangalore.includes(getSlugFromUrlByIndex(2)) ||
      (checkExtraUrl &&
        (lgPages.includes(getSlugAfterFromUrlByIndex(2)) ||
          selectedPagesForDSSCNcr.includes(getSlugAfterFromUrlByIndex(2)) ||
          selectedPagesForDSSCNcr.includes(getSlugAfterFromUrlByIndex(2))))
    ) {
      return null
    }
    return (
      <div className="d-flex justify-content-between">
        <div className="topbar courses">
          <a href="/courses">
            <img
              className="lazyload mr-1"
              src={graduationCap}
              width="20"
              height="20"
              alt=""
            />
            all Courses
          </a>
        </div>
        {/* 
        //TODO: After Changes on offer Page Comment Will Remove.
        <div className="topbar offers">
          <a href="/offers">
            <span className="mx-auto w-100">
              <img
                className="lazyload mr-1"
                src={mobOffers}
                width="20"
                height="20"
                alt=""
              />
              <span className="offer_color">Offer</span>
            </span>
          </a>
        </div> */}
        <div className="topbar brochure">
          <a
            href=" "
            data-toggle="modal"
            data-target="#download_brochure"
            onClick={() => setModalType('downloadBrochure')}
          >
            <img
              className="lazyload mr-1"
              src={download}
              width="14"
              height="14"
              alt=""
            />
            Brochure
          </a>
        </div>
      </div>
    )
  }

  return window?.innerWidth && window.innerWidth > 767 ? (
    <></>
  ) : (
    <div className="topbar_fix">{getMobileHeader()}</div>
  )
}
export default memo(SmallHeader)
