/* eslint-disable jsx-a11y/anchor-is-valid */
import {useModalTypeContext} from 'context/modalTypeContext'
import React, { memo } from 'react'
import download from 'assets/images/svgs/download.png'

const BlueTitleWithDesc = memo(({ info, classAddOn, classAddDescOn }) => {
  const { setModalType } = useModalTypeContext()
  return (
    <section>
      <div className="container">
        {!info?.BTNTitleShow ? 
          <>
            {info.mainTitle &&
              <div
              className={`menu-title-pg-diploma mb-3 ${
                classAddOn ? classAddOn : ''
              }`}
            > 
              <h1>{info.mainTitle}</h1>
            </div>
            }
            {info.title &&        
              <div
                className={`menu-title-pg-diploma mb-3 ${
                  classAddOn ? classAddOn : ''
                }`}
              > 
                <h2>{info.title}</h2>
              </div>
            }
          </> : 
          <>
            <div className="row align-items-center">
                <div className="col-md-6 mb-3">
                <a>
                  <h2 className="course-title mb-2 ct-lg-normal-text">
                    {info.title}
                  </h2>
                </a>
                </div>
                  <div className="col-md-6 mb-3 ct-mb-sm-24">
                    <div className="dnld-btn">
                      <a
                        className="ctc-btn teblet-down"
                        data-toggle="modal"
                        data-target="#download_brochure"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setModalType('downloadBrochure')}
                      >
                        <img
                          className="lazyloaded"
                          src={download}
                          width="15"
                          height="15"
                          alt=""
                        />{' '}
                           Course Syllabus
                      </a>
                    </div>
                  </div>
            </div>
          </>
        }
        <div
          className={`counrse-contn-pg-diploma ${
            classAddDescOn ? classAddDescOn : ''
          }`}
          dangerouslySetInnerHTML={{
            __html: info.longDescription,
          }}
        ></div>
      </div>
    </section>
  )
})
export default BlueTitleWithDesc
