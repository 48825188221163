import { useEffect, useReducer } from 'react'
import constate from 'constate'
import logger from './Logger'
import useLocalStorage from '../shared/useLocalStorage'

export const initialState = {
  student: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_STUDENT':
      return {
        ...state,
        student: { ...action.payload.student },
      }

    default:
      return state
  }
}

const loggerReducer = logger(reducer)

const useStudent = () => {
  const [data, setData] = useLocalStorage('student', initialState)
  const [state, dispatch] = useReducer(loggerReducer, data)

  useEffect(() => {
    setData(state)
  }, [state, setData])

  const { student } = state

  const setStudent = (student) => {
    dispatch({
      type: 'SET_STUDENT',
      payload: { student },
    })
  }

  return {
    student,
    setStudent,
  }
}

export const [StudentProvider, useStudentContext] = constate(useStudent)
