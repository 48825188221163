import { useState } from 'react'
import constate from 'constate'

const usePages = () => {
  const [enterprise, setEnterprise] = useState('')
  const [courseCrafted, setCourseCrafted] = useState('')
  const [courses, setCourses] = useState('')
  const [offers, setOffers] = useState('')
  const [instructor, setInstructor] = useState('')
  const [whyUs, setWhyUs] = useState('')
  const [courseUpBatch, setCourseUpBatch] = useState('')
  const [sitemap, setSitemap] = useState('')
  const [allTestimonial, setAllTestimonial] = useState('')
  const [verifyMessage, setVerifyMessage] = useState('')

  return {
    verifyMessage,
    setVerifyMessage,
    enterprise,
    setEnterprise,
    courseCrafted,
    setCourseCrafted,
    courses,
    setCourses,
    offers,
    setOffers,
    instructor,
    setInstructor,
    whyUs,
    setWhyUs,
    courseUpBatch,
    setCourseUpBatch,
    allTestimonial,
    setAllTestimonial,
    sitemap,
    setSitemap,
  }
}
export const [PagesProvider, usePagesContext] = constate(usePages)
