export const defaultCityList = [
  {
    label: 'metro',
    options: [
      {
        label: 'Bangalore',
        value: 1,
      },
      {
        label: 'Chennai',
        value: 2,
      },
      {
        label: 'Delhi',
        value: 3,
      },
      {
        label: 'Gurgaon',
        value: 4,
      },
      {
        label: 'Hyderabad',
        value: 5,
      },
      {
        label: 'Kolkata',
        value: 6,
      },
      {
        label: 'Mumbai',
        value: 7,
      },
      {
        label: 'Noida',
        value: 8,
      },
      {
        label: 'Pune',
        value: 9,
      },
    ],
  },
  {
    label: 'intenational',
    options: [
      {
        label: 'Outside India - Americas',
        value: 10,
      },
      {
        label: 'Outside India - Asia',
        value: 11,
      },
      {
        label: 'Outside India - Australia',
        value: 12,
      },
      {
        label: 'Outside India - EU',
        value: 13,
      },
      {
        label: 'Outside India - United Kingdom',
        value: 14,
      },
    ],
  },
  {
    label: 'rest of india',
    options: [
      {
        label: 'Agra',
        value: 15,
      },
      {
        label: 'Ahmedabad',
        value: 16,
      },
      {
        label: 'Alappuzha',
        value: 17,
      },
      {
        label: 'Alwar',
        value: 18,
      },
      {
        label: 'Amritsar',
        value: 19,
      },
      {
        label: 'Aurangabad',
        value: 20,
      },
      {
        label: 'Bharatpur',
        value: 21,
      },
      {
        label: 'Bhavnagar',
        value: 22,
      },
      {
        label: 'Bikaner',
        value: 23,
      },
      {
        label: 'Bhopal',
        value: 24,
      },
      {
        label: 'Bhubaneshwar',
        value: 25,
      },
      {
        label: 'Bodh Gaya',
        value: 26,
      },
      {
        label: 'Calangute',
        value: 27,
      },
      {
        label: 'Chandigarh',
        value: 28,
      },
      {
        label: 'Chittorgarh',
        value: 29,
      },
      {
        label: 'Coimbatore',
        value: 30,
      },
      {
        label: 'Cuttack',
        value: 31,
      },
      {
        label: 'Dalhousie',
        value: 32,
      },
      {
        label: 'Dehradun',
        value: 33,
      },
      {
        label: 'Diu-Island',
        value: 34,
      },
      {
        label: 'Ernakulam',
        value: 35,
      },
      {
        label: 'Faridabad',
        value: 36,
      },
      {
        label: 'Gangtok',
        value: 37,
      },
      {
        label: 'Gaya',
        value: 38,
      },
      {
        label: 'Ghaziabad',
        value: 39,
      },
      {
        label: 'Guwahati',
        value: 40,
      },
      {
        label: 'Gwalior',
        value: 41,
      },
      {
        label: 'Haridwar',
        value: 42,
      },
      {
        label: 'Imphal',
        value: 43,
      },
      {
        label: 'Indore',
        value: 44,
      },
      {
        label: 'Jabalpur',
        value: 45,
      },
      {
        label: 'Jaipur',
        value: 46,
      },
      {
        label: 'Jaisalmer',
        value: 47,
      },
      {
        label: 'Jalandhar',
        value: 48,
      },
      {
        label: 'Jamshedpur',
        value: 49,
      },
      {
        label: 'Jodhpur',
        value: 50,
      },
      {
        label: 'Junagadh',
        value: 51,
      },
      {
        label: 'Kanpur',
        value: 52,
      },
      {
        label: 'Kanyakumari',
        value: 53,
      },
      {
        label: 'Khajuraho',
        value: 54,
      },
      {
        label: 'Khandala',
        value: 55,
      },
      {
        label: 'Kochi',
        value: 56,
      },
      {
        label: 'Kodaikanal',
        value: 57,
      },
      {
        label: 'Kota',
        value: 58,
      },
      {
        label: 'Kottayam',
        value: 59,
      },
      {
        label: 'Kovalam',
        value: 60,
      },
      {
        label: 'Lucknow',
        value: 61,
      },
      {
        label: 'Ludhiana',
        value: 62,
      },
      {
        label: 'Madurai',
        value: 63,
      },
      {
        label: 'Manali',
        value: 64,
      },
      {
        label: 'Mangalore',
        value: 65,
      },
      {
        label: 'Margao',
        value: 66,
      },
      {
        label: 'Mathura',
        value: 67,
      },
      {
        label: 'Mount Abu',
        value: 68,
      },
      {
        label: 'Modinagar',
        value: 69,
      },
      {
        label: 'Mussoorie',
        value: 70,
      },
      {
        label: 'Mysore',
        value: 71,
      },
      {
        label: 'Nagpur',
        value: 72,
      },
      {
        label: 'Nainital',
        value: 73,
      },
      {
        label: 'Nedumangad',
        value: 74,
      },
      {
        label: 'Neemuch',
        value: 75,
      },
      {
        label: 'Ooty',
        value: 76,
      },
      {
        label: 'Orchha',
        value: 77,
      },
      {
        label: 'Panaji',
        value: 78,
      },
      {
        label: 'Pauri',
        value: 79,
      },
      {
        label: 'Patna',
        value: 80,
      },
      {
        label: 'Pondicherry',
        value: 81,
      },
      {
        label: 'Porbandar',
        value: 82,
      },
      {
        label: 'Port Blair',
        value: 83,
      },
      {
        label: 'Puri',
        value: 84,
      },
      {
        label: 'Pushkar',
        value: 85,
      },
      {
        label: 'Rajkot',
        value: 86,
      },
      {
        label: 'Rameshwaram',
        value: 87,
      },
      {
        label: 'Ranchi',
        value: 88,
      },
      {
        label: 'Sanchi',
        value: 89,
      },
      {
        label: 'Secunderabad',
        value: 90,
      },
      {
        label: 'Shimla',
        value: 91,
      },
      {
        label: 'Surat',
        value: 92,
      },
      {
        label: 'Thanjavur',
        value: 93,
      },
      {
        label: 'Tiruchirappalli',
        value: 94,
      },
      {
        label: 'Thrissur',
        value: 95,
      },
      {
        label: 'Tirumala',
        value: 96,
      },
      {
        label: 'Udaipur',
        value: 97,
      },
      {
        label: 'Vadodra',
        value: 98,
      },
      {
        label: 'Varanasi',
        value: 99,
      },
      {
        label: 'Vasco-Da-Gama',
        value: 100,
      },
      {
        label: 'Vijayawada',
        value: 101,
      },
      {
        label: 'Achalpur',
        value: 102,
      },
      {
        label: 'Achhnera',
        value: 103,
      },
      {
        label: 'Adalaj',
        value: 104,
      },
      {
        label: 'Adilabad',
        value: 105,
      },
      {
        label: 'Adityapur',
        value: 106,
      },
      {
        label: 'Adoni',
        value: 107,
      },
      {
        label: 'Adoor',
        value: 108,
      },
      {
        label: 'Adra',
        value: 109,
      },
      {
        label: 'Adyar',
        value: 110,
      },
      {
        label: 'Afzalpur',
        value: 111,
      },
      {
        label: 'Agartala',
        value: 112,
      },
      {
        label: 'Ahmednagar',
        value: 113,
      },
      {
        label: 'Aizawl',
        value: 114,
      },
      {
        label: 'Ajmer',
        value: 115,
      },
      {
        label: 'Akola',
        value: 116,
      },
      {
        label: 'Akot',
        value: 117,
      },
      {
        label: 'Aligarh',
        value: 118,
      },
      {
        label: 'Alirajpur',
        value: 119,
      },
      {
        label: 'Allahabad',
        value: 120,
      },
      {
        label: 'Amalapuram',
        value: 121,
      },
      {
        label: 'Amalner',
        value: 122,
      },
      {
        label: 'Ambala',
        value: 123,
      },
      {
        label: 'Ambejogai',
        value: 124,
      },
      {
        label: 'Ambikapur',
        value: 125,
      },
      {
        label: 'Amravati',
        value: 126,
      },
      {
        label: 'Amreli',
        value: 127,
      },
      {
        label: 'Amroha',
        value: 128,
      },
      {
        label: 'Anakapalle',
        value: 129,
      },
      {
        label: 'Anand',
        value: 130,
      },
      {
        label: 'Anantapur',
        value: 131,
      },
      {
        label: 'Anantnag',
        value: 132,
      },
      {
        label: 'Anjangaon',
        value: 133,
      },
      {
        label: 'Anjar',
        value: 134,
      },
      {
        label: 'Ankleshwar',
        value: 135,
      },
      {
        label: 'Arakkonam',
        value: 136,
      },
      {
        label: 'Arambagh',
        value: 137,
      },
      {
        label: 'Araria',
        value: 138,
      },
      {
        label: 'Arrah',
        value: 139,
      },
      {
        label: 'Arsikere',
        value: 140,
      },
      {
        label: 'Aruppukkottai',
        value: 141,
      },
      {
        label: 'Arvi',
        value: 142,
      },
      {
        label: 'Arwal',
        value: 143,
      },
      {
        label: 'Asansol',
        value: 144,
      },
      {
        label: 'Asarganj',
        value: 145,
      },
      {
        label: 'Ashok Nagar',
        value: 146,
      },
      {
        label: 'Athni',
        value: 147,
      },
      {
        label: 'Attingal',
        value: 148,
      },
      {
        label: 'Azamgarh',
        value: 149,
      },
      {
        label: 'Bagaha',
        value: 150,
      },
      {
        label: 'Bageshwar',
        value: 151,
      },
      {
        label: 'Bahadurgarh',
        value: 152,
      },
      {
        label: 'Baharampur',
        value: 153,
      },
      {
        label: 'Bahraich',
        value: 154,
      },
      {
        label: 'Balaghat',
        value: 155,
      },
      {
        label: 'Balangir',
        value: 156,
      },
      {
        label: 'Baleshwar Town',
        value: 157,
      },
      {
        label: 'Ballari',
        value: 158,
      },
      {
        label: 'Balurghat',
        value: 159,
      },
      {
        label: 'Bankura',
        value: 160,
      },
      {
        label: 'Bapatla',
        value: 161,
      },
      {
        label: 'Baramula',
        value: 162,
      },
      {
        label: 'Barbil',
        value: 163,
      },
      {
        label: 'Bargarh',
        value: 164,
      },
      {
        label: 'Barh',
        value: 165,
      },
      {
        label: 'Baripada Town',
        value: 166,
      },
      {
        label: 'Barmer',
        value: 167,
      },
      {
        label: 'Barnala',
        value: 168,
      },
      {
        label: 'Barpeta',
        value: 169,
      },
      {
        label: 'Batala',
        value: 170,
      },
      {
        label: 'Bathinda',
        value: 171,
      },
      {
        label: 'Begusarai',
        value: 172,
      },
      {
        label: 'Belagavi',
        value: 173,
      },
      {
        label: 'Bellampalle',
        value: 174,
      },
      {
        label: 'Belonia',
        value: 175,
      },
      {
        label: 'Bengaluru',
        value: 176,
      },
      {
        label: 'Bettiah',
        value: 177,
      },
      {
        label: 'BhabUrban Agglomeration',
        value: 178,
      },
      {
        label: 'Bhadrachalam',
        value: 179,
      },
      {
        label: 'Bhadrak',
        value: 180,
      },
      {
        label: 'Bhagalpur',
        value: 181,
      },
      {
        label: 'Bhainsa',
        value: 182,
      },
      {
        label: 'Bharuch',
        value: 183,
      },
      {
        label: 'Bhatapara',
        value: 184,
      },
      {
        label: 'Bhawanipatna',
        value: 185,
      },
      {
        label: 'Bheemunipatnam',
        value: 186,
      },
      {
        label: 'Bhilai Nagar',
        value: 187,
      },
      {
        label: 'Bhilwara',
        value: 188,
      },
      {
        label: 'Bhimavaram',
        value: 189,
      },
      {
        label: 'Bhiwandi',
        value: 190,
      },
      {
        label: 'Bhiwani',
        value: 191,
      },
      {
        label: 'Bhongir',
        value: 192,
      },
      {
        label: 'Bhuj',
        value: 193,
      },
      {
        label: 'Bilaspur',
        value: 194,
      },
      {
        label: 'Bobbili',
        value: 195,
      },
      {
        label: 'Bodhan',
        value: 196,
      },
      {
        label: 'Bokaro Steel City',
        value: 197,
      },
      {
        label: 'Bongaigaon City',
        value: 198,
      },
      {
        label: 'Brahmapur',
        value: 199,
      },
      {
        label: 'Buxar',
        value: 200,
      },
      {
        label: 'Byasanagar',
        value: 201,
      },
      {
        label: 'Chaibasa',
        value: 202,
      },
      {
        label: 'Chalakudy',
        value: 203,
      },
      {
        label: 'Chandausi',
        value: 204,
      },
      {
        label: 'Changanassery',
        value: 205,
      },
      {
        label: 'Charkhi Dadri',
        value: 206,
      },
      {
        label: 'Chatra',
        value: 207,
      },
      {
        label: 'Cherthala',
        value: 208,
      },
      {
        label: 'Chhapra',
        value: 209,
      },
      {
        label: 'Chikkamagaluru',
        value: 210,
      },
      {
        label: 'Chilakaluripet',
        value: 211,
      },
      {
        label: 'Chirala',
        value: 212,
      },
      {
        label: 'Chirkunda',
        value: 213,
      },
      {
        label: 'Chirmiri',
        value: 214,
      },
      {
        label: 'Chittoor',
        value: 215,
      },
      {
        label: 'Dalli-Rajhara',
        value: 216,
      },
      {
        label: 'Darbhanga',
        value: 217,
      },
      {
        label: 'Darjiling',
        value: 218,
      },
      {
        label: 'Davanagere',
        value: 219,
      },
      {
        label: 'Deesa',
        value: 220,
      },
      {
        label: 'Dehri-on-Sone',
        value: 221,
      },
      {
        label: 'Deoghar',
        value: 222,
      },
      {
        label: 'Dhamtari',
        value: 223,
      },
      {
        label: 'Dhanbad',
        value: 224,
      },
      {
        label: 'Dharmanagar',
        value: 225,
      },
      {
        label: 'Dharmavaram',
        value: 226,
      },
      {
        label: 'Dhenkanal',
        value: 227,
      },
      {
        label: 'Dhoraji',
        value: 228,
      },
      {
        label: 'Dhubri',
        value: 229,
      },
      {
        label: 'Dhule',
        value: 230,
      },
      {
        label: 'Dhuri',
        value: 231,
      },
      {
        label: 'Dibrugarh',
        value: 232,
      },
      {
        label: 'Dimapur',
        value: 233,
      },
      {
        label: 'Diphu',
        value: 234,
      },
      {
        label: 'Dispur',
        value: 235,
      },
      {
        label: 'Dumka',
        value: 236,
      },
      {
        label: 'Dumraon',
        value: 237,
      },
      {
        label: 'Durg',
        value: 238,
      },
      {
        label: 'Eluru',
        value: 239,
      },
      {
        label: 'English Bazar',
        value: 240,
      },
      {
        label: 'Erode',
        value: 241,
      },
      {
        label: 'Etawah',
        value: 242,
      },
      {
        label: 'Faridkot',
        value: 243,
      },
      {
        label: 'Farooqnagar',
        value: 244,
      },
      {
        label: 'Fatehabad',
        value: 245,
      },
      {
        label: 'Fatehpur Sikri',
        value: 246,
      },
      {
        label: 'Fazilka',
        value: 247,
      },
      {
        label: 'Firozabad',
        value: 248,
      },
      {
        label: 'Firozpur',
        value: 249,
      },
      {
        label: 'Forbesganj',
        value: 250,
      },
      {
        label: 'Gadwal',
        value: 251,
      },
      {
        label: 'Gandhinagar',
        value: 252,
      },
      {
        label: 'Gangarampur',
        value: 253,
      },
      {
        label: 'Ganjbasoda',
        value: 254,
      },
      {
        label: 'Giridih',
        value: 255,
      },
      {
        label: 'Goalpara',
        value: 256,
      },
      {
        label: 'Gobichettipalayam',
        value: 257,
      },
      {
        label: 'Gobindgarh',
        value: 258,
      },
      {
        label: 'Godhra',
        value: 259,
      },
      {
        label: 'Gohana',
        value: 260,
      },
      {
        label: 'Gokak',
        value: 261,
      },
      {
        label: 'Gooty',
        value: 262,
      },
      {
        label: 'Gopalganj',
        value: 263,
      },
      {
        label: 'Gudivada',
        value: 264,
      },
      {
        label: 'Gudur',
        value: 265,
      },
      {
        label: 'Gumia',
        value: 266,
      },
      {
        label: 'Guntakal',
        value: 267,
      },
      {
        label: 'Guntur',
        value: 268,
      },
      {
        label: 'Gurdaspur',
        value: 269,
      },
      {
        label: 'Gurugram',
        value: 270,
      },
      {
        label: 'Guruvayoor',
        value: 271,
      },
      {
        label: 'Habra',
        value: 272,
      },
      {
        label: 'Hajipur',
        value: 273,
      },
      {
        label: 'Haldwani-cum-Kathgodam',
        value: 274,
      },
      {
        label: 'Hansi',
        value: 275,
      },
      {
        label: 'Hapur',
        value: 276,
      },
      {
        label: 'Hardoi',
        value: 277,
      },
      {
        label: 'Hazaribag',
        value: 278,
      },
      {
        label: 'Hindupur',
        value: 279,
      },
      {
        label: 'Hisar',
        value: 280,
      },
      {
        label: 'Hoshiarpur',
        value: 281,
      },
      {
        label: 'Hubli-Dharwad',
        value: 282,
      },
      {
        label: 'Hugli-Chinsurah',
        value: 283,
      },
      {
        label: 'Ichalkaranji',
        value: 284,
      },
      {
        label: 'Itarsi',
        value: 285,
      },
      {
        label: 'Jagdalpur',
        value: 286,
      },
      {
        label: 'Jaggaiahpet',
        value: 287,
      },
      {
        label: 'Jagraon',
        value: 288,
      },
      {
        label: 'Jagtial',
        value: 289,
      },
      {
        label: 'Jalpaiguri',
        value: 290,
      },
      {
        label: 'Jamalpur',
        value: 291,
      },
      {
        label: 'Jammalamadugu',
        value: 292,
      },
      {
        label: 'Jammu',
        value: 293,
      },
      {
        label: 'Jamnagar',
        value: 294,
      },
      {
        label: 'Jamui',
        value: 295,
      },
      {
        label: 'Jangaon',
        value: 296,
      },
      {
        label: 'Jatani',
        value: 297,
      },
      {
        label: 'Jehanabad',
        value: 298,
      },
      {
        label: 'Jhansi',
        value: 299,
      },
      {
        label: 'Jhargram',
        value: 300,
      },
      {
        label: 'Jharsuguda',
        value: 301,
      },
      {
        label: 'Jhumri Tilaiya',
        value: 302,
      },
      {
        label: 'Jind',
        value: 303,
      },
      {
        label: 'Jorhat',
        value: 304,
      },
      {
        label: 'Kadapa',
        value: 305,
      },
      {
        label: 'Kadi',
        value: 306,
      },
      {
        label: 'Kadiri',
        value: 307,
      },
      {
        label: 'Kagaznagar',
        value: 308,
      },
      {
        label: 'Kailasahar',
        value: 309,
      },
      {
        label: 'Kaithal',
        value: 310,
      },
      {
        label: 'Kakinada',
        value: 311,
      },
      {
        label: 'Kalimpong',
        value: 312,
      },
      {
        label: 'Kalpi',
        value: 313,
      },
      {
        label: 'Kalyan-Dombivali',
        value: 314,
      },
      {
        label: 'Kamareddy',
        value: 315,
      },
      {
        label: 'Kancheepuram',
        value: 316,
      },
      {
        label: 'Kandukur',
        value: 317,
      },
      {
        label: 'Kanhangad',
        value: 318,
      },
      {
        label: 'Kannur',
        value: 319,
      },
      {
        label: 'Kapadvanj',
        value: 320,
      },
      {
        label: 'Kapurthala',
        value: 321,
      },
      {
        label: 'Karaikal',
        value: 322,
      },
      {
        label: 'Karimganj',
        value: 323,
      },
      {
        label: 'Karimnagar',
        value: 324,
      },
      {
        label: 'Karjat',
        value: 325,
      },
      {
        label: 'Karnal',
        value: 326,
      },
      {
        label: 'Karur',
        value: 327,
      },
      {
        label: 'Karwar',
        value: 328,
      },
      {
        label: 'Kasaragod',
        value: 329,
      },
      {
        label: 'Kashipur',
        value: 330,
      },
      {
        label: 'Katihar',
        value: 331,
      },
      {
        label: 'Kavali',
        value: 332,
      },
      {
        label: 'Kayamkulam',
        value: 333,
      },
      {
        label: 'Kendrapara',
        value: 334,
      },
      {
        label: 'Kendujhar',
        value: 335,
      },
      {
        label: 'Keshod',
        value: 336,
      },
      {
        label: 'Khair',
        value: 337,
      },
      {
        label: 'Khambhat',
        value: 338,
      },
      {
        label: 'Khammam',
        value: 339,
      },
      {
        label: 'Khanna',
        value: 340,
      },
      {
        label: 'Kharagpur',
        value: 341,
      },
      {
        label: 'Kharar',
        value: 342,
      },
      {
        label: 'Khowai',
        value: 343,
      },
      {
        label: 'Kishanganj',
        value: 344,
      },
      {
        label: 'Kodungallur',
        value: 345,
      },
      {
        label: 'Kohima',
        value: 346,
      },
      {
        label: 'Kolar',
        value: 347,
      },
      {
        label: 'Kollam',
        value: 348,
      },
      {
        label: 'Koratla',
        value: 349,
      },
      {
        label: 'Korba',
        value: 350,
      },
      {
        label: 'Kot Kapura',
        value: 351,
      },
      {
        label: 'Kothagudem',
        value: 352,
      },
      {
        label: 'Kovvur',
        value: 353,
      },
      {
        label: 'Koyilandy',
        value: 354,
      },
      {
        label: 'Kozhikode',
        value: 355,
      },
      {
        label: 'Kullu',
        value: 356,
      },
      {
        label: 'Kunnamkulam',
        value: 357,
      },
      {
        label: 'Kurnool',
        value: 358,
      },
      {
        label: 'Kyathampalle',
        value: 359,
      },
      {
        label: 'Lachhmangarh',
        value: 360,
      },
      {
        label: 'Ladnu',
        value: 361,
      },
      {
        label: 'Ladwa',
        value: 362,
      },
      {
        label: 'Lahar',
        value: 363,
      },
      {
        label: 'Laharpur',
        value: 364,
      },
      {
        label: 'Lakheri',
        value: 365,
      },
      {
        label: 'Lakhimpur',
        value: 366,
      },
      {
        label: 'Lakhisarai',
        value: 367,
      },
      {
        label: 'Lakshmeshwar',
        value: 368,
      },
      {
        label: 'Lal Gopalganj Nindaura',
        value: 369,
      },
      {
        label: 'Lalganj',
        value: 370,
      },
      {
        label: 'Lalgudi',
        value: 371,
      },
      {
        label: 'Lalitpur',
        value: 372,
      },
      {
        label: 'Lalsot',
        value: 373,
      },
      {
        label: 'Lanka',
        value: 374,
      },
      {
        label: 'Lar',
        value: 375,
      },
      {
        label: 'Lathi',
        value: 376,
      },
      {
        label: 'Latur',
        value: 377,
      },
      {
        label: 'Lilong',
        value: 378,
      },
      {
        label: 'Limbdi',
        value: 379,
      },
      {
        label: 'Lingsugur',
        value: 380,
      },
      {
        label: 'Loha',
        value: 381,
      },
      {
        label: 'Lohardaga',
        value: 382,
      },
      {
        label: 'Lonar',
        value: 383,
      },
      {
        label: 'Lonavla',
        value: 384,
      },
      {
        label: 'Longowal',
        value: 385,
      },
      {
        label: 'Loni',
        value: 386,
      },
      {
        label: 'Losal',
        value: 387,
      },
      {
        label: 'Lumding',
        value: 388,
      },
      {
        label: 'Lunawada',
        value: 389,
      },
      {
        label: 'Lunglei',
        value: 390,
      },
      {
        label: 'Macherla',
        value: 391,
      },
      {
        label: 'Machilipatnam',
        value: 392,
      },
      {
        label: 'Madanapalle',
        value: 393,
      },
      {
        label: 'Maddur',
        value: 394,
      },
      {
        label: 'Madhepura',
        value: 395,
      },
      {
        label: 'Madhubani',
        value: 396,
      },
      {
        label: 'Madhugiri',
        value: 397,
      },
      {
        label: 'Madhupur',
        value: 398,
      },
      {
        label: 'Madikeri',
        value: 399,
      },
      {
        label: 'Magadi',
        value: 400,
      },
      {
        label: 'Mahad',
        value: 401,
      },
      {
        label: 'Mahalingapura',
        value: 402,
      },
      {
        label: 'Maharajganj',
        value: 403,
      },
      {
        label: 'Maharajpur',
        value: 404,
      },
      {
        label: 'Mahasamund',
        value: 405,
      },
      {
        label: 'Mahbubnagar',
        value: 406,
      },
      {
        label: 'Mahe',
        value: 407,
      },
      {
        label: 'Mahemdabad',
        value: 408,
      },
      {
        label: 'Mahendragarh',
        value: 409,
      },
      {
        label: 'Mahesana',
        value: 410,
      },
      {
        label: 'Mahidpur',
        value: 411,
      },
      {
        label: 'Mahnar Bazar',
        value: 412,
      },
      {
        label: 'Mahuva',
        value: 413,
      },
      {
        label: 'Maihar',
        value: 414,
      },
      {
        label: 'Mainaguri',
        value: 415,
      },
      {
        label: 'Makhdumpur',
        value: 416,
      },
      {
        label: 'Makrana',
        value: 417,
      },
      {
        label: 'Malaj Khand',
        value: 418,
      },
      {
        label: 'Malappuram',
        value: 419,
      },
      {
        label: 'Malavalli',
        value: 420,
      },
      {
        label: 'Malda',
        value: 421,
      },
      {
        label: 'Malegaon',
        value: 422,
      },
      {
        label: 'Malerkotla',
        value: 423,
      },
      {
        label: 'Malkangiri',
        value: 424,
      },
      {
        label: 'Malkapur',
        value: 425,
      },
      {
        label: 'Malout',
        value: 426,
      },
      {
        label: 'Malpura',
        value: 427,
      },
      {
        label: 'Malur',
        value: 428,
      },
      {
        label: 'Manachanallur',
        value: 429,
      },
      {
        label: 'Manasa',
        value: 430,
      },
      {
        label: 'Manavadar',
        value: 431,
      },
      {
        label: 'Manawar',
        value: 432,
      },
      {
        label: 'Mancherial',
        value: 433,
      },
      {
        label: 'Mandalgarh',
        value: 434,
      },
      {
        label: 'Mandamarri',
        value: 435,
      },
      {
        label: 'Mandapeta',
        value: 436,
      },
      {
        label: 'Mandawa',
        value: 437,
      },
      {
        label: 'Mandi',
        value: 438,
      },
      {
        label: 'Mandi Dabwali',
        value: 439,
      },
      {
        label: 'Mandideep',
        value: 440,
      },
      {
        label: 'Mandla',
        value: 441,
      },
      {
        label: 'Mandsaur',
        value: 442,
      },
      {
        label: 'Mandvi',
        value: 443,
      },
      {
        label: 'Mandya',
        value: 444,
      },
      {
        label: 'Manendragarh',
        value: 445,
      },
      {
        label: 'Maner',
        value: 446,
      },
      {
        label: 'Mangaldoi',
        value: 447,
      },
      {
        label: 'Mangalvedhe',
        value: 448,
      },
      {
        label: 'Manglaur',
        value: 449,
      },
      {
        label: 'Mangrol',
        value: 450,
      },
      {
        label: 'Mangrulpir',
        value: 451,
      },
      {
        label: 'Manihari',
        value: 452,
      },
      {
        label: 'Manjlegaon',
        value: 453,
      },
      {
        label: 'Mankachar',
        value: 454,
      },
      {
        label: 'Manmad',
        value: 455,
      },
      {
        label: 'Mansa',
        value: 456,
      },
      {
        label: 'Manuguru',
        value: 457,
      },
      {
        label: 'Manvi',
        value: 458,
      },
      {
        label: 'Manwath',
        value: 459,
      },
      {
        label: 'Mapusa',
        value: 460,
      },
      {
        label: 'Margherita',
        value: 461,
      },
      {
        label: 'Marhaura',
        value: 462,
      },
      {
        label: 'Mariani',
        value: 463,
      },
      {
        label: 'Marigaon',
        value: 464,
      },
      {
        label: 'Markapur',
        value: 465,
      },
      {
        label: 'Marmagao',
        value: 466,
      },
      {
        label: 'Masaurhi',
        value: 467,
      },
      {
        label: 'Mathabhanga',
        value: 468,
      },
      {
        label: 'Mattannur',
        value: 469,
      },
      {
        label: 'Mauganj',
        value: 470,
      },
      {
        label: 'Mavelikkara',
        value: 471,
      },
      {
        label: 'Mavoor',
        value: 472,
      },
      {
        label: 'Mayang Imphal',
        value: 473,
      },
      {
        label: 'Medak',
        value: 474,
      },
      {
        label: 'Medininagar (Daltonganj)',
        value: 475,
      },
      {
        label: 'Medinipur',
        value: 476,
      },
      {
        label: 'Meerut',
        value: 477,
      },
      {
        label: 'Mehkar',
        value: 478,
      },
      {
        label: 'Memari',
        value: 479,
      },
      {
        label: 'Merta City',
        value: 480,
      },
      {
        label: 'Mhaswad',
        value: 481,
      },
      {
        label: 'Mhow Cantonment',
        value: 482,
      },
      {
        label: 'Mhowgaon',
        value: 483,
      },
      {
        label: 'Mihijam',
        value: 484,
      },
      {
        label: 'Mira-Bhayandar',
        value: 485,
      },
      {
        label: 'Mirganj',
        value: 486,
      },
      {
        label: 'Miryalaguda',
        value: 487,
      },
      {
        label: 'Mirzapur',
        value: 488,
      },
      {
        label: 'Modasa',
        value: 489,
      },
      {
        label: 'Moga',
        value: 490,
      },
      {
        label: 'Mohali',
        value: 491,
      },
      {
        label: 'Mokameh',
        value: 492,
      },
      {
        label: 'Mokokchung',
        value: 493,
      },
      {
        label: 'Monoharpur',
        value: 494,
      },
      {
        label: 'Moradabad',
        value: 495,
      },
      {
        label: 'Morena',
        value: 496,
      },
      {
        label: 'Morinda, India',
        value: 497,
      },
      {
        label: 'Morshi',
        value: 498,
      },
      {
        label: 'Morvi',
        value: 499,
      },
      {
        label: 'Motihari',
        value: 500,
      },
      {
        label: 'Motipur',
        value: 501,
      },
      {
        label: 'Mudabidri',
        value: 502,
      },
      {
        label: 'Mudalagi',
        value: 503,
      },
      {
        label: 'Muddebihal',
        value: 504,
      },
      {
        label: 'Mudhol',
        value: 505,
      },
      {
        label: 'Mukerian',
        value: 506,
      },
      {
        label: 'Mukhed',
        value: 507,
      },
      {
        label: 'Muktsar',
        value: 508,
      },
      {
        label: 'Mul',
        value: 509,
      },
      {
        label: 'Mulbagal',
        value: 510,
      },
      {
        label: 'Multai',
        value: 511,
      },
      {
        label: 'Mundargi',
        value: 512,
      },
      {
        label: 'Mundi',
        value: 513,
      },
      {
        label: 'Mungeli',
        value: 514,
      },
      {
        label: 'Munger',
        value: 515,
      },
      {
        label: 'Murliganj',
        value: 516,
      },
      {
        label: 'Murshidabad',
        value: 517,
      },
      {
        label: 'Murtijapur',
        value: 518,
      },
      {
        label: 'Murwara (Katni)',
        value: 519,
      },
      {
        label: 'Musabani',
        value: 520,
      },
      {
        label: 'Muvattupuzha',
        value: 521,
      },
      {
        label: 'Muzaffarpur',
        value: 522,
      },
      {
        label: 'Nabadwip',
        value: 523,
      },
      {
        label: 'Nabarangapur',
        value: 524,
      },
      {
        label: 'Nabha',
        value: 525,
      },
      {
        label: 'Nadbai',
        value: 526,
      },
      {
        label: 'Nadiad',
        value: 527,
      },
      {
        label: 'Nagaon',
        value: 528,
      },
      {
        label: 'Nagapattinam',
        value: 529,
      },
      {
        label: 'Nagar',
        value: 530,
      },
      {
        label: 'Nagari',
        value: 531,
      },
      {
        label: 'Nagarkurnool',
        value: 532,
      },
      {
        label: 'Nagaur',
        value: 533,
      },
      {
        label: 'Nagda',
        value: 534,
      },
      {
        label: 'Nagercoil',
        value: 535,
      },
      {
        label: 'Nagina',
        value: 536,
      },
      {
        label: 'Nagla',
        value: 537,
      },
      {
        label: 'Nahan',
        value: 538,
      },
      {
        label: 'Naharlagun',
        value: 539,
      },
      {
        label: 'Naidupet',
        value: 540,
      },
      {
        label: 'Naihati',
        value: 541,
      },
      {
        label: 'Naila Janjgir',
        value: 542,
      },
      {
        label: 'Nainpur',
        value: 543,
      },
      {
        label: 'Najibabad',
        value: 544,
      },
      {
        label: 'Nakodar',
        value: 545,
      },
      {
        label: 'Nakur',
        value: 546,
      },
      {
        label: 'Nalbari',
        value: 547,
      },
      {
        label: 'Namagiripettai',
        value: 548,
      },
      {
        label: 'Namakkal',
        value: 549,
      },
      {
        label: 'Nanded-Waghala',
        value: 550,
      },
      {
        label: 'Nandgaon',
        value: 551,
      },
      {
        label: 'Nandivaram-Guduvancheri',
        value: 552,
      },
      {
        label: 'Nandura',
        value: 553,
      },
      {
        label: 'Nandurbar',
        value: 554,
      },
      {
        label: 'Nandyal',
        value: 555,
      },
      {
        label: 'Nangal',
        value: 556,
      },
      {
        label: 'Nanjangud',
        value: 557,
      },
      {
        label: 'Nanjikottai',
        value: 558,
      },
      {
        label: 'Nanpara',
        value: 559,
      },
      {
        label: 'Narasapuram',
        value: 560,
      },
      {
        label: 'Narasaraopet',
        value: 561,
      },
      {
        label: 'Naraura',
        value: 562,
      },
      {
        label: 'Narayanpet',
        value: 563,
      },
      {
        label: 'Nargund',
        value: 564,
      },
      {
        label: 'Narkatiaganj',
        value: 565,
      },
      {
        label: 'Narkhed',
        value: 566,
      },
      {
        label: 'Narnaul',
        value: 567,
      },
      {
        label: 'Narsinghgarh',
        value: 568,
      },
      {
        label: 'Narsipatnam',
        value: 569,
      },
      {
        label: 'Narwana',
        value: 570,
      },
      {
        label: 'Nashik',
        value: 571,
      },
      {
        label: 'Nasirabad',
        value: 572,
      },
      {
        label: 'Natham',
        value: 573,
      },
      {
        label: 'Nathdwara',
        value: 574,
      },
      {
        label: 'Naugachhia',
        value: 575,
      },
      {
        label: 'Naugawan Sadat',
        value: 576,
      },
      {
        label: 'Nautanwa',
        value: 577,
      },
      {
        label: 'Navalgund',
        value: 578,
      },
      {
        label: 'Navsari',
        value: 579,
      },
      {
        label: 'Nawabganj',
        value: 580,
      },
      {
        label: 'Nawada',
        value: 581,
      },
      {
        label: 'Nawanshahr',
        value: 582,
      },
      {
        label: 'Nawapur',
        value: 583,
      },
      {
        label: 'Neem-Ka-Thana',
        value: 584,
      },
      {
        label: 'Nehtaur',
        value: 585,
      },
      {
        label: 'Nelamangala',
        value: 586,
      },
      {
        label: 'Nellikuppam',
        value: 587,
      },
      {
        label: 'Nellore',
        value: 588,
      },
      {
        label: 'Nepanagar',
        value: 589,
      },
      {
        label: 'Neyveli (TS)',
        value: 590,
      },
      {
        label: 'Neyyattinkara',
        value: 591,
      },
      {
        label: 'Nidadavole',
        value: 592,
      },
      {
        label: 'Nilambur',
        value: 593,
      },
      {
        label: 'Nilanga',
        value: 594,
      },
      {
        label: 'Nimbahera',
        value: 595,
      },
      {
        label: 'Nirmal',
        value: 596,
      },
      {
        label: 'Niwai',
        value: 597,
      },
      {
        label: 'Niwari',
        value: 598,
      },
      {
        label: 'Nizamabad',
        value: 599,
      },
      {
        label: 'Nohar',
        value: 600,
      },
      {
        label: 'Nokha',
        value: 601,
      },
      {
        label: 'Nongstoin',
        value: 602,
      },
      {
        label: 'Noorpur',
        value: 603,
      },
      {
        label: 'North Lakhimpur',
        value: 604,
      },
      {
        label: 'Nowgong',
        value: 605,
      },
      {
        label: 'Nowrozabad (Khodargama)',
        value: 606,
      },
      {
        label: 'Nuzvid',
        value: 607,
      },
      {
        label: "O' Valley",
        value: 608,
      },
      {
        label: 'Obra',
        value: 609,
      },
      {
        label: 'Oddanchatram',
        value: 610,
      },
      {
        label: 'Ongole',
        value: 611,
      },
      {
        label: 'Orai',
        value: 612,
      },
      {
        label: 'Osmanabad',
        value: 613,
      },
      {
        label: 'Ottappalam',
        value: 614,
      },
      {
        label: 'Ozar',
        value: 615,
      },
      {
        label: 'P.N.Patti',
        value: 616,
      },
      {
        label: 'Pachora',
        value: 617,
      },
      {
        label: 'Pachore',
        value: 618,
      },
      {
        label: 'Pacode',
        value: 619,
      },
      {
        label: 'Padmanabhapuram',
        value: 620,
      },
      {
        label: 'Padra',
        value: 621,
      },
      {
        label: 'Padrauna',
        value: 622,
      },
      {
        label: 'Paithan',
        value: 623,
      },
      {
        label: 'Pakaur',
        value: 624,
      },
      {
        label: 'Palacole',
        value: 625,
      },
      {
        label: 'Palai',
        value: 626,
      },
      {
        label: 'Palakkad',
        value: 627,
      },
      {
        label: 'Palampur',
        value: 628,
      },
      {
        label: 'Palani',
        value: 629,
      },
      {
        label: 'Palanpur',
        value: 630,
      },
      {
        label: 'Palasa Kasibugga',
        value: 631,
      },
      {
        label: 'Palghar',
        value: 632,
      },
      {
        label: 'Pali',
        value: 633,
      },
      {
        label: 'Palia Kalan',
        value: 634,
      },
      {
        label: 'Palitana',
        value: 635,
      },
      {
        label: 'Palladam',
        value: 636,
      },
      {
        label: 'Pallapatti',
        value: 637,
      },
      {
        label: 'Pallikonda',
        value: 638,
      },
      {
        label: 'Palwal',
        value: 639,
      },
      {
        label: 'Palwancha',
        value: 640,
      },
      {
        label: 'Panagar',
        value: 641,
      },
      {
        label: 'Panagudi',
        value: 642,
      },
      {
        label: 'Panamattom',
        value: 643,
      },
      {
        label: 'Panchkula',
        value: 644,
      },
      {
        label: 'Panchla',
        value: 645,
      },
      {
        label: 'Pandharkaoda',
        value: 646,
      },
      {
        label: 'Pandharpur',
        value: 647,
      },
      {
        label: 'Pandhurna',
        value: 648,
      },
      {
        label: 'PandUrban Agglomeration',
        value: 649,
      },
      {
        label: 'Panipat',
        value: 650,
      },
      {
        label: 'Panna',
        value: 651,
      },
      {
        label: 'Panniyannur',
        value: 652,
      },
      {
        label: 'Panruti',
        value: 653,
      },
      {
        label: 'Panvel',
        value: 654,
      },
      {
        label: 'Pappinisseri',
        value: 655,
      },
      {
        label: 'Paradip',
        value: 656,
      },
      {
        label: 'Paramakudi',
        value: 657,
      },
      {
        label: 'Parangipettai',
        value: 658,
      },
      {
        label: 'Parasi',
        value: 659,
      },
      {
        label: 'Paravoor',
        value: 660,
      },
      {
        label: 'Parbhani',
        value: 661,
      },
      {
        label: 'Pardi',
        value: 662,
      },
      {
        label: 'Parlakhemundi',
        value: 663,
      },
      {
        label: 'Parli',
        value: 664,
      },
      {
        label: 'Partur',
        value: 665,
      },
      {
        label: 'Parvathipuram',
        value: 666,
      },
      {
        label: 'Pasan',
        value: 667,
      },
      {
        label: 'Paschim Punropara',
        value: 668,
      },
      {
        label: 'Pasighat',
        value: 669,
      },
      {
        label: 'Patan',
        value: 670,
      },
      {
        label: 'Pathanamthitta',
        value: 671,
      },
      {
        label: 'Pathankot',
        value: 672,
      },
      {
        label: 'Pathardi',
        value: 673,
      },
      {
        label: 'Pathri',
        value: 674,
      },
      {
        label: 'Patiala',
        value: 675,
      },
      {
        label: 'Patratu',
        value: 676,
      },
      {
        label: 'Pattamundai',
        value: 677,
      },
      {
        label: 'Patti',
        value: 678,
      },
      {
        label: 'Pattran',
        value: 679,
      },
      {
        label: 'Pattukkottai',
        value: 680,
      },
      {
        label: 'Patur',
        value: 681,
      },
      {
        label: 'Pauni',
        value: 682,
      },
      {
        label: 'Pavagada',
        value: 683,
      },
      {
        label: 'Pedana',
        value: 684,
      },
      {
        label: 'Peddapuram',
        value: 685,
      },
      {
        label: 'Pehowa',
        value: 686,
      },
      {
        label: 'Pen',
        value: 687,
      },
      {
        label: 'Perambalur',
        value: 688,
      },
      {
        label: 'Peravurani',
        value: 689,
      },
      {
        label: 'Peringathur',
        value: 690,
      },
      {
        label: 'Perinthalmanna',
        value: 691,
      },
      {
        label: 'Periyakulam',
        value: 692,
      },
      {
        label: 'Periyasemur',
        value: 693,
      },
      {
        label: 'Pernampattu',
        value: 694,
      },
      {
        label: 'Perumbavoor',
        value: 695,
      },
      {
        label: 'Petlad',
        value: 696,
      },
      {
        label: 'Phagwara',
        value: 697,
      },
      {
        label: 'Phalodi',
        value: 698,
      },
      {
        label: 'Phaltan',
        value: 699,
      },
      {
        label: 'Phillaur',
        value: 700,
      },
      {
        label: 'Phulabani',
        value: 701,
      },
      {
        label: 'Phulera',
        value: 702,
      },
      {
        label: 'Phulpur',
        value: 703,
      },
      {
        label: 'Phusro',
        value: 704,
      },
      {
        label: 'Pihani',
        value: 705,
      },
      {
        label: 'Pilani',
        value: 706,
      },
      {
        label: 'Pilibanga',
        value: 707,
      },
      {
        label: 'Pilibhit',
        value: 708,
      },
      {
        label: 'Pilkhuwa',
        value: 709,
      },
      {
        label: 'Pindwara',
        value: 710,
      },
      {
        label: 'Pinjore',
        value: 711,
      },
      {
        label: 'Pipar City',
        value: 712,
      },
      {
        label: 'Pipariya',
        value: 713,
      },
      {
        label: 'Piriyapatna',
        value: 714,
      },
      {
        label: 'Piro',
        value: 715,
      },
      {
        label: 'Pithampur',
        value: 716,
      },
      {
        label: 'Pithapuram',
        value: 717,
      },
      {
        label: 'Pithoragarh',
        value: 718,
      },
      {
        label: 'Pollachi',
        value: 719,
      },
      {
        label: 'Polur',
        value: 720,
      },
      {
        label: 'Ponnani',
        value: 721,
      },
      {
        label: 'Ponneri',
        value: 722,
      },
      {
        label: 'Ponnur',
        value: 723,
      },
      {
        label: 'Porsa',
        value: 724,
      },
      {
        label: 'Powayan',
        value: 725,
      },
      {
        label: 'Prantij',
        value: 726,
      },
      {
        label: 'Pratapgarh',
        value: 727,
      },
      {
        label: 'Prithvipur',
        value: 728,
      },
      {
        label: 'Proddatur',
        value: 729,
      },
      {
        label: 'Pudukkottai',
        value: 730,
      },
      {
        label: 'Pudupattinam',
        value: 731,
      },
      {
        label: 'Pukhrayan',
        value: 732,
      },
      {
        label: 'Pulgaon',
        value: 733,
      },
      {
        label: 'Puliyankudi',
        value: 734,
      },
      {
        label: 'Punalur',
        value: 735,
      },
      {
        label: 'Punch',
        value: 736,
      },
      {
        label: 'Punganur',
        value: 737,
      },
      {
        label: 'Punjaipugalur',
        value: 738,
      },
      {
        label: 'Puranpur',
        value: 739,
      },
      {
        label: 'Purna',
        value: 740,
      },
      {
        label: 'Purnia',
        value: 741,
      },
      {
        label: 'PurqUrban Agglomerationzi',
        value: 742,
      },
      {
        label: 'Purulia',
        value: 743,
      },
      {
        label: 'Purwa',
        value: 744,
      },
      {
        label: 'Pusad',
        value: 745,
      },
      {
        label: 'Puthuppally',
        value: 746,
      },
      {
        label: 'Puttur',
        value: 747,
      },
      {
        label: 'Qadian',
        value: 748,
      },
      {
        label: 'Raayachuru',
        value: 749,
      },
      {
        label: 'Rabkavi Banhatti',
        value: 750,
      },
      {
        label: 'Radhanpur',
        value: 751,
      },
      {
        label: 'Rae Bareli',
        value: 752,
      },
      {
        label: 'Rafiganj',
        value: 753,
      },
      {
        label: 'Raghogarh-Vijaypur',
        value: 754,
      },
      {
        label: 'Raghunathganj',
        value: 755,
      },
      {
        label: 'Raghunathpur',
        value: 756,
      },
      {
        label: 'Rahatgarh',
        value: 757,
      },
      {
        label: 'Rahuri',
        value: 758,
      },
      {
        label: 'Raiganj',
        value: 759,
      },
      {
        label: 'Raigarh',
        value: 760,
      },
      {
        label: 'Raikot',
        value: 761,
      },
      {
        label: 'Raipur',
        value: 762,
      },
      {
        label: 'Rairangpur',
        value: 763,
      },
      {
        label: 'Raisen',
        value: 764,
      },
      {
        label: 'Raisinghnagar',
        value: 765,
      },
      {
        label: 'Rajagangapur',
        value: 766,
      },
      {
        label: 'Rajahmundry',
        value: 767,
      },
      {
        label: 'Rajakhera',
        value: 768,
      },
      {
        label: 'Rajaldesar',
        value: 769,
      },
      {
        label: 'Rajam',
        value: 770,
      },
      {
        label: 'Rajampet',
        value: 771,
      },
      {
        label: 'Rajapalayam',
        value: 772,
      },
      {
        label: 'Rajauri',
        value: 773,
      },
      {
        label: 'Rajgarh',
        value: 774,
      },
      {
        label: 'Rajgir',
        value: 775,
      },
      {
        label: 'Rajnandgaon',
        value: 776,
      },
      {
        label: 'Rajpipla',
        value: 777,
      },
      {
        label: 'Rajpura',
        value: 778,
      },
      {
        label: 'Rajsamand',
        value: 779,
      },
      {
        label: 'Rajula',
        value: 780,
      },
      {
        label: 'Rajura',
        value: 781,
      },
      {
        label: 'Ramachandrapuram',
        value: 782,
      },
      {
        label: 'Ramagundam',
        value: 783,
      },
      {
        label: 'Ramanagaram',
        value: 784,
      },
      {
        label: 'Ramanathapuram',
        value: 785,
      },
      {
        label: 'Ramdurg',
        value: 786,
      },
      {
        label: 'Ramganj Mandi',
        value: 787,
      },
      {
        label: 'Ramgarh',
        value: 788,
      },
      {
        label: 'Ramnagar',
        value: 789,
      },
      {
        label: 'Ramngarh',
        value: 790,
      },
      {
        label: 'Rampur',
        value: 791,
      },
      {
        label: 'Rampur Maniharan',
        value: 792,
      },
      {
        label: 'Rampura Phul',
        value: 793,
      },
      {
        label: 'Rampurhat',
        value: 794,
      },
      {
        label: 'Ramtek',
        value: 795,
      },
      {
        label: 'Ranaghat',
        value: 796,
      },
      {
        label: 'Ranavav',
        value: 797,
      },
      {
        label: 'Ranebennuru',
        value: 798,
      },
      {
        label: 'Rangia',
        value: 799,
      },
      {
        label: 'Rania',
        value: 800,
      },
      {
        label: 'Ranipet',
        value: 801,
      },
      {
        label: 'Rapar',
        value: 802,
      },
      {
        label: 'Rasipuram',
        value: 803,
      },
      {
        label: 'Rasra',
        value: 804,
      },
      {
        label: 'Ratangarh',
        value: 805,
      },
      {
        label: 'Rath',
        value: 806,
      },
      {
        label: 'Ratia',
        value: 807,
      },
      {
        label: 'Ratlam',
        value: 808,
      },
      {
        label: 'Ratnagiri',
        value: 809,
      },
      {
        label: 'Rau',
        value: 810,
      },
      {
        label: 'Raurkela',
        value: 811,
      },
      {
        label: 'Raver',
        value: 812,
      },
      {
        label: 'Rawatbhata',
        value: 813,
      },
      {
        label: 'Rawatsar',
        value: 814,
      },
      {
        label: 'Raxaul Bazar',
        value: 815,
      },
      {
        label: 'Rayachoti',
        value: 816,
      },
      {
        label: 'Rayadurg',
        value: 817,
      },
      {
        label: 'Rayagada',
        value: 818,
      },
      {
        label: 'Reengus',
        value: 819,
      },
      {
        label: 'Rehli',
        value: 820,
      },
      {
        label: 'Renigunta',
        value: 821,
      },
      {
        label: 'Renukoot',
        value: 822,
      },
      {
        label: 'Reoti',
        value: 823,
      },
      {
        label: 'Repalle',
        value: 824,
      },
      {
        label: 'Revelganj',
        value: 825,
      },
      {
        label: 'Rewa',
        value: 826,
      },
      {
        label: 'Rewari',
        value: 827,
      },
      {
        label: 'Rishikesh',
        value: 828,
      },
      {
        label: 'Risod',
        value: 829,
      },
      {
        label: 'Robertsganj',
        value: 830,
      },
      {
        label: 'Robertson Pet',
        value: 831,
      },
      {
        label: 'Rohtak',
        value: 832,
      },
      {
        label: 'Ron',
        value: 833,
      },
      {
        label: 'Roorkee',
        value: 834,
      },
      {
        label: 'Rosera',
        value: 835,
      },
      {
        label: 'Rudauli',
        value: 836,
      },
      {
        label: 'Rudrapur',
        value: 837,
      },
      {
        label: 'Rupnagar',
        value: 838,
      },
      {
        label: 'Sabalgarh',
        value: 839,
      },
      {
        label: 'Sadabad',
        value: 840,
      },
      {
        label: 'Sadalagi',
        value: 841,
      },
      {
        label: 'Sadasivpet',
        value: 842,
      },
      {
        label: 'Sadri',
        value: 843,
      },
      {
        label: 'Sadulpur',
        value: 844,
      },
      {
        label: 'Sadulshahar',
        value: 845,
      },
      {
        label: 'Safidon',
        value: 846,
      },
      {
        label: 'Safipur',
        value: 847,
      },
      {
        label: 'Sagar',
        value: 848,
      },
      {
        label: 'Sagara',
        value: 849,
      },
      {
        label: 'Sagwara',
        value: 850,
      },
      {
        label: 'Saharanpur',
        value: 851,
      },
      {
        label: 'Saharsa',
        value: 852,
      },
      {
        label: 'Sahaspur',
        value: 853,
      },
      {
        label: 'Sahaswan',
        value: 854,
      },
      {
        label: 'Sahawar',
        value: 855,
      },
      {
        label: 'Sahibganj',
        value: 856,
      },
      {
        label: 'Sahjanwa',
        value: 857,
      },
      {
        label: 'Saidpur',
        value: 858,
      },
      {
        label: 'Saiha',
        value: 859,
      },
      {
        label: 'Sailu',
        value: 860,
      },
      {
        label: 'Sainthia',
        value: 861,
      },
      {
        label: 'Sakaleshapura',
        value: 862,
      },
      {
        label: 'Sakti',
        value: 863,
      },
      {
        label: 'Salaya',
        value: 864,
      },
      {
        label: 'Salem',
        value: 865,
      },
      {
        label: 'Salur',
        value: 866,
      },
      {
        label: 'Samalkha',
        value: 867,
      },
      {
        label: 'Samalkot',
        value: 868,
      },
      {
        label: 'Samana',
        value: 869,
      },
      {
        label: 'Samastipur',
        value: 870,
      },
      {
        label: 'Sambalpur',
        value: 871,
      },
      {
        label: 'Sambhal',
        value: 872,
      },
      {
        label: 'Sambhar',
        value: 873,
      },
      {
        label: 'Samdhan',
        value: 874,
      },
      {
        label: 'Samthar',
        value: 875,
      },
      {
        label: 'Sanand',
        value: 876,
      },
      {
        label: 'Sanawad',
        value: 877,
      },
      {
        label: 'Sanchore',
        value: 878,
      },
      {
        label: 'Sandi',
        value: 879,
      },
      {
        label: 'Sandila',
        value: 880,
      },
      {
        label: 'Sanduru',
        value: 881,
      },
      {
        label: 'Sangamner',
        value: 882,
      },
      {
        label: 'Sangareddy',
        value: 883,
      },
      {
        label: 'Sangaria',
        value: 884,
      },
      {
        label: 'Sangli',
        value: 885,
      },
      {
        label: 'Sangole',
        value: 886,
      },
      {
        label: 'Sangrur',
        value: 887,
      },
      {
        label: 'Sankarankovil',
        value: 888,
      },
      {
        label: 'Sankari',
        value: 889,
      },
      {
        label: 'Sankeshwara',
        value: 890,
      },
      {
        label: 'Santipur',
        value: 891,
      },
      {
        label: 'Sarangpur',
        value: 892,
      },
      {
        label: 'Sardarshahar',
        value: 893,
      },
      {
        label: 'Sardhana',
        value: 894,
      },
      {
        label: 'Sarni',
        value: 895,
      },
      {
        label: 'Sarsod',
        value: 896,
      },
      {
        label: 'Sasaram',
        value: 897,
      },
      {
        label: 'Sasvad',
        value: 898,
      },
      {
        label: 'Satana',
        value: 899,
      },
      {
        label: 'Satara',
        value: 900,
      },
      {
        label: 'Sathyamangalam',
        value: 901,
      },
      {
        label: 'Satna',
        value: 902,
      },
      {
        label: 'Sattenapalle',
        value: 903,
      },
      {
        label: 'Sattur',
        value: 904,
      },
      {
        label: 'Saunda',
        value: 905,
      },
      {
        label: 'Saundatti-Yellamma',
        value: 906,
      },
      {
        label: 'Sausar',
        value: 907,
      },
      {
        label: 'Savanur',
        value: 908,
      },
      {
        label: 'Savarkundla',
        value: 909,
      },
      {
        label: 'Savner',
        value: 910,
      },
      {
        label: 'Sawai Madhopur',
        value: 911,
      },
      {
        label: 'Sawantwadi',
        value: 912,
      },
      {
        label: 'Sedam',
        value: 913,
      },
      {
        label: 'Sehore',
        value: 914,
      },
      {
        label: 'Sendhwa',
        value: 915,
      },
      {
        label: 'Seohara',
        value: 916,
      },
      {
        label: 'Seoni',
        value: 917,
      },
      {
        label: 'Seoni-Malwa',
        value: 918,
      },
      {
        label: 'Shahabad',
        value: 919,
      },
      {
        label: 'Shahabad, Hardoi',
        value: 920,
      },
      {
        label: 'Shahabad, Rampur',
        value: 921,
      },
      {
        label: 'Shahade',
        value: 922,
      },
      {
        label: 'Shahbad',
        value: 923,
      },
      {
        label: 'Shahdol',
        value: 924,
      },
      {
        label: 'Shahganj',
        value: 925,
      },
      {
        label: 'Shahjahanpur',
        value: 926,
      },
      {
        label: 'Shahpur',
        value: 927,
      },
      {
        label: 'Shahpura',
        value: 928,
      },
      {
        label: 'Shajapur',
        value: 929,
      },
      {
        label: 'Shamgarh',
        value: 930,
      },
      {
        label: 'Shamli',
        value: 931,
      },
      {
        label: 'Shamsabad, Agra',
        value: 932,
      },
      {
        label: 'Shamsabad, Farrukhabad',
        value: 933,
      },
      {
        label: 'Shegaon',
        value: 934,
      },
      {
        label: 'Sheikhpura',
        value: 935,
      },
      {
        label: 'Shendurjana',
        value: 936,
      },
      {
        label: 'Shenkottai',
        value: 937,
      },
      {
        label: 'Sheoganj',
        value: 938,
      },
      {
        label: 'Sheohar',
        value: 939,
      },
      {
        label: 'Sheopur',
        value: 940,
      },
      {
        label: 'Sherghati',
        value: 941,
      },
      {
        label: 'Sherkot',
        value: 942,
      },
      {
        label: 'Shiggaon',
        value: 943,
      },
      {
        label: 'Shikaripur',
        value: 944,
      },
      {
        label: 'Shikarpur, Bulandshahr',
        value: 945,
      },
      {
        label: 'Shikohabad',
        value: 946,
      },
      {
        label: 'Shillong',
        value: 947,
      },
      {
        label: 'Shirdi',
        value: 948,
      },
      {
        label: 'Shirpur-Warwade',
        value: 949,
      },
      {
        label: 'Shirur',
        value: 950,
      },
      {
        label: 'Shishgarh',
        value: 951,
      },
      {
        label: 'Shivamogga',
        value: 952,
      },
      {
        label: 'Shivpuri',
        value: 953,
      },
      {
        label: 'Sholavandan',
        value: 954,
      },
      {
        label: 'Sholingur',
        value: 955,
      },
      {
        label: 'Shoranur',
        value: 956,
      },
      {
        label: 'Shrigonda',
        value: 957,
      },
      {
        label: 'Shrirampur',
        value: 958,
      },
      {
        label: 'Shrirangapattana',
        value: 959,
      },
      {
        label: 'Shujalpur',
        value: 960,
      },
      {
        label: 'Siana',
        value: 961,
      },
      {
        label: 'Sibsagar',
        value: 962,
      },
      {
        label: 'Siddipet',
        value: 963,
      },
      {
        label: 'Sidhi',
        value: 964,
      },
      {
        label: 'Sidhpur',
        value: 965,
      },
      {
        label: 'Sidlaghatta',
        value: 966,
      },
      {
        label: 'Sihor',
        value: 967,
      },
      {
        label: 'Sihora',
        value: 968,
      },
      {
        label: 'Sikanderpur',
        value: 969,
      },
      {
        label: 'Sikandra Rao',
        value: 970,
      },
      {
        label: 'Sikandrabad',
        value: 971,
      },
      {
        label: 'Sikar',
        value: 972,
      },
      {
        label: 'Silao',
        value: 973,
      },
      {
        label: 'Silapathar',
        value: 974,
      },
      {
        label: 'Silchar',
        value: 975,
      },
      {
        label: 'Siliguri',
        value: 976,
      },
      {
        label: 'Sillod',
        value: 977,
      },
      {
        label: 'Silvassa',
        value: 978,
      },
      {
        label: 'Simdega',
        value: 979,
      },
      {
        label: 'Sindagi',
        value: 980,
      },
      {
        label: 'Sindhagi',
        value: 981,
      },
      {
        label: 'Sindhnur',
        value: 982,
      },
      {
        label: 'Singrauli',
        value: 983,
      },
      {
        label: 'Sinnar',
        value: 984,
      },
      {
        label: 'Sira',
        value: 985,
      },
      {
        label: 'Sircilla',
        value: 986,
      },
      {
        label: 'Sirhind Fatehgarh Sahib',
        value: 987,
      },
      {
        label: 'Sirkali',
        value: 988,
      },
      {
        label: 'Sirohi',
        value: 989,
      },
      {
        label: 'Sironj',
        value: 990,
      },
      {
        label: 'Sirsa',
        value: 991,
      },
      {
        label: 'Sirsaganj',
        value: 992,
      },
      {
        label: 'Sirsi',
        value: 993,
      },
      {
        label: 'Siruguppa',
        value: 994,
      },
      {
        label: 'Sitamarhi',
        value: 995,
      },
      {
        label: 'Sitapur',
        value: 996,
      },
      {
        label: 'Sitarganj',
        value: 997,
      },
      {
        label: 'Sivaganga',
        value: 998,
      },
      {
        label: 'Sivagiri',
        value: 999,
      },
      {
        label: 'Sivakasi',
        value: 1000,
      },
      {
        label: 'Siwan',
        value: 1001,
      },
      {
        label: 'Sohagpur',
        value: 1002,
      },
      {
        label: 'Sohna',
        value: 1003,
      },
      {
        label: 'Sojat',
        value: 1004,
      },
      {
        label: 'Solan',
        value: 1005,
      },
      {
        label: 'Solapur',
        value: 1006,
      },
      {
        label: 'Sonamukhi',
        value: 1007,
      },
      {
        label: 'Sonepur',
        value: 1008,
      },
      {
        label: 'Songadh',
        value: 1009,
      },
      {
        label: 'Sonipat',
        value: 1010,
      },
      {
        label: 'Sopore',
        value: 1011,
      },
      {
        label: 'Soro',
        value: 1012,
      },
      {
        label: 'Soron',
        value: 1013,
      },
      {
        label: 'Soyagaon',
        value: 1014,
      },
      {
        label: 'Sri Madhopur',
        value: 1015,
      },
      {
        label: 'Srikakulam',
        value: 1016,
      },
      {
        label: 'Srikalahasti',
        value: 1017,
      },
      {
        label: 'Srinagar',
        value: 1018,
      },
      {
        label: 'Srinivaspur',
        value: 1019,
      },
      {
        label: 'Srirampore',
        value: 1020,
      },
      {
        label: 'Srisailam Project (Right Flank Colony) Township',
        value: 1021,
      },
      {
        label: 'Srivilliputhur',
        value: 1022,
      },
      {
        label: 'Sugauli',
        value: 1023,
      },
      {
        label: 'Sujangarh',
        value: 1024,
      },
      {
        label: 'Sujanpur',
        value: 1025,
      },
      {
        label: 'Sullurpeta',
        value: 1026,
      },
      {
        label: 'Sultanganj',
        value: 1027,
      },
      {
        label: 'Sultanpur',
        value: 1028,
      },
      {
        label: 'Sumerpur',
        value: 1029,
      },
      {
        label: 'Sunabeda',
        value: 1030,
      },
      {
        label: 'Sunam',
        value: 1031,
      },
      {
        label: 'Sundargarh',
        value: 1032,
      },
      {
        label: 'Sundarnagar',
        value: 1033,
      },
      {
        label: 'Supaul',
        value: 1034,
      },
      {
        label: 'Surandai',
        value: 1035,
      },
      {
        label: 'Surapura',
        value: 1036,
      },
      {
        label: 'Suratgarh',
        value: 1037,
      },
      {
        label: 'Surban Agglomerationr',
        value: 1038,
      },
      {
        label: 'Suri',
        value: 1039,
      },
      {
        label: 'Suriyampalayam',
        value: 1040,
      },
      {
        label: 'Suryapet',
        value: 1041,
      },
      {
        label: 'Tadepalligudem',
        value: 1042,
      },
      {
        label: 'Tadpatri',
        value: 1043,
      },
      {
        label: 'Takhatgarh',
        value: 1044,
      },
      {
        label: 'Taki',
        value: 1045,
      },
      {
        label: 'Talaja',
        value: 1046,
      },
      {
        label: 'Talcher',
        value: 1047,
      },
      {
        label: 'Talegaon Dabhade',
        value: 1048,
      },
      {
        label: 'Talikota',
        value: 1049,
      },
      {
        label: 'Taliparamba',
        value: 1050,
      },
      {
        label: 'Talode',
        value: 1051,
      },
      {
        label: 'Talwara',
        value: 1052,
      },
      {
        label: 'Tamluk',
        value: 1053,
      },
      {
        label: 'Tanda',
        value: 1054,
      },
      {
        label: 'Tandur',
        value: 1055,
      },
      {
        label: 'Tanuku',
        value: 1056,
      },
      {
        label: 'Tarakeswar',
        value: 1057,
      },
      {
        label: 'Tarana',
        value: 1058,
      },
      {
        label: 'Taranagar',
        value: 1059,
      },
      {
        label: 'Taraori',
        value: 1060,
      },
      {
        label: 'Tarbha',
        value: 1061,
      },
      {
        label: 'Tarikere',
        value: 1062,
      },
      {
        label: 'Tarn Taran',
        value: 1063,
      },
      {
        label: 'Tasgaon',
        value: 1064,
      },
      {
        label: 'Tehri',
        value: 1065,
      },
      {
        label: 'Tekkalakote',
        value: 1066,
      },
      {
        label: 'Tenali',
        value: 1067,
      },
      {
        label: 'Tenkasi',
        value: 1068,
      },
      {
        label: 'Tenu dam-cum-Kathhara',
        value: 1069,
      },
      {
        label: 'Terdal',
        value: 1070,
      },
      {
        label: 'Tezpur',
        value: 1071,
      },
      {
        label: 'Thakurdwara',
        value: 1072,
      },
      {
        label: 'Thammampatti',
        value: 1073,
      },
      {
        label: 'Thana Bhawan',
        value: 1074,
      },
      {
        label: 'Thane',
        value: 1075,
      },
      {
        label: 'Thanesar',
        value: 1076,
      },
      {
        label: 'Thangadh',
        value: 1077,
      },
      {
        label: 'Tharad',
        value: 1078,
      },
      {
        label: 'Tharamangalam',
        value: 1079,
      },
      {
        label: 'Tharangambadi',
        value: 1080,
      },
      {
        label: 'Theni Allinagaram',
        value: 1081,
      },
      {
        label: 'Thirumangalam',
        value: 1082,
      },
      {
        label: 'Thirupuvanam',
        value: 1083,
      },
      {
        label: 'Thiruthuraipoondi',
        value: 1084,
      },
      {
        label: 'Thiruvalla',
        value: 1085,
      },
      {
        label: 'Thiruvallur',
        value: 1086,
      },
      {
        label: 'Thiruvananthapuram',
        value: 1087,
      },
      {
        label: 'Thiruvarur',
        value: 1088,
      },
      {
        label: 'Thodupuzha',
        value: 1089,
      },
      {
        label: 'Thoubal',
        value: 1090,
      },
      {
        label: 'Thuraiyur',
        value: 1091,
      },
      {
        label: 'Tikamgarh',
        value: 1092,
      },
      {
        label: 'Tilda Newra',
        value: 1093,
      },
      {
        label: 'Tilhar',
        value: 1094,
      },
      {
        label: 'Tindivanam',
        value: 1095,
      },
      {
        label: 'Tinsukia',
        value: 1096,
      },
      {
        label: 'Tiptur',
        value: 1097,
      },
      {
        label: 'Tirora',
        value: 1098,
      },
      {
        label: 'Tiruchendur',
        value: 1099,
      },
      {
        label: 'Tiruchengode',
        value: 1100,
      },
      {
        label: 'Tirukalukundram',
        value: 1101,
      },
      {
        label: 'Tirukkoyilur',
        value: 1102,
      },
      {
        label: 'Tirunelveli',
        value: 1103,
      },
      {
        label: 'Tirupathur',
        value: 1104,
      },
      {
        label: 'Tirupati',
        value: 1105,
      },
      {
        label: 'Tiruppur',
        value: 1106,
      },
      {
        label: 'Tirur',
        value: 1107,
      },
      {
        label: 'Tiruttani',
        value: 1108,
      },
      {
        label: 'Tiruvannamalai',
        value: 1109,
      },
      {
        label: 'Tiruvethipuram',
        value: 1110,
      },
      {
        label: 'Tiruvuru',
        value: 1111,
      },
      {
        label: 'Tirwaganj',
        value: 1112,
      },
      {
        label: 'Titlagarh',
        value: 1113,
      },
      {
        label: 'Tittakudi',
        value: 1114,
      },
      {
        label: 'Todabhim',
        value: 1115,
      },
      {
        label: 'Todaraisingh',
        value: 1116,
      },
      {
        label: 'Tohana',
        value: 1117,
      },
      {
        label: 'Tonk',
        value: 1118,
      },
      {
        label: 'Tuensang',
        value: 1119,
      },
      {
        label: 'Tuljapur',
        value: 1120,
      },
      {
        label: 'Tulsipur',
        value: 1121,
      },
      {
        label: 'Tumkur',
        value: 1122,
      },
      {
        label: 'Tumsar',
        value: 1123,
      },
      {
        label: 'Tundla',
        value: 1124,
      },
      {
        label: 'Tuni',
        value: 1125,
      },
      {
        label: 'Tura',
        value: 1126,
      },
      {
        label: 'Uchgaon',
        value: 1127,
      },
      {
        label: 'Udaipurwati',
        value: 1128,
      },
      {
        label: 'Udgir',
        value: 1129,
      },
      {
        label: 'Udhagamandalam',
        value: 1130,
      },
      {
        label: 'Udhampur',
        value: 1131,
      },
      {
        label: 'Udumalaipettai',
        value: 1132,
      },
      {
        label: 'Udupi',
        value: 1133,
      },
      {
        label: 'Ujhani',
        value: 1134,
      },
      {
        label: 'Ujjain',
        value: 1135,
      },
      {
        label: 'Umarga',
        value: 1136,
      },
      {
        label: 'Umaria',
        value: 1137,
      },
      {
        label: 'Umarkhed',
        value: 1138,
      },
      {
        label: 'Umbergaon',
        value: 1139,
      },
      {
        label: 'Umred',
        value: 1140,
      },
      {
        label: 'Umreth',
        value: 1141,
      },
      {
        label: 'Una',
        value: 1142,
      },
      {
        label: 'Unjha',
        value: 1143,
      },
      {
        label: 'Unnamalaikadai',
        value: 1144,
      },
      {
        label: 'Unnao',
        value: 1145,
      },
      {
        label: 'Upleta',
        value: 1146,
      },
      {
        label: 'Uran',
        value: 1147,
      },
      {
        label: 'Uran Islampur',
        value: 1148,
      },
      {
        label: 'Uravakonda',
        value: 1149,
      },
      {
        label: 'Urmar Tanda',
        value: 1150,
      },
      {
        label: 'Usilampatti',
        value: 1151,
      },
      {
        label: 'Uthamapalayam',
        value: 1152,
      },
      {
        label: 'Uthiramerur',
        value: 1153,
      },
      {
        label: 'Utraula',
        value: 1154,
      },
      {
        label: 'Vadakkuvalliyur',
        value: 1155,
      },
      {
        label: 'Vadalur',
        value: 1156,
      },
      {
        label: 'Vadgaon Kasba',
        value: 1157,
      },
      {
        label: 'Vadipatti',
        value: 1158,
      },
      {
        label: 'Vadnagar',
        value: 1159,
      },
      {
        label: 'Vadodara',
        value: 1160,
      },
      {
        label: 'Vaijapur',
        value: 1161,
      },
      {
        label: 'Vaikom',
        value: 1162,
      },
      {
        label: 'Valparai',
        value: 1163,
      },
      {
        label: 'Valsad',
        value: 1164,
      },
      {
        label: 'Vandavasi',
        value: 1165,
      },
      {
        label: 'Vaniyambadi',
        value: 1166,
      },
      {
        label: 'Vapi',
        value: 1167,
      },
      {
        label: 'Varkala',
        value: 1168,
      },
      {
        label: 'Vasai-Virar',
        value: 1169,
      },
      {
        label: 'Vatakara',
        value: 1170,
      },
      {
        label: 'Vedaranyam',
        value: 1171,
      },
      {
        label: 'Vellakoil',
        value: 1172,
      },
      {
        label: 'Vellore',
        value: 1173,
      },
      {
        label: 'Venkatagiri',
        value: 1174,
      },
      {
        label: 'Veraval',
        value: 1175,
      },
      {
        label: 'Vidisha',
        value: 1176,
      },
      {
        label: 'Vijainagar, Ajmer',
        value: 1177,
      },
      {
        label: 'Vijapur',
        value: 1178,
      },
      {
        label: 'Vijayapura',
        value: 1179,
      },
      {
        label: 'Vijaypur',
        value: 1180,
      },
      {
        label: 'Vikarabad',
        value: 1181,
      },
      {
        label: 'Vikramasingapuram',
        value: 1182,
      },
      {
        label: 'Viluppuram',
        value: 1183,
      },
      {
        label: 'Vinukonda',
        value: 1184,
      },
      {
        label: 'Viramgam',
        value: 1185,
      },
      {
        label: 'Virudhachalam',
        value: 1186,
      },
      {
        label: 'Virudhunagar',
        value: 1187,
      },
      {
        label: 'Visakhapatnam',
        value: 1188,
      },
      {
        label: 'Visnagar',
        value: 1189,
      },
      {
        label: 'Viswanatham',
        value: 1190,
      },
      {
        label: 'Vita',
        value: 1191,
      },
      {
        label: 'Vizianagaram',
        value: 1192,
      },
      {
        label: 'Vrindavan',
        value: 1193,
      },
      {
        label: 'Vyara',
        value: 1194,
      },
      {
        label: 'Wadgaon Road',
        value: 1195,
      },
      {
        label: 'Wadhwan',
        value: 1196,
      },
      {
        label: 'Wadi',
        value: 1197,
      },
      {
        label: 'Wai',
        value: 1198,
      },
      {
        label: 'Wanaparthy',
        value: 1199,
      },
      {
        label: 'Wani',
        value: 1200,
      },
      {
        label: 'Wankaner',
        value: 1201,
      },
      {
        label: 'Wara Seoni',
        value: 1202,
      },
      {
        label: 'Warangal',
        value: 1203,
      },
      {
        label: 'Wardha',
        value: 1204,
      },
      {
        label: 'Warhapur',
        value: 1205,
      },
      {
        label: 'Warisaliganj',
        value: 1206,
      },
      {
        label: 'Warora',
        value: 1207,
      },
      {
        label: 'Warud',
        value: 1208,
      },
      {
        label: 'Washim',
        value: 1209,
      },
      {
        label: 'Wokha',
        value: 1210,
      },
      {
        label: 'Yadgir',
        value: 1211,
      },
      {
        label: 'Yamunanagar',
        value: 1212,
      },
      {
        label: 'Yanam',
        value: 1213,
      },
      {
        label: 'Yavatmal',
        value: 1214,
      },
      {
        label: 'Yawal',
        value: 1215,
      },
      {
        label: 'Yellandu',
        value: 1216,
      },
      {
        label: 'Yerraguntla',
        value: 1217,
      },
      {
        label: 'Yemmiganur',
        value: 1218,
      },
      {
        label: 'Yevla',
        value: 1219,
      },
      {
        label: 'Zaidpur',
        value: 1220,
      },
      {
        label: 'Zamania',
        value: 1221,
      },
      {
        label: 'Zira',
        value: 1222,
      },
      {
        label: 'Zirakpur',
        value: 1223,
      },
      {
        label: 'Zunheboto',
        value: 1224,
      },
    ],
  },
]
