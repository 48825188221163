/* eslint-disable jsx-a11y/anchor-is-valid */
import BlueTitleWithDesc from 'components/BlueTitleWithDesc'
import React, { memo } from 'react'

const SkillsAndTools = memo(({ skillAndTools }) => {
  return (
    <section>
      <div>
      {(skillAndTools?.title || skillAndTools?.longDescription) && 
          <div className='mt-5'>
            <BlueTitleWithDesc
              info={skillAndTools}
              classAddOn="menu-title-pg-diploma-analytics"
              classAddDescOn="counrse-contn-pg-diploma-analytics"
            />
          </div>
        }
        <div className='container'>
          {skillAndTools?.descWithBg && (
            <div className="mb-4">
              {skillAndTools.descWithBg.map((item, index) => {
                return (
                  <div
                  key={`skillAndTools-${index}`}
                    className={`skills-tools pg-lg-center${
                      index < skillAndTools.descWithBg.length - 1 ? 'blue-bm' : ''
                    }`}
                  >
                    {item.label}
                  </div>
                )
              })}
            </div>
          )}
          {skillAndTools?.LastDescription && (
            <div
              className="counrse-contn-pg-diploma-analytics mb-5"
              dangerouslySetInnerHTML={{
                __html: skillAndTools.LastDescription,
              }}
            ></div>
          )}
        </div>  
      </div>
    </section>
  )
})
export default SkillsAndTools
