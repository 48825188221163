import { useEffect, useReducer } from 'react'
import constate from 'constate'
import logger from './Logger'
import useLocalStorage from '../shared/useLocalStorage'

export const initialState = {
  token: '',
  isAuthenticated: null,
  enlistHide: false,
  user: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: { ...state.user, ...action.payload.user },
        token: action.data.token,
        isAuthenticated: true,
      }

    case 'LOGOUT_SUCCESSFUL':
      return {
        ...initialState,
        enlistHide: state.enlistHide,
      }

    case 'ENLISTHIDE':
      return { ...state, enlistHide: action.data.enlistHide }

    default:
      return state
  }
}

const loggerReducer = logger(reducer)

const useUser = () => {
  const [data, setData] = useLocalStorage('user', initialState)
  const [state, dispatch] = useReducer(loggerReducer, data)

  useEffect(() => {
    setData(state)
  }, [state, setData])

  const { user, token, isAuthenticated, enlistHide } = state

  const setUser = (user, token) => {
    dispatch({
      type: 'SET_USER',
      payload: { user },
      data: { token },
    })
  }

  const logoutSuccess = () => {
    dispatch({
      type: 'LOGOUT_SUCCESSFUL',
    })
  }

  const setEnlistHide = (enlistHide) => {
    dispatch({
      type: 'ENLISTHIDE',
      data: { enlistHide },
    })
  }

  return {
    // state
    user,
    token,
    isAuthenticated,
    enlistHide,
    // methods
    setUser,
    logoutSuccess,
    setEnlistHide,
  }
}

export const [UserProvider, useUserContext] = constate(useUser)
