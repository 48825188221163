/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, memo } from 'react'
import useApi from 'shared/api'
import moment from 'moment'
import { getImage } from 'shared/utills'
import { useRouteMatch } from 'react-router-dom'
import search from '../../assets/images/saerch.png'
import icon11 from '../../assets/images/icon-11.png'
import icon12 from '../../assets/images/icon-12.png'
import arrowLeft from '../../assets/images/svgs/arrow-left.png'
import './searchbar.css'

const SearchBar = memo(({ data, isserachbar, backSearchBar }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const cssClassInitial = {
    searchResult: 'inactive',
    notFound: 'inactive',
    closeBtn: 'inactive',
    charLimit: 'active',
  }
  const [getCssClass, setCssClass] = useState(cssClassInitial)
  const { API } = useApi()
  let isLgPage =false
  const route = useRouteMatch()
  if(route.path === '/courses/lg'){
    isLgPage = true
  }
  const handleChange = async (e) => {
    setSearchTerm(e.target.value)
    if (e?.target?.value && e.target.value.length > 2) {
      try {
        const response = await API.searchCourse.get({
          url: `searchcourse?search=${e.target.value}`,
        })
        if (response?.data?.course) {
          setSearchResults(response.data.course)
          if (
            response?.data?.course?.rows &&
            response.data.course.rows.length > 0
          ) {
            setCssClass({
              ...cssClassInitial,
              searchResult: 'active',
              charLimit: 'inactive',
            })
          } else {
            setCssClass({
              searchResult: 'inactive',
              notFound: 'active',
              closeBtn: 'inactive',
              charLimit: 'inactive',
            })
          }
        }
      } catch (error) {}
    } else {
      setCssClass({
        searchResult: 'inactive',
        notFound: 'inactive',
        closeBtn: 'inactive',
        charLimit: 'active',
      })
    }
  }
  return (
    <div className="new-mn searchbarCource">
      <div className={'menu-overlay ' + isserachbar} id="menu-overlay1">
        <div className="menu">
          <section className={'menu-list ' + isserachbar}>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="search-bar " style={{ marginTop: 37 }}>
                    <div className="sear-arrow open-arrow">
                      <a
                        href="javascript:void(0);"
                        className=" hamburger-menu"
                        onClick={backSearchBar}
                      >
                        <img
                          src={arrowLeft}
                          width="40"
                          style={{ marginTop: -14 }}
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="search-bar-innr open-arrow-inpt">
                      <input
                        type="text"
                        placeholder="Enter course name."
                        value={searchTerm}
                        onChange={handleChange}
                      />
                      <button>
                        <img src={search} alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row find_records">
                <div className="col-md-12 p-0">
                  <div className="col-md-12">
                    <div
                      className={'multi_records ' + getCssClass.searchResult}
                    >
                      <div className="row">
                        {searchResults &&
                          searchResults.rows &&
                          searchResults.rows.map((itemCource) => {
                            let learningModes = ''
                            if (itemCource?.learningModes) {
                              itemCource.learningModes.forEach((lmode) => {
                                if (learningModes && learningModes.length > 0) {
                                  learningModes = learningModes + ' | '
                                }
                                if (lmode.learningModeID === 1) {
                                  learningModes = learningModes + 'Classroom'
                                } else if (lmode.learningModeID === 2) {
                                  learningModes = learningModes + 'Online'
                                } else if (lmode.learningModeID === 3) {
                                  learningModes = learningModes + 'eLearning'
                                }
                              })
                            }
                            return (
                              <div
                                className="p-3"
                                key={`searchcourse_${itemCource.courseID}`}
                              >
                                <a
                                  className="item crc-incld-new bg-white mx-auto"
                                  style={{ minWidth: '250px' }}
                                  href={isLgPage ? `/${itemCource?.slug}/lg` : `/${itemCource?.slug}`}
                                >
                                  <div
                                    className="crc-incld-img data-science-r-small"
                                    style={{
                                      backgroundImage: `url(${getImage(
                                        itemCource.bannerFileName,
                                      )})`,
                                    }}
                                  >
                                    {' '}
                                    {itemCource?.CourseTags &&
                                      itemCource.CourseTags.length > 0 &&
                                      itemCource.CourseTags.map((tag) => (
                                        <span className="green-ng smal-greenbg">
                                          {tag?.Tag?.name}
                                        </span>
                                      ))}
                                    <img
                                      className=" ls-is-cached lazyloaded"
                                      alt={itemCource.name}
                                      style={{ maxWidth: 100, minWidth: 100 }}
                                      src={getImage(itemCource.robotImage)}
                                    />
                                  </div>
                                  <h2
                                    style={{
                                      minHeight: '60px',
                                      maxWidth: '150px',
                                    }}
                                  >
                                    {itemCource.name}
                                  </h2>
                                  <div className="timing-blk">
                                    <ul className="timing">
                                      <li>
                                        <img
                                          className=" ls-is-cached lazyloaded"
                                          src={icon11}
                                          alt=""
                                        />{' '}
                                        <span>
                                          <b>{itemCource.hoursCount + ' '}</b>{' '}
                                          hours
                                        </span>{' '}
                                      </li>
                                      <li>
                                        <img
                                          className=" ls-is-cached lazyloaded"
                                          src={icon12}
                                          alt=""
                                        />{' '}
                                        <span>
                                          <b>{itemCource.classCount + ' '}</b>{' '}
                                          Classes
                                        </span>{' '}
                                      </li>
                                    </ul>
                                    <ul className="pt-4 pb-4 text-left">
                                      {itemCource.upcomingBatch ? (
                                        <li className="drc-blcr">
                                          Next Batch :{' '}
                                          {moment(
                                            itemCource.upcomingBatch.startDate,
                                          ).format('Do MMMM, YYYY')}
                                        </li>
                                      ) : (
                                        <li className="drc-blcr">
                                          Next Batch : Not Found
                                        </li>
                                      )}
                                      <li className="blcr">
                                        {learningModes ||
                                          'Learning Mode Not Found'}
                                      </li>
                                    </ul>
                                    <div className="dnld-btn">
                                      <a
                                        href={isLgPage ? `/${itemCource?.slug}/lg` : `/${itemCource?.slug}`}
                                        style={{
                                          marginBottom: '20px',
                                          padding: '5px 20px',
                                        }}
                                      >
                                        {' '}
                                        View More{' '}
                                      </a>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            )
                          })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className={'zero_records ' + getCssClass.notFound}>
                    <div className="row mt-5">
                      <h1 className="font-48 col-md-12 pt-4 pb-1">
                        <span className="yl-botbod">No Records</span> Found
                      </h1>
                      <p className="eax-2-p mb-3 text-center w-100">
                        But don't be disheartened, you can still explore further
                      </p>
                      <div className="col-md-12">
                        <a
                          href="#expand-block"
                          className="hamburger-menu hamburger-menu-apn totl-buton text-white px-4"
                        >
                          Explore
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className={'zero_records ' + getCssClass.charLimit}>
                    <div className="row mt-5">
                      <p className="eax-2-p mb-3 text-center w-100">
                        Minimum 3 character required to search.
                      </p>
                      <div className="col-md-12">
                        <a
                          href={
                            window.location.href.includes('courses')
                              ? '#expand-block'
                              : 'courses'
                          }
                          className="hamburger-menu hamburger-menu-apn totl-buton text-white px-4"
                        >
                          Explore
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 close-btn mb-5 inactive">
                  <div className="sear-arrow open-arrow">
                    <button className="hamburger-menu hamburger-menu-apn">
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
})
export default SearchBar
