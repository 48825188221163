/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from 'react'
import { getImage } from 'shared/utills'
import { useStudentContext } from '../../context/StudentContext'

import linkedin from '../../assets/images/linkdin.png'

const StudentReviewCard = memo(({ review, single }) => {
  const { setStudent } = useStudentContext()
  return (
    <div className="item about-blk about-blk-2">
      <div
        className={`about-innerblk ${
          single ? 'single_slide shadow bg-white' : ''
        }`}
      >
        {review.tags && review.tags.length > 0 ? (
          review.tags.map((tag, i) => (
            <span key={i} className="green-ng smal-greenbg2">
              {tag?.Tag?.name}
            </span>
          ))
        ) : (
          <span className="green-ng smal-greenbg2">TAG</span>
        )}
        {single ? (
          <span className="single_slide_title">
            <h4>{review.name}</h4>
            <h5>{review.designtions}</h5>
          </span>
        ) : null}
        <div className="rating">
          {!single ? (
            <ul className="start-rtg">
              <li className="start-tr">
                <span className="rat-count">{review.rating}</span>
              </li>
            </ul>
          ) : null}
          <div className="std-img" style={{ height: '89px' }}>
            <img
              alt=""
              className=" ls-is-cached lazyloaded"
              src={getImage(review.profileImage)}
              height={89}
              width={89}
            />{' '}
            {!single ? (
              <a href="javascript:void(0);" aria-label="companyImage">
                <img
                  alt=""
                  className=" ls-is-cached lazyloaded"
                  src={getImage(review.companyImage)}
                />
              </a>
            ) : null}
          </div>
          <div className="std-detail">
            {!single ? (
              <h4 className="StudentCard_review-name">{review.name}</h4>
            ) : null}
            {!single ? (
              <h5
                className="StudentCard_review-designtion"
              >
                {review.designtions}
              </h5>
            ) : null}
            <p className={single ? 'mt-3' : null}>
              {review.decription.toString().substring(0, 90)}
            </p>
            {/* <a href={review.link}> */}
            <a
              data-toggle="modal"
              data-target="#readmore"
              href="javascript:void(0);"
              onClick={() => setStudent(review)}
            >
              Read More...
            </a>
          </div>
          <div className="linkd">
            <a
              href={
                review.linkdin
                  ? review.linkdin.includes('https://')
                    ? review.linkdin
                    : `https://in.${review.linkdin}`
                  : review.linkdin
              }
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src={linkedin}
                className="lnk-img ls-is-cached lazyloaded"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
})
export default StudentReviewCard
