import { useEffect, useReducer } from 'react'
import constate from 'constate'
import logger from './Logger'
import useLocalStorage from '../shared/useLocalStorage'

export const initialState = {
  data: undefined,
}

const reducer = (state, action) => {
  console.log('action.payload', action.type)
  switch (action.type) {
    case 'SET_DATA':
      return {
        ...state,
        data: action.payload,
      }

    case 'RESET_DATA':
      return {
        ...initialState,
      }

    default:
      return state
  }
}

const loggerReducer = logger(reducer)

const useThankyouData = () => {
  const [data, setData] = useLocalStorage('thankyou', initialState)
  const [state, dispatch] = useReducer(loggerReducer, data)

  useEffect(() => {
    setData(state)
  }, [state, setData])

  const setThankyouData = (thankyouData) => {
    dispatch({
      type: 'SET_DATA',
      payload: thankyouData,
    })
  }

  const resetThankyouData = () => {
    dispatch({
      type: 'RESET_DATA',
    })
  }

  return {
    // state
    data,
    // methods
    setThankyouData,
    resetThankyouData,
  }
}

export const [ThankyouProvider, useThankyouContext] = constate(useThankyouData)
