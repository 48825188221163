import React, { createContext, useState } from 'react';

export const CourseContext = createContext();

export const CourseProvider = ({ children }) => {
  const [courseDetailsID, setCourseDetailsID] = useState(null);

  return (
    <CourseContext.Provider value={{ courseDetailsID, setCourseDetailsID }}>
      {children}
    </CourseContext.Provider>
  );
};
