import React from 'react'

const BulletPointTitle = ({title, description}) => {
  return (
    <div className='container mt-4'>
        {(title) && 
          <div className="counrse-contn-pg-diploma-analytics mt-4 mb-3 pg-placement-bold">
            {title}
          </div>}
        {(description) &&  
          <div  className="counrse-contn-pg-diploma-analytics mb-2"
          dangerouslySetInnerHTML={{
            __html: description,
          }}></div>}
      </div>
  )
}

export default BulletPointTitle