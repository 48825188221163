import React, { memo } from 'react'
// import { Nitrate } from '@n7-io/nitrate-react'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import '../../assets/css/search-wind.css'
import PayAfterPlacementDetail from './PayAfterPlacementDetail'

const BlogSection = React.lazy(() => import('../home/BlogSection'))

const PayAfterPlacement = memo(() => {
  let Options = {
    loop: true,
    margin: 10,
    nav: true,
    autoplayTimeout: 2000,
    autoplaySpeed: 2000,
    responsive: {
      0: {
        items: 1,
        nav: true,
      },
      600: {
        items: 2,
        nav: false,
      },
      1000: {
        items: 3,
        nav: true,
        loop: false,
      },
    },
  }
  return (
    <div>
      <PayAfterPlacementDetail />
      <BlogSection Options={Options} nitrateRef="placementExtras" />
    </div>
  )
})
export default PayAfterPlacement
