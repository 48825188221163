import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.js'

import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from 'serviceWorker'
import TagManager from 'react-gtm-module'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { BASE_URL, heapCode, server } from 'shared/settings'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import App from './app/App'
Sentry.init({
  dsn:
    'https://3eda67083be647a7906687dade98253b@o927437.ingest.sentry.io/5892639',
  integrations: [new Integrations.BrowserTracing()],
  environment:
    BASE_URL === 'https://dev.analytixlabs.co.in/api'
      ? 'Staging'
      : BASE_URL === 'https://www.analytixlabs.co.in/api'
      ? 'production'
      : 'Other',
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})
if (localStorage.getItem('user')) {
  let user = localStorage.getItem('user')
  user = JSON.parse(user)
  Sentry.configureScope((scope) => scope.setUser(user.user))
}
const tagManagerArgs = [
  {
    gtmId: 'G-S8DQVCX660',
  },
  {
    gtmId: 'GTM-TKCZNMD',
  },
]
tagManagerArgs.map((item) => TagManager.initialize(item))
window.heap.load(`${heapCode}`)
ReactDOM.render(
  <React.StrictMode enabled={false}>
    <HelmetProvider>
      {server === 'dev' && (
        <Helmet>
          <script type="text/javascript">pidTracker('60113');</script>
        </Helmet>
      )}
      <App />
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
