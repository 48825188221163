/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from 'react'

const CareerGraph = memo(({ info }) => {
  return (
    <section>
      <div className="container">
        <div className="career-graph-main">
          <div className="career-graph-title mb-2">
            <h3>{info.title}</h3>
          </div>
          <div className="career-graph-list-outer mt-2">
            {info.careerList.map((item) => {
              return (
                <div className="career-graph-list-inner">
                  <div className="career-graph-list-image-outer">
                    <img
                      src={item.imageUrl}
                      alt=""
                      className=" ls-is-cached lazyloaded mt-1"
                      style={{ width: 75, height: 75 }}
                    />
                  </div>
                  <div className="career-graph-list-info-outer">
                    <div className="career-info-above-text">
                      {item.upperContent}
                    </div>
                    <div className="career-info-below-text">
                      {' '}
                      {item.lowerContent}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
})
export default CareerGraph
