/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, memo, useContext } from 'react'
import moment from 'moment'
import hubspot from '../../assets/images/footer/hubspotchat.png'
import call from '../../assets/images/footer/call.png'
import whatsapp from '../../assets/images/footer/whatsapp.png'
import { useModalTypeContext } from 'context/modalTypeContext'
import { onCallUsClickPPC, onWhatsappClickPPC } from '../../shared/function'
import { Helmet } from 'react-helmet-async'
// import { nitrateStore } from '@n7-io/nitrate-react'
import './HSChat.scss'
import './footer.css'
import ContactNumber from 'data/ppcData'
import { getHeaderAndFooter } from '../../shared/utills'
import { GeneralContext } from '../../context/GeneralContext'

function BottomFooter() {
  const { setModalType } = useModalTypeContext()
  const { generalData, setGeneralState } = useContext(GeneralContext)
    let footer = generalData?.footer
  // const loadHubSpot = () => {
  //   const d = document
  //   const s = d.createElement('script')

  //   s.src = 'https://js.hs-scripts.com/5081260.js'
  //   s.async = true
  //   d.getElementsByTagName('body')[0].appendChild(s)
  // }
  // useEffect(() => {
  //   if (window?.innerWidth && window.innerWidth > 767) {
  //     setTimeout(() => {
  //       loadHubSpot()
  //     }, 5000)
  //   }
  // }, [])

  const endTime = footer?.callUs?.end_time
  const startTime = footer?.callUs?.start_time
  const isWorkingHourse =
    moment().isBefore(moment(endTime, 'HH:mm:ss')) &&
    moment().isAfter(moment(startTime, 'HH:mm:ss'))

  const [isbottomfooter, setIsBottomFooter] = useState(0)
  const scrollTop = 0

  useEffect(() => {
    const onScroll = (e) => {
      setIsBottomFooter(e.target.documentElement.scrollTop > 300 ? 1 : 0)
    }
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollTop])

  async function callFooter() {
    const params = await getHeaderAndFooter()
    setGeneralState('footer', params)
  }
  useEffect(() => {
    // const exploreFooterFooterRecord = nitrateStore('exploreFooterFooterRecord')
    // if (exploreFooterFooterRecord) {
    //   const transformedData = {
    //     headerRecord: {
    //       announcement: exploreFooterFooterRecord.headerRecord.announcement,
    //     },
    //     footerRecord: {
    //       branches: exploreFooterFooterRecord.footerRecord.branches,
    //       callUs: exploreFooterFooterRecord.footerRecord.call_us,
    //       weAreSocial: exploreFooterFooterRecord.footerRecord.we_are_social,
    //       support: exploreFooterFooterRecord.footerRecord.support,
    //     },
    //   }
    //   setGeneralState('footer', transformedData)
    // } else
     callFooter()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return window?.innerWidth && window.innerWidth <= 767 ? (
    <div
      className="mobile-menu show bottombar_fix"
      id="footer_fix"
      style={{ opacity: isbottomfooter, padding: 0 }}
    >
      <Helmet>
        {/* <style>
          {`
            @media screen and (max-width: 600px) {
              #hubspot-messages-iframe-container {
                display:none !impportant;
                visibility: hidden;
              }
            }
          `}
        </style> */}
        {/* <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-783236209/SLOGCIDx950BEPH4vPUC"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
          
            gtag('config', "AW-783236209/SLOGCIDx950BEPH4vPUC");
          `} 
        </script> */}
      </Helmet>
      <div className="d-flex justify-content-between">
        <div className="topbar courses">
          <a
            href={
              isWorkingHourse
                ? `tel:${
                    footer?.footerRecord?.callUs?.country_code ||
                    footer?.callUs?.country_code
                  }${ContactNumber.ppcContactNumber}`
                : 'javascript:void(0);'
            }
            data-toggle={isWorkingHourse ? null : 'modal'}
            data-target={isWorkingHourse ? null : '#contactus_form'}
            className={isWorkingHourse ? null : 'ctn-active'}
            onClick={() =>
              isWorkingHourse
                ? onCallUsClickPPC()
                : setModalType('ppcrequestCallback')
            }
          >
            <img
              className="lazyload mr-1"
              src={call}
              width="15"
              height="15"
              alt=""
            />
          </a>
        </div>
        <div className="topbar brochure" onClick={() => onWhatsappClickPPC()}>
          <a href="javascript:void(0);" aria-label="whatsapp">
            <img
              className="lazyload ml-1"
              src={whatsapp}
              width="20"
              height="20"
              alt=""
            />
          </a>
        </div>
      </div>
    </div>
  ) : (
    <div
      className="mobile-menu show"
      id="footer_fix"
      style={{ opacity: isbottomfooter }}
    >
      {/* <Helmet>
        <style>
          {`
          .indicator{
            margin-bottom: 100px;
            margin-right: 5px;
          }
          #__eechatIcon{
            bottom: 120px !important;
            right: 20px !important;
            width: 60px !important;
            height: 60px !important;
          }
        `}
        </style>
        <script src="https://extraaedgeresources.blob.core.windows.net/demo/analytixlabs/Chatbot/js/chat.js"></script>
      </Helmet> */}
      <ul style={{ height: '30.8px' }}>
        <li className="mr-1">
          <div className="dnld-btn">
            <a
              href={
                isWorkingHourse
                  ? `tel:${
                      footer?.footerRecord?.callUs?.country_code ||
                      footer?.callUs?.country_code
                    }${ContactNumber.ppcContactNumber}`
                  : '/contact-us'
              }
              onClick={() => (isWorkingHourse ? onCallUsClickPPC() : null)}
            >
              <img
                className="lazyload"
                src={call}
                width="15"
                height="15"
                alt=""
              />
              &nbsp;&nbsp;
              <span>{isWorkingHourse ? 'Call Us' : 'Call Back'}</span>
            </a>
          </div>
        </li>
        <li className="mr-1">
          <div className="dnld-btn">
            <a className="mob-dnl" onClick={() => onWhatsappClickPPC()}>
              <img
                className="lazyload"
                src={hubspot}
                width="15"
                height="15"
                alt=""
              />
              &nbsp;&nbsp;<span>Chat with Us</span>
            </a>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default memo(BottomFooter)
