import React, { useState, useEffect, memo, useContext } from 'react'
// import { nitrateStore } from '@n7-io/nitrate-react'

import SliderHome from './SilderHome'
import { GeneralContext } from '../../context/GeneralContext'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { axiosInstance } from '../../shared/api'
import { homeData, companiesLogoList } from '../../data/homeData'
import { homeCourseOrder } from '../../constants'
import { DateDiff, getCourseList } from 'shared/utills'
import { pgDiplomaAnalyticsCard } from 'data/pgDiplomaAnalytics'
import {homeCardStaticLearningTrack} from 'data/staticCardDataHomeandCourse'
import HTMLHeadCommon from 'components/HTMLHeadCommon/HTMLHeadCommon'
import {Helmet} from 'react-helmet-async'
const CourseListing = React.lazy(() => import('./CourseListing'))
const CompaniesLogo = React.lazy(() => import('./CompaniesLogo'))
const WhyUs = React.lazy(() => import('./WhyUs'))
const ConterDetails = React.lazy(() => import('./ConterDetails'))
const LearningModes = React.lazy(() => import('./LearningModes'))
const UpcomingbatchesHome = React.lazy(() => import('./UpcomingbatchesHome'))
const TestimonialStudent = React.lazy(() => import('./TestimonialStudent'))
const StudentModal = React.lazy(() => import('components/StudentModal'))
const CallUs = React.lazy(() => import('../course/Callus'))
const BlogSection = React.lazy(() => import('./BlogSection'))
const FAQSection = React.lazy(() => import('./FAQSection'))

const Home = memo(() => {
  const { generalData, setGeneralState } = useContext(GeneralContext)
  const [testimonials, setTestimonial] = useState([])
  const [homedetails, setHomedetail] = useState(homeData)
  let Options = {
    loop: true,
    margin: 10,
    nav: true,
    autoplayTimeout: 2000,
    autoplaySpeed: 2000,
    responsive: {
      0: {
        items: 1,
        nav: true,
      },
      600: {
        items: 2,
        nav: false,
      },
      1000: {
        items: 3,
        nav: true,
        loop: false,
      },
    },
  }

    function addCardCourseData(learningTracks) {
    const newLearningTracks = []
    homeCourseOrder.forEach((item) => {
      const findCourse = learningTracks.filter((data) => data.slug === item)
      if (item === 'pg-in-data-analytics') {
        newLearningTracks.push(pgDiplomaAnalyticsCard)
      }  else if (Array.isArray(findCourse) && findCourse.length > 0) {
        newLearningTracks.push(findCourse[0])
      }
    })
    return newLearningTracks
  }

  async function callHome() {
    const response = await axiosInstance.get('home')
    const params = {
      dummy: false,
      popularCourses: response.data.popularCourses,
      learningModes: response.data.learningModes,
      announcementRecord: response.data.announcementRecord,
      upcomingBatch: response.data.upcomingBatchResult,
      blogRecord: response.data.blogRecord,
      learningTracks: response.data.learningTracks,
      headerSection: response.data.headerSection,
      faqSection: response.data.faqsData,
    }
    params.learningTracks = addCardCourseData(params.learningTracks)
    setGeneralState('home', params)
    setGeneralState('LastUpdatedDate', new Date())
    setHomedetail(params)
  }
  async function callTestimonial() {
    const testimonialRes = await axiosInstance.post('frTestimonial')
    setTestimonial(testimonialRes.data)
    setGeneralState('testimonial', testimonialRes.data)
    setGeneralState('LastUpdatedDate', new Date())
  }

  async function callCourse() {
    const params = await getCourseList()
    setGeneralState('course', params)
    setGeneralState('LastUpdatedDate', new Date())
  }

  function resetGeneralState() {
    setGeneralState('headerfooter', null)
    setGeneralState('home', null)
    setGeneralState('announcement', null)
    setGeneralState('course', null)
    setGeneralState('footer', null)
    setGeneralState('headerRecord', null)
    setGeneralState('uBatchHeader', null)
    setGeneralState('testimonial', null)
    setGeneralState('LastUpdatedDate', new Date())
  }
  useEffect(() => {
    let dateDiff = DateDiff(generalData?.LastUpdatedDate)
    if (dateDiff > 1) {
      resetGeneralState()
    }

    // const exploreHome = nitrateStore('exploreHome')
    if (generalData?.home) setHomedetail(generalData?.home)
    // else if (exploreHome) {
    //   const transformedData = {
    //     dummy: false,
    //     popularCourses: exploreHome.popularCourses,
    //     learningModes: exploreHome.learningModes,
    //     announcementRecord: exploreHome.announcementRecord,
    //     upcomingBatch: exploreHome.upcomingBatchResult,
    //     blogRecord: exploreHome.blogRecord,
    //     learningTracks: exploreHome.learningTracks,
    //     headerSection: exploreHome.headerSection,
    //     faqSection: exploreHome.faqsData,
    //   }
    //   transformedData.learningTracks = addCardCourseData(
    //     transformedData.learningTracks,
    //   )
    //   setHomedetail(transformedData)
    // } else
    callHome()

    // const exploreTestimonial = nitrateStore('exploreTestimonial')
    if (generalData?.testimonial) setTestimonial(generalData?.testimonial)
    // else if (exploreTestimonial) {
    //   const transformedData = {
    //     testimonial: exploreTestimonial,
    //   }
    //   setTestimonial(transformedData)
    // } else
    callTestimonial()
    !generalData?.course?.course && callCourse()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <>
      <Helmet>
      <script type="application/ld+json">
        { homedetails.faqSection?.faqsData && homedetails.faqSection.faqsData.length > 0
        ? `{
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [${homedetails.faqSection?.faqsData.map(item => `{
                    "@type": "Question",
                    "name": "${item.title}",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "${item.description.replace(/<[^>]*>/g, '').replace(/\n|\r|\t/g, '').replace(/"/g, "'").replace(/&nbsp;/g, ' ').replace(/&amp;/g, '&')}"
                    }
                  }`).join(',')}]
              }`
              : '{}'}
      </script>
      <script type="application/ld+json">
          {homeCardStaticLearningTrack &&
          homeCardStaticLearningTrack.length > 0
            ? `{   
          "@context": "https://schema.org/",
          "@type": "ItemList",
          "itemListElement": [${homeCardStaticLearningTrack.map(
            (item, index) =>
              `{
             "@type": "ListItem",
          "position": "${index}",
          "item": { 
                        "@context": "https://schema.org/",
                        "@type": "Course",
                        "name": "${item.schema.title}",
                        "description": "${item.schema.description}",
                        "hasCourseInstance": [
                          {
                            "@type": "CourseInstance",
                            "courseMode": ["${item.schema.courseMode}"],
                            "location": ["${item.schema.location}"],
                            "offers": {
                              "@type": "AggregateOffer",
                              "price": "Starting from ${item.schema.totalAmount ??
                                ""}",
                              "priceCurrency": "${item.schema.priceCurrency}"
                            }
                          }
                        ]
            }
            }`
          )}]
          }`
            : "{}"}
        </script>
      
    </Helmet>
    <HTMLHeadCommon/>
      <div>
        <SliderHome
          dummy={homedetails.dummy}
          learningTracks={homeCardStaticLearningTrack}
          header={homedetails.headerSection.header}
        />
        {/* <Nitrate> */}
        <CourseListing
          nitrateRef="homeMobileExtras"
          CourseList={homedetails.popularCourses}
        />
        <CompaniesLogo
          nitrateRef="homeMobileExtras"
          companiesLogoList={companiesLogoList}
        />
        <WhyUs
          nitrateRef="homeExtras"
          dummy={homedetails.dummy}
          whyAnalytixlabs={homedetails.headerSection.whyAnalytixlabs}
        />
        <TestimonialStudent
          nitrateRef="homeExtras"
          dummy={homedetails.dummy}
          testimonials={testimonials}
        />
        <StudentModal nitrateRef="homeExtras" />
        <LearningModes
          nitrateRef="homeExtras"
          dummy={homedetails.dummy}
          learningModes={homedetails.learningModes}
        />
        <UpcomingbatchesHome
          nitrateRef="homeExtras"
          dummy={homedetails.dummy}
          upcomingBatch={homedetails.upcomingBatch}
        />
        <ConterDetails
          nitrateRef="homeExtras"
          dummy={homedetails.dummy}
          statistics={homedetails.headerSection.statistics}
        />
        <CallUs nitrateRef="homeExtras" />
        <BlogSection nitrateRef="homeExtras" Options={Options} blogFlag={false} />
        <FAQSection nitrateRef="homeExtras" FaqRecord={homedetails.faqSection} />
        {/* </Nitrate> */}
      </div>
    </>
  )
})

export default Home
