import ReactGA from 'react-ga'
const BASE_URL = 'https://devapi.analytixlabs.co.in/'
const URL_BLOG = 'https://www.analytixlabs.co.in/blog/'
const URL = 'https://www.analytixlabs.co.in'
const Logo_URL = 'https://www.analytixlabs.co.in'
const LMS_URL = 'https://lms.analytixlabs.co.in/'
const Razorpay_Key = 'rzp_test_xpj06x8Jsk8eQr'
const domain = { domain: '.analytixlabs.co.in' }

const handleGA = () => {
  ReactGA.initialize('G-S8DQVCX660') // only need in production
  ReactGA.pageview(window.location.pathname) // only need in production
}
const heapCode = '1494897111'
const server = 'dev'

export {
  BASE_URL,
  URL_BLOG,
  URL,
  Logo_URL,
  Razorpay_Key,
  LMS_URL,
  domain,
  handleGA,
  heapCode,
  server,
}
