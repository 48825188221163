/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import moment from "moment";
import {pgDiploma} from 'views/upcomingBatche/data';

const UpComingBatches = () => {
  let batchList = [];
  const blkClasses = {
    Noida: 'gr-lc',
    Gurgaon: 'yl-lc',
    Bangalore: 'bl-lc',
  }
  const courseBatchesList = pgDiploma?.CourseBatches;
    courseBatchesList &&
    courseBatchesList.length > 0 &&
    courseBatchesList.forEach((batchD, index) => {
      let batch = {
        ...batchD,
        city: batchD.City.name,
        cssClass: `${blkClasses[batchD.City.name] || 'yl-dt'}`,
      }
      if (index < 3) {
        batchList.push(
          <li
            key={`course__Upcoming__Batch__${index}`}
            className={'p-2 ' + batch.cssClass}
          >
            <div className="dat-cnt">
              <h5>
                {moment(batch.startDate).format('DD')}
                <span>{moment(batch.startDate).format('MMM')}</span>
              </h5>
              <p className="date-pls">{batch.City.name}</p>
            </div>
          </li>,
        )
      }
    })
  return (
    <ul className="count-ul-inr float-left mt-2 mapping_img_2">
      {batchList}
    </ul>
  )
}

export default UpComingBatches