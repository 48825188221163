/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from 'react'

const PGDVsPGCC = memo(({ pgdVspgcc }) => {
  return (
    <section>
      <div className="container">
        <div className="title-pg-diploma mb-4">
          <h2>{pgdVspgcc.title}</h2>
        </div>
      </div>
      <div className="container mt-4 ">
        {pgdVspgcc?.longDescription && <div
            className={`counrse-contn-pg-diploma mb-4`}
            dangerouslySetInnerHTML={{
              __html: pgdVspgcc.longDescription,
            }}
          ></div>}
        <div
          className="counrse-contn-pg-diploma-analytics pg-diploma-btn-center"
          dangerouslySetInnerHTML={{
            __html: pgdVspgcc?.mobileViewTable,
          }}
        ></div>
        <div className='ct-note-message-table mb-3 mt-3'>
          {pgdVspgcc?.noteMsg}
        </div>{' '}
      </div>
    </section>
  )
})
export default PGDVsPGCC
