import { useState } from 'react'
import constate from 'constate'

const useModalType = () => {
  const [modalType, setModalType] = useState('')
  const [modalParams, setModalParams] = useState('')

  return { modalType, setModalType, modalParams, setModalParams }
}

export const [ModalTypeProvider, useModalTypeContext] = constate(useModalType)
