const pgDiplomaCityNoida = {
  slug : '/data-analyst-training-course-in-noida',
  title : 'Data Analytics Course in Noida: Duration, Fees & Placement',
  description : 'Kickstart your Data Analyst career in Noida! Explore comprehensive Data Analyst training and learn about course curriculum, fees & placement support. Learn Now!'
}

const pgDiplomaCityDelhi = {
  slug : '/data-analyst-training-course-in-delhi',
  title : 'Become a Data Analyst in Delhi: Find Top-Rated Institutes & Courses',
  description : 'Find the best Data Analyst courses and institutes in Delhi with placement opportunities. Learn about top programs and training options for aspiring Data Analysts.'
}

const pgDiplomaCityGurgaon = {
  slug : '/data-analyst-training-course-in-gurgaon',
  title : 'Data Analyst Course in Gurgaon : Launch your Data Analytics Career',
  description : 'Aspire to be a Data Analyst in Gurgaon? Explore a range of top-rated courses & institutes offering in-demand Data Analytics skills.'
}

const pgDiplomaCityBangalore = {
  slug : '/data-analytics-training-course-in-bangalore',
  title : 'Best Data Analyst Courses & Training in Bangalore with Placement',
  description : 'Discover the best Data Analyst courses and training in Bangalore with placement opportunities. Learn about top institutes, course content, and fees. Enroll now!'
}

const PGCityPagesSlug = [
  pgDiplomaCityNoida?.slug,
  pgDiplomaCityDelhi?.slug,
  pgDiplomaCityGurgaon?.slug,
  pgDiplomaCityBangalore?.slug
]

export {
  pgDiplomaCityNoida,
  pgDiplomaCityDelhi,
  pgDiplomaCityGurgaon,
  pgDiplomaCityBangalore,
  PGCityPagesSlug,
}
